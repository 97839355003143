import sortBy from 'lodash/sortBy';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToaster } from 'lib/toaster';
import {
  createCrewNote,
  deleteCrewNote,
  fetchCrewMatrixVessels,
  fetchCrewNotes,
  fetchCrewBoardingRecords,
  fetchCrewPersonalInfo,
  fetchDataSyncDetails,
  fetchVesselScheduleEvents,
} from 'api/crew-matrix';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_CREATE_CREW_NOTE,
  TRACK_DELETE_CREW_NOTE,
} from 'utils/analytics/constants';
import { CrewNote } from 'components/CrewMatrixPlanning/types';

// type used for crew note related thunks
type CrewShort = {
  id: string;
  name: string | undefined;
};

export const fetchDataSyncDetailsAsync = createAsyncThunk(
  'crewChangeMatrix/fetchDataSyncDetailsAsync',
  async () => {
    const { success, message, result } = await fetchDataSyncDetails();

    if (!success || !result) {
      showToaster({ message, type: 'error' });
    }

    return result || null;
  }
);

export const fetchCrewMatrixVesselsAsync = createAsyncThunk(
  'crewChangeMatrix/fetchCrewMatrixVesselsAsync',
  async () => {
    const response = await fetchCrewMatrixVessels();
    const { success, message, result: vessels = [] } = response;

    // show failed message
    if (!success) {
      showToaster({ message, type: 'error' });
    }

    // sort by vessel name
    return sortBy(vessels, 'vesselName');
  }
);

// fetch vessel schedule events to update the events in store
export const fetchVesselScheduleEventsAsync = createAsyncThunk(
  'crewChangeMatrix/fetchVesselScheduleEventsAsync',
  async ({ vesselId, vesselImo }: { vesselId: number; vesselImo: string }) => {
    const response = await fetchVesselScheduleEvents(vesselImo);
    const { success, message, result: events = [] } = response;

    // show failed message
    if (!success) {
      showToaster({ message, type: 'error', placement: 'left' });
      return { vesselId, events: [] };
    }

    return { vesselId, events };
  }
);

export const fetchCrewNotesAsync = createAsyncThunk(
  'crewChangeMatrix/fetchCrewNotesAsync',
  async ({ crewId, page }: { crewId: string; page: number }) => {
    const { success, message, result } = await fetchCrewNotes(crewId, page);
    if (!success || !result) {
      showToaster({ message, type: 'error' });
      return { crewId, result: null };
    }

    return { crewId, result };
  }
);

export const createCrewNoteAsync = createAsyncThunk(
  'crewChangeMatrix/createCrewNoteAsync',
  async ({ crew, note }: { crew: CrewShort; note: string }) => {
    const { id: crewId } = crew;
    const { success, message, result } = await createCrewNote(crewId, note);
    if (!success || !result) {
      showToaster({ message, type: 'error' });
      return { crewId, result: null };
    }

    // trigger amplitude event for creating new crew note
    trackUserAction(TRACK_CREATE_CREW_NOTE, 'click', {
      crew,
      note,
    });

    return { crewId, result };
  }
);

export const deleteCrewNoteAsync = createAsyncThunk(
  'crewChangeMatrix/deleteCrewNoteAsync',
  async ({ crew, note }: { crew: CrewShort; note: CrewNote }) => {
    const { id: crewId } = crew;
    const { success, message } = await deleteCrewNote(crewId, note.id);
    if (!success) {
      showToaster({ message, type: 'error' });
      return null;
    }

    showToaster({
      message,
      placement: 'left',
      testId: 'e2e_cmp-crew-notes-remove-toaster',
    });

    // trigger amplitude event for deleting new crew note
    trackUserAction(TRACK_DELETE_CREW_NOTE, 'click', {
      crew,
      note,
    });

    return { crewId, noteId: note.id };
  }
);

export const fetchCrewHistoryAsync = createAsyncThunk(
  'crewChangeMatrix/fetchCrewHistoryAsync',
  async (crewId: string) => {
    const { success, message, result } = await fetchCrewBoardingRecords(crewId);

    if (!success || !result) {
      showToaster({ message, type: 'error' });
    }

    return { crewId, records: result?.records || [] };
  }
);

export const fetchCrewPersonalInfoAsync = createAsyncThunk(
  'crewChangeMatrix/fetchCrewPersonalInfoAsync',
  async (crewId: string) => {
    const { success, message, result } = await fetchCrewPersonalInfo(crewId);

    if (!success || !result) {
      showToaster({ message, type: 'error' });
    }

    return { crewId, personalInfo: result || null };
  }
);
