import { Middleware, PayloadAction } from '@reduxjs/toolkit';

import { showToaster } from 'lib/toaster';
import { saveAppSettings } from 'api/flotilla';
import { updateStoreSettings } from 'redux/actions';
import {
  UPDATE_APP_COMBINED_SETTINGS,
  UPDATE_APP_SETTINGS,
} from 'redux/thunks';
import { sanitizeAppSettings } from 'utils/settings';
import { validateAppSettings } from 'utils/validation/settings';

import { FLOTILLA_SETTINGS_KEY } from 'utils/types';
import { AppSettings, StoreAPI } from '../types';

const settings: Middleware<{}, any> =
  ({ dispatch }: StoreAPI) =>
  (next) =>
  async (action: PayloadAction<AppSettings>) => {
    const { type, payload } = action;
    // update store with saved settings after saving to backend
    if ([UPDATE_APP_SETTINGS, UPDATE_APP_COMBINED_SETTINGS].includes(type)) {
      const validSettings = validateAppSettings(
        sanitizeAppSettings(payload, false) // sanitize before validating, e.g - copy read-only fields
      );
      if (validSettings) {
        const { success, settings: savedSettings } = await saveAppSettings(
          validSettings
        );

        if (success && savedSettings) {
          dispatch(updateStoreSettings(savedSettings));
          localStorage.removeItem(FLOTILLA_SETTINGS_KEY);
          // show toaster for combined settings updatw
          if (type === UPDATE_APP_COMBINED_SETTINGS) {
            showToaster({ message: 'App settings updated' });
          }
          return next(action);
        }
      }
    }

    next(action);
  };

export default settings;
