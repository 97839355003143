import { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectCrewChangePanel } from 'redux/selectors';
import FilterView from './FilterView';
import ReadOnly from './ReadOnly';
import { FlightConnectionProps } from './types';

function FlightConnection({ ...props }: FlightConnectionProps) {
  const { isCompareView, port, ...commonProps } = props;
  const { active: activity } = useSelector(selectCrewChangePanel);

  return activity === 'readOnly' ? (
    <ReadOnly {...commonProps} port={port} />
  ) : (
    <FilterView {...commonProps} isCompareView={isCompareView} />
  );
}

export default memo(FlightConnection);
