import { Dispatch, memo, SetStateAction } from 'react';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import styled from 'styled-components/macro';

import { borderGray, green, orange, textBlack } from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { formatDate } from 'utils/format-date';

import { Tooltip } from 'components/shared';
import { Label } from 'components/CrewChangePanel/common';
import { ItemWrapper } from '../common';
import { FareType, ReadOnlyFlight } from '../../../../types';
import { formatHour } from 'lib/common';
import { isArray } from 'lodash';

const Title = styled.div`
  color: ${textBlack};
  padding-left: 1rem;
  margin-top: 5px;
  margin-bottom: 8px;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    padding-left: 0.5rem;
  }
`;

const Row = styled(ItemWrapper)`
  grid-template-columns: 1.25fr 1fr;
`;

const Divider = styled.div`
  border-top: 1px solid ${borderGray};
`;

const FilterWrapper = styled.div`
  height: fit-content;
  padding: 0.25rem 0.5rem;
  letter-spacing: 0.025rem;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    padding-left: 0.5rem;
  }
`;

const Text = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
`;

const BadgeContainer = styled.div`
  width: fit-content;
`;

const Badge = styled.div<{ $green: boolean }>`
  padding: 0.1rem 0.75rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.33;
  ${({ $green }) =>
    $green
      ? `
    background: ${green}20;
    color: ${green};
    border: 1px solid ${green};
  `
      : `
    background: ${orange}20;
    color: ${orange};
    border: 1px solid ${orange};
  `};
`;

type Props = {
  anchorEl: HTMLElement | null;
  flight: ReadOnlyFlight;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
};

function ReadOnlyFlightFilters({ anchorEl, flight, setAnchorEl }: Props) {
  const {
    crew,
    arrival: { airportCode: arrivalAirportCode },
    departure: { airportCode: departureAirportCode },
    filters,
  } = flight;
  const {
    time: flightTime,
    layover,
    stopsCount,
    type,
    source,
    departures,
    fareType,
    arrivalTime,
    departureTime,
    hotelCostDelay,
    allowAirportTransfer,
  } = filters;
  const connection = `${departureAirportCode} to ${arrivalAirportCode}`;
  const isMarineFlight = fareType === FareType.marine;

  return (
    <Menu
      id="flight-filters-menu"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      TransitionComponent={Fade}
      MenuListProps={{
        'aria-labelledby': 'fade-button',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{ width: 380 }}
    >
      <Title>
        Filters Applied for <b>{connection}</b>
      </Title>

      <Divider />

      <FilterWrapper>
        <Row>
          <Label>Crew Name:</Label>
          <Text>{crew.name}</Text>
        </Row>

        <Row>
          <Label>Category:</Label>
          <Text>{type.toUpperCase()}</Text>
        </Row>

        <Row>
          <Label>Departure Date:</Label>
          <Text>
            {formatDate(
              crew.type === 'onsigner'
                ? departures?.onsigner
                : departures?.offsigner
            )}
          </Text>
        </Row>

        <Row>
          <Label>Departure Hours:</Label>
          <Text>
            {formatHour(departureTime[0])} to {formatHour(departureTime[1])}
          </Text>
        </Row>

        <Row>
          <Label>Arrival Hours:</Label>
          <Text>
            {formatHour(arrivalTime[0])} to {formatHour(arrivalTime[1])}
          </Text>
        </Row>

        <Row>
          <Label>Total Layover Time:</Label>
          <Text>
            {isArray(layover) ? `${layover[0]} to ${layover[1]}` : layover}{' '}
            Hour(s)
          </Text>
        </Row>

        <Row>
          <Label>Max Flight Time:</Label>
          <Text>{flightTime} Hour(s)</Text>
        </Row>

        <Row>
          <Label>Hotel Cost Delay:</Label>
          <Text>{hotelCostDelay} Hour(s)</Text>
        </Row>

        <Row>
          <Label>Max Stops:</Label>
          <Text>{stopsCount}</Text>
        </Row>

        <Row>
          <Label>Flight Source:</Label>
          <Text>{source.toUpperCase()}</Text>
        </Row>

        <Row>
          <Label>Fare Type:</Label>
          <BadgeContainer>
            <Tooltip
              content={`Selected a ${
                isMarineFlight ? 'marine' : 'general'
              } flight`}
            >
              <Badge $green={isMarineFlight}>
                {isMarineFlight ? 'Marine' : 'General'}
              </Badge>
            </Tooltip>
          </BadgeContainer>
        </Row>

        <Row>
          <Label>Transfer between Airports:</Label>
          <BadgeContainer>
            <Tooltip
              content={`Airport transfer is ${
                allowAirportTransfer ? 'allowed' : 'denied'
              } for this flight`}
            >
              <Badge $green={Boolean(allowAirportTransfer)}>
                {allowAirportTransfer ? 'Allowed' : 'Denied'}
              </Badge>
            </Tooltip>
          </BadgeContainer>
        </Row>
      </FilterWrapper>
    </Menu>
  );
}

export default memo(ReadOnlyFlightFilters);
