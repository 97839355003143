import uniqBy from 'lodash/uniqBy';
import {
  memo,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, Chip, Popper, TextField } from '@mui/material';
import {
  AgentEmail,
  AgentSearchReq,
  SendAgentEmailReq,
} from '@greywing-maritime/frontend-library/dist/types/emailReqResp';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components/macro';
import { validate } from 'superstruct';

import { useDebounce, useMobile, useModal } from 'hooks';
import sleep from 'lib/sleep';
import { red, textBlack, textGray } from 'lib/colors';
import { isValidEmail } from 'lib/common';
import { trackUserAction } from 'lib/amplitude';
import { showToaster } from 'lib/toaster';
import { BREAK_POINT_L } from 'lib/breakpoints';
import {
  selectCrewChangePanel,
  selectMapVessels,
  selectSettings,
} from 'redux/selectors';
import { searchAgents, sendAgentEmail } from 'api/flotilla';
import {
  TRACK_SEND_EMAIL,
  TRACK_SEND_EMAIL_CHANGE_TEMPLATE,
  TRACK_SEND_EMAIL_COPY_ALL,
  TRACK_SEND_EMAIL_COPY_SECTION,
} from 'utils/analytics/constants';
import { Crew } from 'utils/types/crew-change-types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { ButtonV2, Modal, Tooltip } from 'components/shared';
import { CrewTable, VesselTable } from './Tables';
import AddAgentModal from './AddAgent';
import AddPassport from './AddPassport';
import AnimationModal from './Animation';
import { StyledButton } from '../common';
import {
  ACTION_TIMES,
  copyEmailToClipboard,
  filterEmailDetails,
  formatEmailDetails,
  getCrewDetailsTable,
  getEmailMessage,
  getEmailRequest,
  getLocodeKeyFromPortData,
  getVesselDetailsTable,
  includeCrewWithoutFlight,
  initialPagination,
  packagePassports,
  passportPasswordIsValid,
  scrolledToEnd,
  setActionTime,
  setCrewPassportList,
} from '../helpers';
import {
  crewDetailFields,
  PortCardType,
  vesselDetailFields,
  VesselSelectedFields,
  CrewSelectedFields,
  SelectedFields,
  EmailTemplate,
} from '../types';
import { isEmail } from 'utils/validations';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InfoIcon from '@mui/icons-material/Info';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .MuiInputLabel-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input,
  .MuiAutocomplete-loading {
    font-size: 0.9rem;
    font-family: HK Grotesk, Roboto;
  }
  .MuiChip-root {
    font-size: 0.8rem;
    font-family: HK Grotesk, Roboto;
    margin-right: 0.25rem;
  }
  .missing-agent {
    border: 1px solid ${red};
    background: ${red}33;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`;

const LabelWrapper = styled(FlexWrapper)`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${textBlack};
  margin-right: 0.5rem;
`;

const ButtonsWrapper = styled(FlexWrapper)`
  justify-content: flex-end;
`;

const Spacer = styled.div`
  margin-bottom: 20px;
`;

const StyledPopper = muiStyled(Popper)(() => ({
  '& .MuiAutocomplete-loading, .MuiAutocomplete-paper': {
    fontSize: '0.9rem',
    fontFamily: 'HK Grotesk, Roboto',
  },
}));

const TemplateWrapper = styled.div`
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    font-size: 0.9rem;
    font-weight: bold;
  }
`;

const ButtonGroup = styled.div`
  align-self: flex-start;
  display: flex;
  column-gap: 0.5rem;
`;

const CopyIcon = styled(AssignmentIcon)`
  margin-right: 0.5rem;
  font-size: 18px !important;
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 1.1rem !important;
  }
`;

const inputStyles = {
  style: {
    fontSize: '0.9rem',
  },
};

const dropDownStyles = {
  maxHeight: '250px',
  fontSize: '0.9rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
};

const textFieldStyles = { mb: 3 };

type EventType =
  | React.KeyboardEvent<HTMLDivElement>
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

type Props = {
  event: CrewEvent;
  port: PortCardType;
  closeModal: () => void;
};

// hide these field initially
const defaultStateFalse = [
  'manningOffice',
  'rank',
  'cid',
  'country',
  'time',
  'cost',
  'reason',
  'sex',
  'birthday',
  'flightNumbers',
  'time',
  'passportNumber',
  'passportExpiry',
  'visaCountry',
  'visaExpiry',
  'passportIssued',
  'seamenBookNumber',
  'seamenBookExpiry',
  'seamenBookIssued',
  'seamenBookIssuedCountryIso3',
];

// set the possible selected fields in vessel & crew tables to `true`
const getSelectedFields = (array: string[]) =>
  array.reduce(
    (acc, field) => ({
      ...acc,
      [field]: defaultStateFalse.includes(field) ? false : true,
    }),
    {}
  );

function SendModal({ event, port, closeModal }: Props) {
  const { vesselId } = useSelector(selectCrewChangePanel);
  const { userInfo } = useSelector(selectSettings);
  const { filteredVessels: vessels } = useSelector(selectMapVessels);
  const {
    filters,
    portParams: { agency },
    planningData: { crew },
  } = useContext(CCPanelContext);
  const isSmallerScreen = useMobile(BREAK_POINT_L);
  const { modal, setModal } = useModal(null);
  // list of crew in added to the email details with missing flights
  const [crewWithoutFlight, setCrewWithoutFlight] = useState<Crew[]>([]);
  const [ccEmails, setCCEmails] = useState<string[]>([]);

  // Select template type
  const [template, setTemplate] = useState<EmailTemplate>('book-flight');

  const { company, access } = userInfo || {};
  const currentLocodeKey = getLocodeKeyFromPortData(port);
  const flights = filters[currentLocodeKey].confirmed;
  const vessel = useMemo(() => vessels.get(vesselId!)!, [vesselId, vessels]);
  const emailDetails = useMemo(
    () => ({
      ...formatEmailDetails({
        crew,
        port,
        flights,
        vessel,
        additionalCrew: crewWithoutFlight,
      }),
      company,
    }),
    [crew, port, flights, vessel, company, crewWithoutFlight]
  );

  // This is for agent search
  const [loading, setLoading] = useState(false);
  // This is for email submission
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [done, setDone] = useState(false);
  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [emails, setEmails] = useState<string[]>([]);
  const [agents, setAgents] = useState<AgentEmail[]>([]);
  const [crewPassports, setCrewPassports] = useState(
    setCrewPassportList(emailDetails.crewDetails)
  );
  const [passportsPassword, setPassportsPassword] = useState('');
  const [pagination, setPagination] = useState(initialPagination);
  const [body, setBody] = useState(
    getEmailMessage({
      emailDetails,
      name: vessel?.name || 'Unknown vessel',
      template,
    })
  );
  // state containing selected fields for vessel & crew; all are selected initially
  const [selectedFields, setSelectedFields] = useState<SelectedFields>({
    vessel: getSelectedFields(vesselDetailFields) as VesselSelectedFields,
    crew: getSelectedFields(crewDetailFields) as CrewSelectedFields,
  });

  const fetchedEmails = useMemo(
    () => agents.map(({ email }) => email),
    [agents]
  );

  const attachedPassports = useMemo(
    () =>
      Object.keys(crewPassports).reduce((count, key) => {
        if (crewPassports[Number(key)].file) {
          return count + 1;
        }
        return count;
      }, 0),
    [crewPassports]
  );

  const isFormValid = useMemo(() => {
    const missingEmail = emails.some((email) => !fetchedEmails.includes(email));
    return (
      emails.some(isValidEmail) &&
      !missingEmail &&
      (attachedPassports > 0
        ? passportPasswordIsValid(passportsPassword)
        : true) &&
      Boolean(body.message.trim().length)
    );
  }, [
    emails,
    fetchedEmails,
    body.message,
    passportsPassword,
    attachedPassports,
  ]);

  const filteredDetails = useMemo(
    () => filterEmailDetails(emailDetails, selectedFields, template),
    [emailDetails, selectedFields, template]
  );

  const handleAddAgents = (agents: AgentEmail[]) => {
    setAgents((prevAgents) => uniqBy([...prevAgents, ...(agents || [])], 'id'));
  };

  const handleSearchAgents = useDebounce(
    async (term?: string, page?: number) => {
      setLoading(true);
      const searchRequest = {
        type: 'port',
        locode: port.locode,
        term,
        page,
      };
      const { searchResponse } = await searchAgents(
        searchRequest as AgentSearchReq
      );

      if (searchResponse) {
        const { results: agents, pagination } = searchResponse;
        handleAddAgents(agents);
        setPagination((prev) => ({
          hasMore: pagination?.more,
          page: page || prev.page,
        }));
        setLoading(false);
      }
    }
  );

  // Modify the email body when the template changes
  useEffect(() => {
    setBody(
      getEmailMessage({
        emailDetails,
        name: vessel?.name || 'Unknown vessel',
        template,
      })
    );
  }, [template]); // eslint-disable-line

  useEffect(() => {
    setActionTime('emails', 'start', true);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (focused && !agents.length) {
      handleSearchAgents();
    }
  }, [focused]); // eslint-disable-line

  useEffect(() => {
    const alreadyFetched = agents.some(({ email }) =>
      email.includes(query.trim().toLowerCase())
    );

    if (query && !alreadyFetched) {
      handleSearchAgents(query);
    }
  }, [query]); // eslint-disable-line

  const handleAddEmail = (event: EventType) => {
    const { value } = event.target as HTMLInputElement;
    const { key } = event as React.KeyboardEvent<HTMLDivElement>;

    if (['Enter'].includes(key)) {
      if (agents.length && !value) return;

      const availableToAdd = !fetchedEmails.some((email) =>
        email.includes(value)
      );
      if (!value || availableToAdd) {
        setModal('addAgent', { value, agency, port });
      }
    }
  };

  const handleUpdateContent = useCallback(
    (type: string) => (event: SyntheticEvent) => {
      const { value } = event.target as HTMLInputElement;
      setBody((detail) => ({ ...detail, [type]: value }));
    },
    []
  );

  const handleSend = useCallback(async () => {
    setIsSubmitting(true);
    const attachments = await packagePassports({
      crewPassports,
      passportsPassword,
    });
    const agentEmailRequest: SendAgentEmailReq = getEmailRequest(
      agents.filter(({ email }) => emails.includes(email)),
      ccEmails,
      { ...filteredDetails, ...body },
      template,
      attachments.length ? attachments : undefined
    );

    const { success, message } = await sendAgentEmail(agentEmailRequest);

    if (!success) {
      setIsSubmitting(false);
      showToaster({ message: message || 'Sending failed!', type: 'error' });
      return;
    }

    setActionTime('emails', 'end');
    sleep(100).then(() => {
      setDone(true);
      trackUserAction(TRACK_SEND_EMAIL, 'click', {
        eventId: event.id,
        ...ACTION_TIMES,
      });
    });
  }, [
    event,
    crewPassports,
    passportsPassword,
    body,
    filteredDetails,
    agents,
    emails,
    template,
    ccEmails,
  ]);

  const handleIncludeCrewWithoutFlight = useCallback(
    (crewId: number) => {
      const addedCrew = crew.find((c) => c.id === crewId);
      if (addedCrew) {
        setCrewWithoutFlight((prev) => [...prev, addedCrew]);
      }
    },
    [crew]
  );

  const renderEmailChip = (option: string, index: number) => {
    const key = `${option}_${index}`;
    const emailUnavailable = !fetchedEmails.includes(option);
    const emailChip = (
      <Chip
        key={key}
        size="small"
        className={emailUnavailable ? 'missing-agent' : ''}
        label={option}
        onDelete={() =>
          setEmails((emails) => emails.filter((email) => email !== option))
        }
      />
    );

    const tooltipContent = !isValidEmail(option)
      ? 'Invalid email'
      : (emailUnavailable && 'Agent unavailable') || '';

    return emailUnavailable ? (
      <Tooltip key={key} content={tooltipContent}>
        {emailChip}
      </Tooltip>
    ) : (
      emailChip
    );
  };

  const renderModal = () => {
    if (!modal?.type) {
      return null;
    }

    if (modal.type === 'addAgent' && event) {
      const { value = '', port, agency = '' } = modal.data || {};
      return (
        <AddAgentModal
          email={value}
          agency={agency}
          ports={port.locode ? [port.locode] : []}
          closeModal={() => setModal(null)}
          addAgents={(agents) => {
            handleAddAgents(agents);
            setEmails((emails) => [
              ...emails,
              ...agents.map(({ email }) => email),
            ]);
          }}
        />
      );
    }
  };

  const actions = (
    <FlexWrapper style={{ justifyContent: 'space-between', width: '100%' }}>
      {!!attachedPassports ? (
        <p style={{ margin: 0 }}>{`${attachedPassports} passport${
          attachedPassports > 1 ? 's' : ''
        } attached.`}</p>
      ) : (
        <div />
      )}
      <ButtonsWrapper>
        <StyledButton variant="delete" onClick={closeModal}>
          Cancel
        </StyledButton>
        <StyledButton
          variant="primary"
          onClick={handleSend}
          disabled={!isFormValid || isSubmitting}
          loading={isSubmitting}
        >
          Send
        </StyledButton>
      </ButtonsWrapper>
    </FlexWrapper>
  );

  const handleCopyToClipboard = async () => {
    trackUserAction(TRACK_SEND_EMAIL_COPY_ALL, 'click', { template });
    const agentEmailRequest: SendAgentEmailReq = getEmailRequest(
      agents.filter(({ email }) => emails.includes(email)),
      ccEmails,
      { ...filteredDetails, ...body },
      template
    );
    copyEmailToClipboard(agentEmailRequest.body);
  };

  const handleCopyOnlyTableToClipboard = (type: 'crew' | 'vessel') => {
    trackUserAction(TRACK_SEND_EMAIL_COPY_SECTION(type), 'click', { template });
    if (type === 'crew') {
      const { crewDetails } = filteredDetails;
      const crewFields = Object.keys(crewDetails[0]);
      const htmlCrewTable = getCrewDetailsTable(
        crewDetails,
        crewFields,
        template
      );
      copyEmailToClipboard(htmlCrewTable);
    } else {
      const { vesselDetails } = filteredDetails;
      const vesselFields = Object.keys(vesselDetails);
      const htmlVesselTable = getVesselDetailsTable(
        vesselDetails,
        vesselFields
      );
      copyEmailToClipboard(htmlVesselTable);
    }
  };

  const handleAddCCEmail = (event: EventType) => {
    const { value } = event.target as HTMLInputElement;
    const { key } = event as React.KeyboardEvent<HTMLDivElement>;

    if (['Enter'].includes(key) && value) {
      const check = validate(value, isEmail());
      if (check[1]) {
        if (ccEmails.includes(value)) {
          showToaster({
            message: `${value} has already been added.`,
            type: 'error',
          });
        } else {
          setCCEmails((prev) => [...prev, value]);
        }
      } else {
        showToaster({
          message: `${value} is not a valid email address.`,
          type: 'error',
        });
      }
    }
  };

  const handleCCChange = (
    e: SyntheticEvent<Element, Event>,
    value: string[],
    reason: string
  ) => {
    if (['removeOption', 'clear'].includes(reason)) {
      setCCEmails(value);
    }
  };

  return done ? (
    <AnimationModal
      locodeKey={currentLocodeKey}
      closeModal={closeModal}
      agentsCount={emails.length}
    />
  ) : (
    <Modal
      width={isSmallerScreen ? '80vw' : 1080}
      title="Crew Change Plan"
      closeModal={closeModal}
      actions={actions}
    >
      <InputWrapper>
        <TemplateWrapper>
          <label>Select an email template</label>
          <ButtonGroup>
            <ButtonV2
              variant={template === 'book-flight' ? 'primary' : 'secondary'}
              onClick={() => {
                trackUserAction(TRACK_SEND_EMAIL_CHANGE_TEMPLATE, 'click', {
                  template: 'book-flight',
                });
                setTemplate('book-flight');
              }}
            >
              Book Flight
            </ButtonV2>
            <ButtonV2
              variant={
                template === 'port-agency-announcement'
                  ? 'primary'
                  : 'secondary'
              }
              onClick={() => {
                trackUserAction(TRACK_SEND_EMAIL_CHANGE_TEMPLATE, 'click', {
                  template: 'port-agency-announcement',
                });
                setTemplate('port-agency-announcement');
              }}
            >
              Port Agent Announcement
            </ButtonV2>
          </ButtonGroup>
        </TemplateWrapper>
        <Autocomplete
          multiple
          size="small"
          value={emails}
          options={agents.map(({ email }) => email)}
          onChange={(_, values) => setEmails(values)}
          onInputChange={(_, value) => setQuery(value)}
          onKeyDown={handleAddEmail}
          onClose={() => setQuery('')}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          loading={loading}
          filterSelectedOptions
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="To:"
              size="small"
              placeholder="Type email"
            />
          )}
          ListboxProps={{
            id: 'send-modal',
            style: dropDownStyles,
            onScroll: (event: SyntheticEvent) => {
              if (scrolledToEnd(event) && pagination.hasMore) {
                handleSearchAgents(query, pagination.page + 1);
              }
            },
          }}
          noOptionsText="Press Enter to add a new agent"
          renderTags={(values) => values.map(renderEmailChip)}
          PopperComponent={StyledPopper}
          sx={textFieldStyles}
        />

        <Autocomplete
          multiple
          size="small"
          value={ccEmails}
          options={[]}
          onChange={handleCCChange}
          onKeyDown={handleAddCCEmail}
          loading={loading}
          filterSelectedOptions
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="cc:"
              size="small"
              placeholder="Type email"
            />
          )}
          freeSolo
          noOptionsText="Press Enter to add a new email"
          renderTags={(values) =>
            values.map((option) => (
              <Chip
                key={option}
                size="small"
                label={option}
                onDelete={() =>
                  setCCEmails((emails) =>
                    emails.filter((email) => email !== option)
                  )
                }
              />
            ))
          }
          PopperComponent={StyledPopper}
          sx={textFieldStyles}
        />

        <StyledTextField
          size="small"
          label="Subject"
          placeholder="Enter subject"
          value={body.subject}
          onChange={handleUpdateContent('subject')}
          sx={textFieldStyles}
          inputProps={inputStyles}
        />

        <StyledTextField
          multiline
          minRows={8}
          maxRows={8}
          size="small"
          label="Message"
          placeholder="Add message"
          value={body.message}
          onChange={handleUpdateContent('message')}
          inputProps={inputStyles}
          sx={textFieldStyles}
        />
        <ButtonV2
          variant="secondary"
          style={{ alignSelf: 'start', marginBottom: '1rem' }}
          onClick={handleCopyToClipboard}
        >
          <CopyIcon />
          Copy Complete Email to Clipboard
        </ButtonV2>
      </InputWrapper>

      {vessel && (
        <>
          <LabelWrapper>
            <Label>Vessel</Label>
            <Tooltip content="Select/unselect vessel details to include/omit from email">
              <StyledIcon>
                <InfoIcon sx={{ color: textGray }} />
              </StyledIcon>
            </Tooltip>
          </LabelWrapper>
          <VesselTable
            selectedFields={selectedFields.vessel}
            updateFields={setSelectedFields}
            vessel={emailDetails.vesselDetails}
          />
          <ButtonV2
            size="small"
            variant="secondary"
            onClick={() => handleCopyOnlyTableToClipboard('vessel')}
            style={{ marginTop: '1rem' }}
          >
            <CopyIcon />
            Copy Vessel Table
          </ButtonV2>
        </>
      )}

      <Spacer />

      {Boolean(flights.length) && (
        <>
          <LabelWrapper>
            <Label>Crew</Label>
            <Tooltip content="Select/unselect crew details to include/omit from email">
              <StyledIcon>
                <InfoIcon sx={{ color: textGray }} />
              </StyledIcon>
            </Tooltip>
          </LabelWrapper>
          <CrewTable
            isSelectable={template === 'book-flight'}
            selectedFields={selectedFields.crew}
            updateFields={setSelectedFields}
            crew={includeCrewWithoutFlight(emailDetails.crewDetails, crew)}
            addCrew={handleIncludeCrewWithoutFlight}
          />
          <ButtonV2
            size="small"
            variant="secondary"
            onClick={() => handleCopyOnlyTableToClipboard('crew')}
            style={{ marginTop: '1rem' }}
          >
            <CopyIcon />
            Copy Crew Table
          </ButtonV2>
        </>
      )}

      {access?.['Passports Management'] && (
        <AddPassport
          crew={emailDetails.crewDetails}
          crewPassports={crewPassports}
          passportsPassword={passportsPassword}
          updatePassports={setCrewPassports}
          setPassportsPassword={setPassportsPassword}
        />
      )}
      {renderModal()}
    </Modal>
  );
}

export default memo(SendModal);
