import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';
import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';

import {
  COMPLIANCE_RESPONSE_FAILURE_MESSAGE,
  COMPLIANCE_RESPONSE_SUCCESS_MESSAGE,
} from 'components/CrewMatrixPlanning/constants';
import { formatScheduleReqDates } from 'components/CrewMatrixPlanning/helpers';
import {
  AddOffsignerRequest,
  ComputeOilMajorComplianceRequest,
  ComputedIINOResponse,
  CrewBoardingRecordResponse,
  CrewChangeEventBasic,
  CrewChangeEventDetailed,
  CrewChangeEventSummary,
  CrewNote,
  CrewNotesResponse,
  CrewPersonalInfo,
  ListCrewScheduleResponse,
  ListScheduleRequest,
  OffsignerWithOptions,
  OnsignerDetails,
  OnsignerSearchResponse,
  RemoveOffsignerResponse,
  SuggestedOnsignerRequest,
  SuggestedOnsignerResponse,
  SyncDetails,
  VesselInfo,
  CrewBlockoffDates as CrewBlockoffRequest,
  CrewBlockoffResponse,
  ManualEmptyEvent,
  VesselScheduleDetails,
} from 'components/CrewMatrixPlanning/types';

import {
  getAuthTokenValue,
  handleAPIError,
  handleErrorToSentry,
  headers,
} from './helpers';

type BaseResponse = { success: boolean; message: string };
type ApiResponse<T> = BaseResponse & {
  eventUpdatedAt?: string | null;
  result?: T;
};

const EVENT_UPDATED_AT_HEADER = 'x-event-update-ts';
const UNAUTHENTICATED_RESPONSE: BaseResponse = {
  success: false,
  message: 'User is not authenticated. Please login.',
};

// helper to prepare request headers
const getHeaders = (eventUpdatedAt?: string) => {
  const authToken = getAuthTokenValue();
  return authToken
    ? {
        ...headers(authToken),
        // send custom event updatedAt as header
        ...(eventUpdatedAt
          ? { [EVENT_UPDATED_AT_HEADER]: eventUpdatedAt }
          : {}),
      }
    : null;
};

// get event updatedAt from response headers
const getHeadersInfo = (response: Response) =>
  response.headers.get(EVENT_UPDATED_AT_HEADER);

// override the event's updatedAt with the response header value fo the following requests
/* ---------------------------------------------------------------------------------------------- */
/* ------------------------- Event Update Related API requests: START --------------------------- */
/* ---------------------------------------------------------------------------------------------- */

// update an existing event - currently only event date update
export const updateCurrentMatrixEvent = async (
  event: CrewChangeEventDetailed,
  newDate: string
): Promise<ApiResponse<CrewChangeEventBasic>> => {
  const headers = getHeaders(event.updatedAt);
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${
    event.id
  }`;

  try {
    const response = await fetch(endpoint, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ eventDate: newDate }),
    });
    // throw exception for invalid response code
    const result = await response.json();
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Updated current event date successfully.',
      eventUpdatedAt: getHeadersInfo(response),
      result,
    };
  } catch (error) {
    console.log('Error updating current event date.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to update current event date.',
    };
  }
};

// adding an offsigner by dragging & dropping to on-off pairs
export const addNewOffsignerToEvent = async (
  event: CrewChangeEventDetailed,
  request: AddOffsignerRequest
): Promise<ApiResponse<OffsignerWithOptions>> => {
  const headers = getHeaders(event.updatedAt);
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${
    event.id
  }/offs`;

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify(request),
    });
    const result = await response.json();
    // show custom error message, if available
    handleAPIError(response.status, result);

    return {
      success: true,
      message: 'Offsigner added successfully to crew-change event.',
      eventUpdatedAt: getHeadersInfo(response),
      result,
    };
  } catch (error: any) {
    console.log('Error adding offsigner to crew-change event.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: error.message || 'Failed to add offsigner to crew-change event.',
    };
  }
};

// remove offsigner from an event
export const removeOffsignerFromEvent = async (
  event: CrewChangeEventDetailed,
  crewId: string
): Promise<ApiResponse<RemoveOffsignerResponse>> => {
  const headers = getHeaders(event.updatedAt);
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${
    event.id
  }/offs/${crewId}`;

  try {
    const response = await fetch(endpoint, {
      method: 'DELETE',
      headers,
    });
    const result = await response.json();
    // show custom error message, if available
    handleAPIError(response.status, result);

    return {
      success: true,
      message: 'Offsigner removed successfully from crew-change event.',
      eventUpdatedAt: getHeadersInfo(response),
      result,
    };
  } catch (error: any) {
    console.log('Error removing offsigner from crew-change event.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message:
        error.message || 'Failed to remove offsigner from crew-change event.',
    };
  }
};

// called after successfully saving an offsigner by calling add-offsigner endpoint
export const suggestAndSaveOnsigners = async (
  event: CrewChangeEventDetailed,
  request: SuggestedOnsignerRequest
): Promise<ApiResponse<SuggestedOnsignerResponse>> => {
  const headers = getHeaders(event.updatedAt);
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${
    event.id
  }/offs/suggest-save-ons`;

  try {
    const response = await fetch(endpoint, {
      method: 'PUT',
      headers,
      body: JSON.stringify(request),
    });
    const result = await response.json();
    // show custom error message, if available
    handleAPIError(response.status, result);

    return {
      success: true,
      message: 'Added new offsigner to the event successfully!.',
      eventUpdatedAt: getHeadersInfo(response),
      result,
    };
  } catch (error: any) {
    console.log('Error fetching suggested onsigner.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message:
        error.message ||
        `Failed to fetch suggested onsigner for ${request.offsigner.name}.`,
    };
  }
};

export const updateOffsignerReason = async (
  event: CrewChangeEventDetailed,
  crewId: string,
  reason: string
): Promise<ApiResponse<OffsignerWithOptions>> => {
  const headers = getHeaders(event.updatedAt);
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${
    event.id
  }/offs/${crewId}/reason`;

  try {
    const response = await fetch(endpoint, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ reason }),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Offsigner reason updated successfully.',
      eventUpdatedAt: getHeadersInfo(response),
      result,
    };
  } catch (error) {
    console.log('Error updating offsigner reason.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to update offsigner reason.',
    };
  }
};

// update selected/alternative onsigners combination
export const updateOnsignerStatus = async (
  event: CrewChangeEventDetailed,
  offsignerId: string,
  onsignerId: string,
  type: 'select' | 'deselect'
): Promise<
  ApiResponse<OffsignerWithOptions & { dummyOffsignerDeleted?: boolean }>
> => {
  const headers = getHeaders(event.updatedAt);
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${
    event.id
  }/offs/${offsignerId}/ons/${onsignerId}/${type}`;

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
    });
    const result = await response.json();
    // show custom error message, if available
    handleAPIError(response.status, result);

    return {
      success: true,
      message: 'Updated suggested onsigner successfully.',
      eventUpdatedAt: getHeadersInfo(response),
      result,
    };
  } catch (error: any) {
    console.log('Error updating onsigner.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      // show custom error message if onsigner already selected for another event
      message:
        error.message ||
        'Failed to update suggested onsigner. This onsigner is already selected for another event.',
    };
  }
};

// Accept/confirm OR unaccept/unconfirm an event. One API util is used with proper param
// Accepting will lock the event from further updating
// Unaccepting will unlock the event for updates & accepting again
export const updateMatrixEventStatus = async (
  event: CrewChangeEventDetailed
): Promise<ApiResponse<CrewChangeEventDetailed>> => {
  const { id: eventId, updatedAt, isAccepted } = event;
  const headers = getHeaders(updatedAt);
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${eventId}/${
    !isAccepted ? 'accept' : 'unaccept'
  }`;

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
    });
    const result = await response.json();
    // show custom error message, if available
    handleAPIError(response.status, result);

    return {
      success: true,
      message: `Crew change event ${
        !isAccepted ? 'accepted & confirmed' : 'unaccepted'
      } successfully.`,
      eventUpdatedAt: getHeadersInfo(response),
      result,
    };
  } catch (error: any) {
    console.log(
      `Error ${!isAccepted ? 'accepting' : 'unaccepting'} crew change event.`,
      error
    );
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message:
        error.message ||
        `Failed to ${
          !isAccepted ? 'accept/confirm' : 'unaccept/unconfirm'
        } the crew change event.`,
    };
  }
};

// confirms a manually searched & selected onsigner for an offsigner
export const confirmSelectedOnsigner = async (
  event: CrewChangeEventDetailed,
  offsignerId: string,
  onsignerId: string
): Promise<ApiResponse<OffsignerWithOptions>> => {
  const { id: eventId, updatedAt } = event;
  const headers = getHeaders(updatedAt);
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${eventId}/offs/${offsignerId}/manually-select-ons`;
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({ externalCrewId: onsignerId }),
    });
    const result = await response.json();
    // show custom error message, if available
    handleAPIError(response.status, result);

    return {
      success: true,
      message: 'Selected onsigner confirmed successfully.',
      eventUpdatedAt: getHeadersInfo(response),
      result,
    };
  } catch (error: any) {
    console.log('Error confirming selected onsigner.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: error.message || 'Failed to confirm selected onsigner.',
    };
  }
};

// select & confirm an searched onsigner without offsigner
export const selectOnsignerWithoutOffsigner = async (
  event: CrewChangeEventDetailed,
  onsignerId: string,
  rank: string
): Promise<ApiResponse<OffsignerWithOptions>> => {
  const { id: eventId, updatedAt } = event;
  const headers = getHeaders(updatedAt);
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${eventId}/add-onsigner-wo-offsigner`;
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({ externalCrewId: onsignerId, signOnRank: rank }),
    });
    const result = await response.json();
    // show custom error message, if available
    handleAPIError(response.status, result);

    return {
      success: true,
      message: 'Selected onsigner confirmed successfully.',
      eventUpdatedAt: getHeadersInfo(response),
      result,
    };
  } catch (error: any) {
    console.log('Error confirming selected onsigner.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: error.message || 'Failed to confirm selected onsigner.',
    };
  }
};

// merge two events
export const mergeMatrixEvents = async (
  event: CrewChangeEventDetailed,
  targetEventId: number
): Promise<BaseResponse> => {
  const { id: eventId, updatedAt } = event;
  const headers = getHeaders(updatedAt);
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${eventId}/merge-into/${targetEventId}`;
  try {
    await fetch(endpoint, { method: 'POST', headers });
    return {
      success: true,
      message: 'Successfully merged events.',
    };
  } catch (error) {
    console.log('Error merging events.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to merge events.',
    };
  }
};

/* ---------------------------------------------------------------------------------------------- */
/* -------------------------- Event Update Related API requests: END ---------------------------- */
/* ---------------------------------------------------------------------------------------------- */

// create empty event
export const createNewCMPEvent = async (
  vesselImo: string,
  eventDate: string
): Promise<ApiResponse<ManualEmptyEvent>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events`;
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({ vesselImo, eventDate }),
    });
    const result = await response.json();
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Successfully created new event.',
      result,
    };
  } catch (error) {
    console.log('Error creating new event!', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to create new event.',
    };
  }
};

// delete user created event - has to be empty, i.e no offsigners
export const deleteEmptyEMPEvent = async (
  eventId: number
): Promise<ApiResponse<undefined>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${eventId}`;
  try {
    const response = await fetch(endpoint, {
      method: 'DELETE',
      headers,
    });
    const result = await response.json();
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Event deleted successfully.',
    };
  } catch (error: any) {
    console.log('Error deleting event!', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message:
        `Cannot be deleted. ${error.message}` || 'Failed to delete event.',
    };
  }
};

// crate a new block-off period
export const createBlockOffPeriod = async (
  crewId: string,
  request: CrewBlockoffRequest
): Promise<ApiResponse<CrewBlockoffResponse>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew/${crewId}/crew-blockoff-periods`;
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify(request),
    });
    const result = await response.json();
    // show custom error message, if available
    handleAPIError(response.status, result);

    return {
      success: true,
      message: 'Successfully created new blockoff period.',
      result,
    };
  } catch (error: any) {
    console.log('Error creating blockoff period.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: error.message || 'Failed to create blockoff period.',
    };
  }
};

// update an existing block-off period
export const updateBlockOffPeriod = async (
  periodId: number,
  crewId: string,
  request: CrewBlockoffRequest
): Promise<ApiResponse<CrewBlockoffResponse>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew/${crewId}/crew-blockoff-periods/${periodId}`;
  try {
    const response = await fetch(endpoint, {
      method: 'PUT',
      headers,
      body: JSON.stringify(request),
    });
    const result = await response.json();
    // show custom error message, if available
    handleAPIError(response.status, result);

    return {
      success: true,
      message: 'Successfully updated crew blockoff period.',
      result,
    };
  } catch (error: any) {
    console.log('Error updating blockoff period.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: error.message || 'Failed to update blockoff period.',
    };
  }
};

// delete an existing block-off period
export const deleteBlockOffPeriod = async (
  periodId: number,
  crewId: string
): Promise<ApiResponse<undefined>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew/${crewId}/crew-blockoff-periods/${periodId}`;
  try {
    const response = await fetch(endpoint, {
      method: 'DELETE',
      headers,
    });
    const result = await response.json();
    // show custom error message, if available
    handleAPIError(response.status, result);

    return {
      success: true,
      message: 'Successfully deleted crew blockoff period.',
    };
  } catch (error: any) {
    console.log('Error deleting blockoff period.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: error.message || 'Failed to delete blockoff period.',
    };
  }
};

export const fetchDataSyncDetails = async (): Promise<
  ApiResponse<SyncDetails>
> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/sync-jobs/last-sync-time`;
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Successfully fetched data sync details.',
      result,
    };
  } catch (error) {
    console.log('Error fetching data sync details.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch data sync details.',
    };
  }
};

// API endpoint to calculate crew-matrix planning compliance for oil majors
export const calculateCrewMatrixCompliance = async (
  complianceRequest: ComputeOilMajorComplianceRequest
): Promise<ApiResponse<ComputedIINOResponse>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/compliance/iino/compute-reqs`;

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify(complianceRequest),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: COMPLIANCE_RESPONSE_SUCCESS_MESSAGE,
      result,
    };
  } catch (error) {
    console.log('Error calculating oil major compliance.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: COMPLIANCE_RESPONSE_FAILURE_MESSAGE,
    };
  }
};

export const fetchCrewMatrixVessels = async (): Promise<
  ApiResponse<VesselInfo[]>
> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/vessels`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Crew matrix vessels fetched successfully.',
      result,
    };
  } catch (error) {
    console.log('Error fetching crew-matrix vessels.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch crew-matrix vessels.',
    };
  }
};

// fetch event-level onboard crew for a vessel
export const fetchMatrixEventDetails = async (
  eventId: number,
  signal?: AbortSignal
): Promise<ApiResponse<CrewChangeEventDetailed>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/${eventId}`;
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
      signal,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Matrix event details fetched successfully.',
      result,
    };
  } catch (error) {
    console.log('Error fetching matrix event details.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch matrix event details.',
    };
  }
};

// crew-change events without on-off pair details
// lighter version of matrix events, fethced in bulk (for entire calendar year)
export const fetchMatrixScheduleEvents = async (
  fromDate: string,
  toDate: string
): Promise<ApiResponse<CrewChangeEventSummary[]>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/org?fromDate=${fromDate}&toDate=${toDate}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Crew matrix events fetched successfully!.',
      result,
    };
  } catch (error) {
    console.log('Error fetching crew matrix events.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch crew matrix events.',
    };
  }
};

// fetch crew-change events for a vessel
export const fetchVesselScheduleEvents = async (
  vesselIMO: string
): Promise<ApiResponse<CrewChangeEventSummary[]>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/org?vesselImo=${vesselIMO}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Events fetched for vessel successfully!.',
      result,
    };
  } catch (error) {
    console.log('Error fetching events for vessel.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch events for vessel.',
    };
  }
};

// modified use of `fetchMatrixScheduleEvents` to fetch all events of a crew ID
export const fetchCrewScheduleEvents = async (
  crewId: string
): Promise<ApiResponse<CrewChangeEventSummary[]>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew-change-events/org?crewId=${crewId}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Events fetched for crew successfully!.',
      result,
    };
  } catch (error) {
    console.log('Error fetching events for crew.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch events for crew.',
    };
  }
};

export const createCrewNote = async (
  crewId: string,
  note: string
): Promise<ApiResponse<CrewNote>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew/${crewId}/crew-notes`;
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({ note }),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Successfully created note for crew.',
      result,
    };
  } catch (error) {
    console.log('Error creating crew note.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to create note for crew.',
    };
  }
};

export const fetchCrewNotes = async (
  crewId: string,
  page: number = 0 // `0` indicates the first page
): Promise<ApiResponse<CrewNotesResponse>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew/${crewId}/crew-notes?page=${page}`;
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Successfully fetched crew notes.',
      result,
    };
  } catch (error) {
    console.log('Error fetching crew notes.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch crew notes.',
    };
  }
};

// only note creator can delete a note
export const deleteCrewNote = async (
  crewId: string,
  noteId: number
): Promise<ApiResponse<undefined>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew/${crewId}/crew-notes/${noteId}`;
  try {
    await fetch(endpoint, {
      method: 'DELETE',
      headers,
    });
    return {
      success: true,
      message: 'Deleted crew note successfully.',
    };
  } catch (error) {
    console.log('Error deleting crew note.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to delete crew note.',
    };
  }
};

// API endpoint to get vessel onboard crew-schedule - for vessel-centric onboard crew timeline view
export const fetchVesselCrewSchedules = async (
  vesselId: number,
  signal?: AbortSignal
): Promise<ApiResponse<VesselScheduleDetails>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const { fromDate, toDate } = formatScheduleReqDates();
  const endpoint = `${getAPIUrl()}/crew-matrix/api/v2/vessels/${vesselId}/crew-schedule?fromDate=${fromDate}&toDate=${toDate}`;
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
      signal,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Successfully fetched crew schedules of current year.',
      result: { [vesselId]: result },
    };
  } catch (error) {
    console.log('Error fetching crew schedules.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch crew schedules of current year.',
    };
  }
};

// crew employment history - displayed in crew info sidepanel
export const fetchCrewBoardingRecords = async (
  crewId: string
): Promise<ApiResponse<CrewBoardingRecordResponse>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew/${crewId}/boarding-records`;
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Crew boarding records fetched successfully.',
      result,
    };
  } catch (error) {
    console.log('Error fetching crew boarding records.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch crew boarding records.',
    };
  }
};

export const fetchCrewPersonalInfo = async (
  crewId: string
): Promise<ApiResponse<CrewPersonalInfo>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew/${crewId}`;
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Crew personal info fetched successfully.',
      result,
    };
  } catch (error) {
    console.log('Error fetching crew personal info.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch crew personal info.',
    };
  }
};

// API endpoint to get org crew list schedules - for org-centric crew timeline view
export const fetchOrgCrewSchedules = async (
  params: ListScheduleRequest
): Promise<ApiResponse<ListCrewScheduleResponse>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const {
    page = 0,
    name, // crew name - if available, provides result for a specific crew
    countryCode: nationalityIso3, // country code - if available, provides result for a specific country
    ...rest // regular & blockoff dates params
  } = params;
  // create query params from function params
  const queryParams = new URLSearchParams({
    page: page.toString(),
    ...(name ? { name } : {}),
    ...(nationalityIso3 ? { nationalityIso3 } : {}),
    ...rest,
  });
  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew/list-schedule?${queryParams}`;
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Organization crew schedules fetched successfully.',
      result,
    };
  } catch (error) {
    console.log('Error fetching org crew shcedules.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch org crew shcedules.',
    };
  }
};

export const searchOnsigners = async (request: {
  name?: string;
  page?: number;
}): Promise<ApiResponse<OnsignerSearchResponse>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const queryParams = new URLSearchParams(mapValues(pickBy(request), String));
  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew?${queryParams}`;
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers,
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Onsigner search result fetched successfully!',
      result,
    };
  } catch (error) {
    console.log('Error fetching onsigner search result.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch onsigner search result.',
    };
  }
};

export const fetchOnsignerDetails = async (
  onsignerId: string,
  vesselId: number,
  eventDate: string
): Promise<ApiResponse<OnsignerDetails>> => {
  const headers = getHeaders();
  if (!headers) {
    return UNAUTHENTICATED_RESPONSE;
  }

  const endpoint = `${getAPIUrl()}/crew-matrix/api/v1/crew/${onsignerId}/crew-with-meta`;
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({ vesselId, eventDate }),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Onsigner details fetched successfully!',
      result,
    };
  } catch (error) {
    console.log('Error fetching onsigner details.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch onsigner details.',
    };
  }
};
