import memoize from 'lodash/memoize';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import { calculateCrewMatrixCompliance } from 'api/crew-matrix';
import {
  TRACK_CALCULATE_COMPLIANCE,
  TRACK_FORCED_CALCULATE_COMPLIANCE,
} from 'utils/analytics/constants';
import { CCMatrixContext } from 'contexts/CCMatrixContext';

import {
  calculateCrewChangeCompliances,
  getComplianceRequest,
} from 'components/CrewMatrixPlanning/helpers';
import {
  COMPLIANCE_RESPONSE_FAILURE_MESSAGE,
  COMPLIANCE_RESPONSE_SUCCESS_MESSAGE,
} from 'components/CrewMatrixPlanning/constants';
import {
  Compliance,
  ComplianceState,
  ComplianceType,
  ComputeOilMajorComplianceRequest,
  ComputedExperience,
  TrimmedEvent,
  VesselWithOnBoardCrew,
} from 'components/CrewMatrixPlanning/types';

type CustomComplianceRequest = ComputeOilMajorComplianceRequest & {
  type: ComplianceType;
};

// hook to calculate the compliances & aggregated experiences for a vessel`
function useCMPOilCompliance({ vessel }: { vessel: VesselWithOnBoardCrew }) {
  const { currentEvent } = useContext(CCMatrixContext);

  const [loading, setLoading] = useState(false);
  const [compliances, setCompliances] = useState<ComplianceState>({
    before: null,
    after: null,
  });
  const [computedExperiences, setComputedExperiences] = useState<
    ComputedExperience[]
  >([]);

  const { eventDate, offsigners } = currentEvent || {};
  // event data with specific fields - `eventDate` & `offsigners`
  // to prevent unnecessary compliance calculation
  const trimmedEvent = useMemo(
    () => (eventDate ? ({ eventDate, offsigners } as TrimmedEvent) : null),
    [eventDate, offsigners]
  );
  const isPastEvent = useMemo(
    () => moment(eventDate).isBefore(moment(), 'day'),
    [eventDate]
  );
  const formattedCompliances = useMemo(
    () =>
      calculateCrewChangeCompliances({
        vessel,
        event: currentEvent!,
        compliances,
      }),
    [vessel, currentEvent, compliances]
  );

  const onCalculateCompliances = useCallback(
    (event: TrimmedEvent | null, recalculate?: boolean) => {
      if (!event) return;

      const requests = (Object.keys(compliances) as ComplianceType[]).reduce<
        CustomComplianceRequest[]
      >(
        (acc, type) =>
          type === 'before' && compliances[type]
            ? acc
            : [...acc, { ...getComplianceRequest(event, vessel, type), type }],
        []
      );

      setLoading(true);
      const promises = requests.map(
        (
          request
        ): Promise<{
          compliance: { [key: string]: Compliance[] };
          experiences: ComputedExperience[];
        } | null> =>
          new Promise((resolve) => {
            const { type, ...apiRequest } = request;
            calculateCrewMatrixCompliance(apiRequest).then((response) => {
              const { success, result: computedData } = response;
              if (!success) {
                resolve(null);
                return;
              }
              // update the computed experiences from compiled response
              if (type === 'after') {
                setComputedExperiences(computedData?.computedExperience || []);
              }
              resolve({
                compliance: { [type]: computedData?.oilMajorResults || [] },
                experiences: computedData?.computedExperience || [],
              });
            });
          })
      );

      Promise.all(promises).then((responses) => {
        const [before, after] = responses;
        const failedResponse = responses.some((res) => !res);
        if (failedResponse) {
          showToaster({
            message: COMPLIANCE_RESPONSE_FAILURE_MESSAGE,
            type: 'error',
            placement: 'left',
          });
        } else {
          trackUserAction(
            recalculate
              ? TRACK_FORCED_CALCULATE_COMPLIANCE
              : TRACK_CALCULATE_COMPLIANCE,
            recalculate ? 'click' : 'happened',
            { event }
          );
          showToaster({
            message: COMPLIANCE_RESPONSE_SUCCESS_MESSAGE,
            placement: 'left',
          });
        }
        setLoading(false);
        setCompliances((prevCompliances) => ({
          ...prevCompliances,
          ...((before?.compliance as ComplianceState | undefined) || {}),
          ...((after?.compliance as ComplianceState | undefined) || {}),
        }));
        // in case of Recalculate, only one result in responses array
        setComputedExperiences(after?.experiences || before?.experiences || []);
      });
    },
    [vessel, compliances]
  );

  useEffect(() => {
    if (isPastEvent || !vessel.onBoardCrew.length) {
      return;
    }
    // memoize to prevent unnecessary calls
    memoize(onCalculateCompliances)(trimmedEvent);
  }, [isPastEvent, trimmedEvent, vessel]); // eslint-disable-line

  return {
    loading,
    isPastEvent,
    trimmedEvent,
    compliances,
    formattedCompliances,
    computedExperiences,
    onCalculateCompliances,
  };
}

export default useCMPOilCompliance;
