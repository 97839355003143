import uniqBy from 'lodash/uniqBy';
import { useEffect, memo, useContext, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { useAppDispatch } from 'hooks';
import { trackUserAction } from 'lib/amplitude';
import { fetchVesselCrewChangePlans } from 'api/flotilla';
import { selectCrewChangeEvent, updateSavedCCPlans } from 'redux/actions';
import { samplePlans } from 'utils/sample-data/crew-change-plans';
import { TRACK_CREW_CHANGE_START_EMPTY } from 'utils/analytics/constants';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';
import { RootState } from 'redux/types';

import { green, white } from 'lib/colors';
import { Loader, Tooltip } from 'components/shared';
import PlanDetails from './PlanDetails';
import {
  StyledButton,
  PlansContainer,
  LoaderWrapper,
  LoaderButton,
  Text,
  ButtonWrapper,
  NewPlanButton,
} from './common';
import { LoadingState, FetchingType, PlanListProps } from './types';

import CardContainer from '../common/CardContainer';
import CardHeader from '../common/CardHeader';

const MotionComponent = motion(PlansContainer);

function SavedCompletePlans({ vessel }: PlanListProps) {
  const dispatch = useAppDispatch();
  const plans = useSelector(
    (state: RootState) => state.crewChangeResources.plans
  );

  const { isInWizard } = useContext(SidePanelFieldContext);
  const [loadingState, setLoadingState] = useState<LoadingState>({
    initial: false,
    loadMore: false,
  });

  const showInitialLoader = loadingState.initial && !isInWizard;
  const vesselPlans = plans?.[vessel.id];
  const {
    data: storedPlans = [],
    meta: { currentPage = 0, totalPages = 0 } = {},
    newlySaved,
  } = vesselPlans || {};
  const crewChangePlans = isInWizard ? samplePlans.slice(0, 3) : storedPlans;
  const hasPlans = Boolean(crewChangePlans.length);
  const hasMore = currentPage < totalPages;
  const sectionBackground =
    loadingState.initial || !(hasPlans || hasMore) ? white : `${green}10`;

  const updateLoadingState = (type: FetchingType, value: boolean) => {
    setLoadingState((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const handleFetchSavedPlans = async ({
    initial,
    loadMore,
    refresh,
  }: LoadingState) => {
    if (isInWizard) {
      return;
    }

    const page =
      ((initial || refresh) && 1) || (loadMore ? currentPage + 1 : 1);
    const canFetchPlans =
      refresh ||
      (initial && !plans?.[vessel.id]) ||
      (loadMore ? page <= totalPages : !totalPages);
    const fetchingType = loadMore ? 'loadMore' : 'initial';

    if (canFetchPlans) {
      updateLoadingState(fetchingType, true);
      const { success, response } = await fetchVesselCrewChangePlans(
        vessel.id,
        { page }
      );

      if (success && response) {
        const { data: newPlans, meta } = response;
        const updatedPlans = {
          meta,
          data:
            initial || refresh
              ? newPlans
              : uniqBy([...crewChangePlans, ...newPlans], 'uuid'),
        };
        dispatch(updateSavedCCPlans({ [vessel.id]: updatedPlans }));
      }
      updateLoadingState(fetchingType, false);
    }
  };

  // start a new/empty crew change
  const handleStartCrewChange = () => {
    dispatch(
      selectCrewChangeEvent({
        vesselId: vessel.id,
        event: {} as CrewEvent, // pass on empty object for empty cerw change
        active: 'plan',
      })
    );
    trackUserAction(TRACK_CREW_CHANGE_START_EMPTY, 'click', {
      vesselId: vessel.id,
    });
  };

  useEffect(() => {
    if (vesselPlans?.newlySaved) {
      setTimeout(() => {
        dispatch(
          updateSavedCCPlans({
            [vessel.id]: { ...vesselPlans, newlySaved: null },
          })
        );
      }, 5000);
    }
  }, [vessel.id, vesselPlans, dispatch]);

  useEffect(() => {
    if (!isInWizard && vessel.id) {
      handleFetchSavedPlans({ initial: true });
    }
  }, [isInWizard, vessel.id]); // eslint-disable-line

  const renderPlanList = () => {
    if (hasPlans || hasMore) {
      return crewChangePlans.map((plan) => (
        <PlanDetails
          key={plan.uuid}
          isCompleted
          summary={plan}
          wizardView={isInWizard}
          recentlyAdded={newlySaved === plan.uuid}
        />
      ));
    }

    return (
      <>
        <Text>No saved crew change plans available for this vessel.</Text>
        <ButtonWrapper>
          <Tooltip content="Click here to start an empty crew change plan">
            <NewPlanButton variant="primary" onClick={handleStartCrewChange}>
              Start a New Plan
            </NewPlanButton>
          </Tooltip>
        </ButtonWrapper>
      </>
    );
  };

  const renderLoaderContent = () => {
    if (
      isInWizard ||
      !hasMore ||
      loadingState.initial ||
      (totalPages && currentPage === totalPages)
    ) {
      return null;
    }

    return (
      <LoaderWrapper>
        {loadingState.loadMore ? (
          <CircularProgress size={20} />
        ) : (
          <LoaderButton
            variant="secondary"
            onClick={() => handleFetchSavedPlans({ loadMore: true })}
          >
            Load More
          </LoaderButton>
        )}
      </LoaderWrapper>
    );
  };

  return (
    <CardContainer
      style={{
        background: sectionBackground,
      }}
    >
      <CardHeader>
        <h4>Saved Plans</h4>
        {hasPlans && (
          <StyledButton
            variant="secondary"
            size="small"
            style={{ marginRight: 0 }}
            onClick={() => handleFetchSavedPlans({ refresh: true })}
          >
            Refresh
          </StyledButton>
        )}
      </CardHeader>

      <AnimatePresence>
        <MotionComponent>
          {showInitialLoader ? <Loader size={120} /> : renderPlanList()}
          {renderLoaderContent()}
        </MotionComponent>
      </AnimatePresence>
    </CardContainer>
  );
}

export default memo(SavedCompletePlans);
