import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';

import { formatDate } from 'utils/format-date';
import { VesselInfo, VesselSummary } from 'components/CrewMatrixPlanning/types';
import { RootState } from 'redux/types';

type CalendarEventsProps = {
  selectedVessel: VesselInfo | null;
  scheduleDate: string;
};

const getScheduleEvents = (state: RootState) =>
  state.crewChangeMatrix.scheduleEvents;
const getFeedbacks = (state: RootState) => state.crewChangeMatrix.feedback;

// wrapper function to provide a selector that returns feedbacks
export const getEventFeedbacks = (eventId: number | undefined) =>
  createSelector(
    getFeedbacks,
    (feedbacks) => (feedbacks && eventId && feedbacks[eventId]) || []
  );

// wrapper function to provide a selector that returns summary events
export const getVesselSummaryEvents = (vesselId: number) =>
  createSelector(getScheduleEvents, (scheduleEvents) =>
    sortBy(
      filter(
        uniqBy(flatten(values(scheduleEvents)), 'id'),
        ({ vessel }) => vessel?.id === vesselId
      ),
      'eventDate'
    )
  );

// wrapper function to provide a selector that returns summary event dates
export const getVesselEventDates = (vesselId: number) =>
  createSelector(getVesselSummaryEvents(vesselId), (vesselSummaryEvents) =>
    groupBy(vesselSummaryEvents, ({ eventDate }) =>
      formatDate(eventDate, 'DD MMM, YYYY')
    )
  );

// wrapper function to provide a selector that returns events of a month
export const getVesselEventsOfMonth = (props: CalendarEventsProps) =>
  createSelector(getScheduleEvents, (scheduleEvents) => {
    const { selectedVessel, scheduleDate } = props;
    // util to find the selected vessel events for this month
    // if no vessel selected, find events for all vessels
    return uniqBy(flatten(Object.values(scheduleEvents || {})), 'id').filter(
      ({ vessel, eventDate }) =>
        (selectedVessel ? selectedVessel.id === vessel.id : true) &&
        moment(eventDate).isSame(moment(scheduleDate), 'month')
    );
  });

// wrapper function to provide a selector that returns formatted list of vessel events in calendar
export const getCalendarVesselSummaryList = (props: CalendarEventsProps) =>
  createSelector(getVesselEventsOfMonth(props), (eventsOfMonth) =>
    eventsOfMonth
      .reduce<VesselSummary[]>((acc, event) => {
        const { vessel: eventVessel, eventDate } = event;
        const includedVessel = acc.find(
          ({ id: vesselId }) => eventVessel.id === vesselId
        );
        const { eventDates: existingEventDates = [] } = includedVessel || {};
        const hasEventDate = existingEventDates.includes(eventDate);
        return includedVessel
          ? uniqBy(
              [
                {
                  ...includedVessel,
                  eventDates: hasEventDate
                    ? existingEventDates
                    : uniq([...existingEventDates, eventDate]),
                  eventCount: includedVessel.eventCount + 1,
                  rowCount: includedVessel.rowCount + (hasEventDate ? 1 : 0),
                },
                ...acc,
              ],
              'id'
            )
          : [
              ...acc,
              {
                eventDates: [eventDate],
                id: eventVessel.id,
                vesselName: eventVessel.name || '',
                eventCount: 1,
                rowCount: 1,
              },
            ];
      }, [])
      .sort((a, b) => a.vesselName.localeCompare(b.vesselName))
  );
