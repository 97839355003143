import uniqBy from 'lodash/uniqBy';
import { SyntheticEvent, memo, useCallback, useEffect, useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import {
  Autocomplete,
  Popper,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import styled from 'styled-components/macro';

import { useAppDispatch, useDebounce } from 'hooks';
import { gray50, lightPurple, red, textBlack, textGray } from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import { savePortNotes, searchPorts } from 'api/flotilla';
import { fetchPortNotesAsync as refetchPortNotes } from 'redux/thunks';
import {
  TRACK_CREATE_CCPANEL_PORT_NOTES,
  TRACK_CREATE_PORT_NOTES,
  TRACK_CREATE_SIDEPANEL_PORT_NOTES,
} from 'utils/analytics/constants';
import { SearchedPort } from 'utils/types';

import { Modal } from 'components/shared';
import { StyledButton } from 'components/CrewChangePanel/common';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  .MuiInputLabel-root,
  .MuiInputBase-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input {
    font-size: 0.85rem;
    line-height: 1.2rem;
    font-family: HK Grotesk, Roboto;
  }

  .MuiInputBase-input {
    padding: '0.5rem 0.9rem';
    padding-right: 0;
  }

  .MuiSvgIcon-root {
    height: 1.3rem;
    width: 1.3rem;
  }
`;

const ButtonsWrapper = styled(FlexWrapper)`
  width: 100%;
`;

const ContinueButton = styled(StyledButton)`
  background: ${lightPurple};
  border: none;
`;

const InputWrapper = styled.div<{ $isNotes?: boolean }>`
  align-items: ${({ $isNotes }) => ($isNotes ? 'flex-start' : 'center')};
  display: grid;
  grid-template-columns: 80px 3fr;
  grid-gap: 10px;
  margin-bottom: 0.75rem;
  width: 100%;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    width: 90% !important;
    grid-template-columns: 1.33fr 3fr;
  }
`;

const Title = styled(FlexWrapper)`
  font-size: 0.9rem;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 0.9rem;
  color: ${textGray};
  margin-bottom: 1.25rem;
`;

const Asterisk = styled.span`
  color: ${red};
  margin-left: 2px;
`;

const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  font-family: HK Grotesk, Roboto;
  border: 1px solid ${gray50};
  border-radius: 4px;
  &:hover {
    border: 1px solid ${textBlack};
  }
  &::placeholder {
    color: ${textGray} !important;
  }
`;

const StyledPopper = muiStyled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-loading': {
    fontSize: '0.85rem',
    fontFamily: 'HK Grotesk, Roboto',
  },
}));

const listStyles = {
  maxHeight: '250px',
  fontSize: '0.85rem',
  padding: 0,
  lineHeight: '1.2rem',
  fontFamily: 'HK Grotesk, Roboto',
};

type Props = {
  view: 'ccpanel' | 'sidepanel' | 'action-bar';
  portLocode?: string;
  closeModal: () => void;
};

function PortNotesModal({ view, portLocode, closeModal }: Props) {
  const dispatch = useAppDispatch();

  const [portQuery, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selected, setSelected] = useState<SearchedPort | null>(null);
  const [portsList, setPortsList] = useState<SearchedPort[]>([]);
  const [notes, setNotes] = useState('');

  const handleFetchPorts = useDebounce(async (text: string) => {
    setLoading(true);
    const { success, portsResponse } = await searchPorts(text);

    if (!success || !portsResponse?.results?.length) {
      setLoading(false);
      return;
    }

    const { results: ports } = portsResponse;
    setPortsList((prevPorts) => uniqBy([...prevPorts, ...(ports || [])], 'id'));
    setLoading(false);
  });

  const handleAddNotes = useCallback(async () => {
    if (!selected) return;

    const { locode } = selected;
    setSubmitting(true);
    const { success, message } = await savePortNotes(locode, {
      text: notes,
    });
    showToaster({
      message,
      type: success ? 'success' : 'error',
      testId: 'e2e_port-notes-success-toaster',
    });
    // fetch port notes after creating one & update submitting state
    await dispatch(refetchPortNotes({ locode, refetch: true }));
    setSubmitting(false);

    if (success) {
      closeModal();
      let action = TRACK_CREATE_PORT_NOTES;
      if (view === 'ccpanel') action = TRACK_CREATE_CCPANEL_PORT_NOTES;
      else if (view === 'sidepanel') action = TRACK_CREATE_SIDEPANEL_PORT_NOTES;
      trackUserAction(action, 'click', {
        locode: selected.locode,
      });
    }
  }, [selected, notes, closeModal, dispatch, view]);

  useEffect(() => {
    // fetch initial port when `portLocode` is available
    if (portLocode) {
      searchPorts(portLocode).then(({ portsResponse }) => {
        setSelected(portsResponse?.results?.[0] || null);
      });
    }
  }, []) // eslint-disable-line

  // call ports api with query update in dropdown input field
  useEffect(() => {
    let mounted = true;
    if (portQuery.length && mounted) {
      handleFetchPorts(portQuery);
    }
    // prevent fetching on unmounted component
    return () => {
      mounted = false;
    };
  }, [portQuery]); // eslint-disable-line

  const handleUpdatePortQuery = (event: SyntheticEvent, value: string) => {
    setQuery(value);
  };

  const handleUpdatePortNotes = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    setNotes(value);
  };

  const handleSelectPort = (
    event: SyntheticEvent,
    value: SearchedPort | string | null
  ) => {
    const selectedPort = !value || typeof value === 'string' ? null : value;
    setSelected(selectedPort);
  };

  const actions = (
    <ButtonsWrapper>
      <ContinueButton variant="secondary" onClick={closeModal}>
        Cancel
      </ContinueButton>
      <StyledButton
        className="e2e_submit-new-port-notes"
        variant="primary"
        disabled={!selected || !notes.trim().length || submitting || loading}
        onClick={handleAddNotes}
      >
        Add Notes
      </StyledButton>
    </ButtonsWrapper>
  );

  return (
    <Modal
      width={500}
      title="Add Port Notes"
      actions={actions}
      closeModal={closeModal}
    >
      <Wrapper>
        <Description>
          Select a port & add helpful notes. This will be available in
          crew-change.
        </Description>
        <InputWrapper>
          <Title>
            <span>
              Port<Asterisk>*</Asterisk>
            </span>
            <span>:</span>
          </Title>
          <Autocomplete
            freeSolo
            size="small"
            loading={loading}
            options={portsList}
            value={selected || null} // fallback to `null` for no option
            inputValue={portQuery}
            onChange={handleSelectPort}
            onInputChange={handleUpdatePortQuery}
            getOptionLabel={(option) => (option as SearchedPort).text}
            renderInput={(params) => (
              <TextField {...params} placeholder="Type port name or LOCODE" />
            )}
            ListboxProps={{ id: 'port-dropdown', style: listStyles }}
            PopperComponent={StyledPopper}
            sx={{ minWidth: 150 }}
          />
        </InputWrapper>

        <InputWrapper $isNotes>
          <Title>
            Notes<span>:</span>
          </Title>
          <StyledTextArea
            minRows={2}
            value={notes}
            onChange={handleUpdatePortNotes}
            placeholder="Add notes for this port"
          />
        </InputWrapper>
      </Wrapper>
    </Modal>
  );
}

export default memo(PortNotesModal);
