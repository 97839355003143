import isEqual from 'lodash/isEqual';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components/macro';

import { useWindowSize } from 'hooks';
import { titleize } from 'lib/string';
import { blue, gray20, green, red, textGray, white } from 'lib/colors';
import { getFlightData } from 'lib/alasql/flights';
import { formatDate } from 'utils/format-date';
import { Crew, Flight, Port } from 'utils/types/crew-change-types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import {
  FlightRawData,
  Tooltip,
  Modal,
  LottieAnimation,
} from 'components/shared';
import { OffsignerIcon, OnsignerIcon } from 'components/icons';
import AllFlightResults from 'components/shared/AllFlightResults';
import { EmptyFooter } from './FlightConnection/common';

import { StyledButton } from '../../common';
import {
  ActiveFlight,
  FlightFilters,
  FlightSelectProps,
  ReadOnlyFlightCrew,
  ReadOnlyFlightFilters,
  ReadOnlyPort,
  SelectInModal,
  UpdateFilter,
} from '../../types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled(FlexWrapper)`
  justify-content: flex-start;
  width: 100%;
  flex-shrink: 0;
`;

const FiltersText = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0.75rem 0 0.5rem 0.8rem;
  font-style: italic;
`;

const AnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  height: 200px;
`;

const AnimationTitle = styled.div`
  font-size: 1rem;
  font-style: italic;
  letter-spacing: 0.1rem;
  margin-top: 0.5rem;
  color: ${textGray};
`;

const ButtonsWrapper = styled(FlexWrapper)`
  width: 100%;
  justify-content: space-between;
`;

const CancelButton = styled(StyledButton)`
  background: ${blue}20;
  color: ${blue};
`;

const BackIcon = styled(ChevronLeftIcon)`
  font-size: 16px !important;
  color: ${white};
  margin-right: 0.5rem;
`;

const modalStyles = {
  margin: 0,
  padding: '0.25rem 0',
  paddingLeft: '0.75rem',
  overflowX: 'hidden',
  background: gray20,
};

type Props = {
  currentFlight: {
    flightId?: string;
    departureTime?: string;
    confirmed?: boolean;
    port?: ReadOnlyPort; // available when viewing saved report in read-only view
    crew: Crew | ReadOnlyFlightCrew;
  };
  filters: FlightFilters | ReadOnlyFlightFilters;
  flights: Flight[];
  closeModal: () => void;
  onSelect?: SelectInModal;
  isReportView?: boolean;
};

// this modal is used in flights table filter view (for crew-change planning)
// and in saved report read-only view
// context in unavailable in latter case
function AllFlightsModal({
  currentFlight, // can be an empty flight
  filters,
  flights,
  isReportView,
  onSelect,
  closeModal,
}: Props) {
  const {
    flightId,
    departureTime,
    crew,
    port: readOnlyPort,
    confirmed,
  } = currentFlight;
  const [width] = useWindowSize();
  const { name: crewName, homeAirport, type } = crew;
  const { planningData: { ports = [] } = {}, updateFilters } =
    useContext(CCPanelContext);

  const { locode, duplicate, source, departures } = filters;
  const locodeKey = duplicate ? `${locode}--${source}` : locode;
  const modalWidth = useMemo(
    () => (width >= 1080 ? 1200 : width * (4 / 5)),
    [width]
  );
  const { name: portName, selectedAirport } = useMemo(
    () =>
      readOnlyPort || (ports.find((port) => port.locode === locode) as Port),
    [ports, readOnlyPort, locode]
  );
  const initialSelected = useMemo(
    () =>
      confirmed && flightId
        ? flights.find(({ originalId }) => originalId === flightId)
        : undefined,
    [confirmed, flightId, flights]
  );
  const [ready, setReady] = useState(false);
  // batch filter changes into this state & update with `UPDATE` button
  const [combinedFilters, setCombinedFilters] = useState<
    FlightFilters | ReadOnlyFlightFilters
  >(filters);
  // currently selected flight in the list
  const [selected, setSelected] = useState<Flight | undefined>(initialSelected);
  // id of raw flight data
  const [rawFlightId, setRawFlightId] = useState('');

  // check if selected flight is updated
  const updateDisabled = useMemo(
    () => isEqual(initialSelected, selected),
    [selected, initialSelected]
  );
  const flightData = useMemo(
    () => rawFlightId && getFlightData(rawFlightId),
    [rawFlightId]
  );
  const flightDepartureDate = useMemo(() => {
    // date coming from selected flight's dearture time
    const dateStr =
      departureTime ||
      (crew.type === 'onsigner' ? departures?.onsigner : departures?.offsigner);
    return dateStr && formatDate(dateStr);
  }, [crew.type, departureTime, departures]);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 2000);
  }, []);

  const isOnsigner = type.toLowerCase() === 'onsigner';
  const from = isOnsigner
    ? homeAirport?.iataCode || ''
    : selectedAirport?.iataCode || '';
  const to = isOnsigner
    ? selectedAirport?.iataCode || ''
    : homeAirport?.iataCode || '';

  const handleUpdateFilter: UpdateFilter = useCallback((filterItem) => {
    setCombinedFilters((prevFilters) => ({
      ...prevFilters,
      ...filterItem,
    }));
  }, []);

  // batch update filters & newly selected flight for current crew
  const handleCombinedUpdate = () => {
    if (!selected || !onSelect) {
      return;
    }

    // update filters in flights table filters view
    if (!isReportView) {
      updateFilters({
        type: 'UPDATE_FILTER',
        payload: { locodeKey, item: combinedFilters },
      });
    }

    // update the planning/report data with new flight
    onSelect(selected as ActiveFlight, crew.id, combinedFilters);
    closeModal();
  };

  const renderTitle = () => {
    if (rawFlightId && flightData) {
      return <span>Raw Flight Data</span>;
    }

    const isOnsigner = type === 'onsigner';
    return (
      <FlexWrapper style={{ gap: '0.5rem' }}>
        All Flights for {crewName}
        <Tooltip content={titleize(type)}>
          <FlexWrapper>
            {isOnsigner ? (
              <OnsignerIcon color={green} size={28} />
            ) : (
              <OffsignerIcon color={red} size={28} />
            )}
          </FlexWrapper>
        </Tooltip>
      </FlexWrapper>
    );
  };

  const renderModalContent = () => {
    if (rawFlightId && flightData) {
      return <FlightRawData flight={flightData} />;
    }
    // add a `ready` state to render the modal early with loader
    return ready ? (
      <>
        {/* render the port name for active flights & filters in cc panel modal view */}
        <ContentWrapper>
          <FiltersText>
            Showing flights & filters for{' '}
            <b>
              {locode}, {portName}
            </b>
            {flightDepartureDate && (
              <span style={{ marginLeft: '6px' }}>
                on <b>{flightDepartureDate}</b>
              </span>
            )}{' '}
            {duplicate && <span>({source} flights)</span>}
          </FiltersText>
        </ContentWrapper>

        <AllFlightResults
          modalView="ccpanel"
          from={from}
          to={to}
          flights={flights}
          filters={combinedFilters}
          updateFilter={handleUpdateFilter}
          selectProps={selectProps}
          openRawData={(flightId) => {
            setRawFlightId(flightId);
          }}
        />
      </>
    ) : (
      <AnimationWrapper>
        <AnimationTitle>Preparing flights...</AnimationTitle>
        <LottieAnimation name="64012-loader-plane-voi" width="80%" />
      </AnimationWrapper>
    );
  };

  const actions =
    (rawFlightId && (
      <ButtonsWrapper>
        <StyledButton variant="primary" onClick={() => setRawFlightId('')}>
          <BackIcon />
          Go Back
        </StyledButton>
      </ButtonsWrapper>
    )) ||
    (ready ? (
      <ButtonsWrapper>
        <CancelButton variant="secondary" onClick={closeModal}>
          Cancel
        </CancelButton>
        <StyledButton
          className="e2e_flight-results-update"
          disabled={updateDisabled}
          variant="primary"
          onClick={handleCombinedUpdate}
        >
          Update
        </StyledButton>
      </ButtonsWrapper>
    ) : (
      <StyledButton variant="primary" onClick={closeModal}>
        Close
      </StyledButton>
    ));

  // pass these props to flight results list component to select a flight
  const selectProps: FlightSelectProps = {
    selected,
    selectFlight: (flight: Flight) =>
      // @ts-ignore
      setSelected({ ...flight, confirmed: true }),
  };
  const customProps = isReportView
    ? {
        actions: !ready ? <EmptyFooter /> : actions,
        ...(ready ? { closeModal } : {}),
      }
    : { actions, closeModal };

  return (
    <Modal
      disableExternalClose
      width={modalWidth}
      height="90vh"
      title={renderTitle()}
      styles={modalStyles}
      {...customProps}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default memo(AllFlightsModal);
