import toLower from 'lodash/toLower';
import toUpper from 'lodash/toUpper';
import trim from 'lodash/trim';
import moment from 'moment';

import { gray50, red, white } from 'lib/colors';
import { isDevelopment } from 'lib/environments';

import { formatDate } from 'utils/format-date';
import { COLORS, CREW_RANK_SORT_ORDER } from '../constants';
import {
  OnBoardCrew,
  RemoveOffsignerResponse,
  ListScheduleStatus,
  TrimmedEvent,
  OnsignerDetails,
  RowSegment,
  EventUpdateType,
  CrewExperience,
  OffsignerWithOptions,
} from '../types';

// possible cases are `isDevelopment` & `isDeployPreview`
export const canShowFeature = () => isDevelopment;

export const getRowColor = (index: number) => {
  const arrLen = COLORS.length;
  const colorIndex = index > arrLen - 1 ? index % arrLen : index;
  return COLORS[colorIndex];
};

export const geteSegmentBackground = (
  segment: RowSegment & { status?: ListScheduleStatus },
  order: number
) => {
  const { empty, status, past, blockedOff } = segment;
  const isOffPeriod = status === 'OFF' || status === 'PLANNED_OFF';
  const isOff = isOffPeriod || blockedOff;
  const color = getRowColor(order);
  // show white background for empty & off period segments
  const offBgColor = isOffPeriod ? white : `${red}10`;
  const backgroundColor =
    (isOff && offBgColor) ||
    (empty ? white : (past && `${color}20`) || `${color}60`);
  // gradient only for active/on segments
  const radialGradientColor = isOffPeriod ? gray50 : red;
  const regularGradient =
    (!empty &&
      (isOff
        ? `
      repeating-radial-gradient(
        circle,
        ${radialGradientColor}10,
        ${radialGradientColor}10 1px,
        ${radialGradientColor}10 3px,
        ${radialGradientColor}50 6px
      )
      `
        : `
      repeating-linear-gradient(
        ${past ? '-45deg' : '45deg'},
        ${color}20,
        ${color}20 1px,
        ${color}20 3px,
        ${color}60 6px
      )`)) ||
    '';
  const hoveredGradient =
    (!empty &&
      (isOff
        ? `
      repeating-radial-gradient(
        circle,
        ${radialGradientColor}20,
        ${radialGradientColor}20 1px,
        ${radialGradientColor}20 3px,
        ${radialGradientColor}60 6px
      )
      `
        : `
      repeating-linear-gradient(
        ${past ? '-45deg' : '45deg'},
        ${color}30,
        ${color}30 1px,
        ${color}30 3px,
        ${color}70 6px
      )`)) ||
    '';
  return {
    regular: `${backgroundColor} ${regularGradient}`,
    hovered: `${backgroundColor} ${hoveredGradient}`,
  };
};

// sort crew based on `currentRank` or `signOnRank`
// example - used for sorting onboard crew or on-off pairs
export const sortCrewByRank = (
  crewList: (OnBoardCrew | OffsignerWithOptions)[]
) =>
  [...crewList].sort(
    (a, b) =>
      CREW_RANK_SORT_ORDER.indexOf(toLower(a.currentRank || a.signOnRank)) -
      CREW_RANK_SORT_ORDER.indexOf(toLower(b.currentRank || b.signOnRank))
  );

// show a custom message when removing offsigner
// if removed offsigner is suggested as onsigner on other events
// otherwise, show the default message
export const formatResponseMessage = (
  response: RemoveOffsignerResponse,
  message: string,
  name: string
) => {
  const { eventsWithOffsignerAsOnsigner: events } = response;
  const additionalText = events.length
    ? `${name} is suggested as onsigner on event date(s) - ${events
        .map(({ eventDate }) => formatDate(eventDate, 'DD/MM/YY'))
        .join(', ')}.`
    : '';
  return `${message} ${additionalText}`.trim();
};

// format search query of event details page
export const formatSearchQuery = (event: { id: number; eventDate: string }) =>
  new URLSearchParams({
    eventDate: formatDate(event.eventDate, 'YYYY-MM-DD'),
    eventId: String(event.id),
  });

// filters out the selected onsigners of an event
export const getEventOnsigners = (event: TrimmedEvent) =>
  event.offsigners
    .map(({ selectedOnsigner }) => selectedOnsigner)
    .filter(Boolean) as OnsignerDetails[];

export const getOnboardDuration = (
  startDate: string | null,
  eventDate: string | undefined
) => {
  const dutationInDays = Math.floor(
    moment(eventDate).diff(moment(startDate), 'days')
  );
  const months = Math.floor(dutationInDays / 30);
  const days = dutationInDays % 30;

  let duration = '';

  if (!months) {
    duration = days > 1 ? `${days} days` : 'Less than a day';
  } else {
    duration = days
      ? `${months} month${months > 1 ? 's' : ''}, ${days} day${
          days > 1 ? 's' : ''
        }`
      : `${months} month${months > 1 ? 's' : ''}`;
  }

  return { duration, daysCount: dutationInDays };
};

// generate text for confirmation modal when event details updated
export const getEventUpdateText = (type: EventUpdateType) => {
  switch (type) {
    case 'block-off':
      return 'This event was recently updated because of crew block-off change.';
    case 'websocket':
      return 'This event was recently updated by another user.';
    case 'offsigner-reassigned':
      return 'This event was recently updated because of offsigner reassignment.';
    default:
      return 'This event was recently updated.';
  }
};

// util to determine if the crew is gas engineer qualified
export const isGasEngineerQualified = ({
  rank,
  experience,
}: {
  rank: string | undefined;
  experience: CrewExperience[] | undefined;
}) => {
  // calculate total experience for GAS experience type for the crew
  const gasEngineerExperience = (experience || []).reduce(
    (acc, item) =>
      toUpper(trim(item.experienceTypeVal)) === 'GAS'
        ? acc + item.yearsExperience
        : acc,
    0
  );
  // if the crew is 1/E or 2/E and has more than 1 year of GAS experience type
  return {
    years: gasEngineerExperience,
    isQualified:
      ['1/E', '2/E'].includes(toUpper(trim(rank))) && gasEngineerExperience > 1,
  };
};

// sort crew ranks based on pre-determined list
export const sortCrewRanks = (ranks: string[]) =>
  ranks.sort(
    (a, b) =>
      CREW_RANK_SORT_ORDER.indexOf(a.toLowerCase()) -
      CREW_RANK_SORT_ORDER.indexOf(b.toLowerCase())
  );
