import every from 'lodash/every';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import uniqBy from 'lodash/uniqBy';
import partition from 'lodash/partition';
import map from 'lodash/map';
import filter from 'lodash/filter';
import some from 'lodash/some';
import values from 'lodash/values';
import moment from 'moment';
import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import type {
  AmadeusAirport,
  Airport as GreywingAirport,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import type { FlightSegment } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import sleep from 'lib/sleep';
import { titleize } from 'lib/string';
import { getFlightReqTime, removeWhiteSpace, secondsToDHM } from 'lib/common';
import {
  duplicateReqFlights,
  getCommonFlights,
  isCompletedRequest,
  populateRecordTable,
  populateRequestTable,
  showTableData,
} from 'lib/alasql/flights';
import { fetchFlights as fetchFlightsApi } from 'api/flotilla';
import { formatDate } from 'utils/format-date';
import { ColumnVisibility } from 'redux/types';
import { PlanningData } from 'utils/types';
import {
  CO2Details,
  Crew,
  Flight,
  FlightRequest,
  Port,
} from 'utils/types/crew-change-types';
import { ResultFilters } from 'components/FlotillaSearch/types';

import { getLayovers, getLocodeKeyDetails, setActionTime } from './common';
import { mergePortsWithRoute } from './route';
import {
  ActiveFlight,
  Airline,
  DepartureInput,
  FlightParamsRange,
  FlightFilters,
  FlightFilterType,
  FlightRow,
  LayoverAirport,
  PortCardType,
  ReadOnlyFlight,
  ReadOnlyPort,
  ReadOnlyFlightRow,
  PopupFlight,
  EmptyFlight,
  FareType,
  FormatPortAirportName,
  PopupPort,
  DuplicateReadOnlyPort,
  FlightReqDetails,
  ConfirmedFlight,
  PortProgressHandler,
  RoutePort,
  MergedPort,
  DepartureCrewUpdate,
  PortDates,
  ReadOnlyFlightFilters,
} from '../types';

type SortUtils = {
  [type in FlightFilterType]: (a: Flight, b: Flight) => number;
};
type FlightDetails = {
  [portLocode: string]: {
    port: Port;
    onsigner: string;
    offsigner: string;
    nearbyAirport: AmadeusAirport | GreywingAirport;
  };
};

// get the initial columns to be visible in a table
export const getFlightsInitialColumnVisibility = (
  columns: GridColDef[],
  crewColumns?: ColumnVisibility[] | null
) => {
  const defaultDisabled: string[] = [
    'CO2 (ton)',
    'SB No.',
    'SB Issue',
    'SB Expiry',
    'PP No.',
    'PP Issue',
    'PP Expiry',
    'Visa Country',
    'Visa Expiry',
  ];
  const fixedColumns: string[] = ['Action', 'Name', 'Flight'];
  return columns.map(({ field, headerName = '' }) => {
    const relatedCrewColumn = crewColumns?.find((col) => col.field === field);
    return {
      field,
      name: headerName,
      selected:
        // see if this is a matching field with crew columns visibility
        // if so, set the visibility based on that
        relatedCrewColumn
          ? relatedCrewColumn.selected
          : !defaultDisabled.includes(headerName),
      fixed: fixedColumns.includes(headerName),
    };
  }, [] as ColumnVisibility[]);
};

export const formatFlightPath = ({
  arrival,
  departure,
}: Flight | ActiveFlight | ReadOnlyFlight) =>
  arrival?.airportCode &&
  departure?.airportCode &&
  JSON.stringify([departure.airportCode, arrival.airportCode]);

export const formatPath = (path: string) =>
  path.split('to ').map((text) => text.trim().toUpperCase());

export const formatPortNearbyAirport: FormatPortAirportName = ({
  iataCode,
  name,
  cityName,
}) => {
  const baseName = `${iataCode}-${titleize(name)}`;
  return cityName
    ? `${iataCode}-${titleize(name)}, ${titleize(cityName)}`
    : baseName;
};

export const getFlightPath = (flight: string, layovers: string[]) => {
  const [from, to] = JSON.parse(flight);
  return `${from} ${layovers.length ? `-${layovers.join(' - ')}-` : '-'} ${to}`;
};

// non-required flights, i.e - departure & arrival airports are same
export const getUnneededFlightsCount = (
  crewList: Crew[],
  port: Port | ReadOnlyPort
) => {
  const { iataCode: portAirportCode = '' } = port.selectedAirport || {};
  return crewList.filter(
    ({ homeAirport }) => (homeAirport?.iataCode || '') === portAirportCode
  ).length;
};

// flights prepared for port popup
export const getFlightsInTable = (
  flights: (ActiveFlight | ReadOnlyFlight)[] = []
) =>
  flights.reduce((acc: PopupFlight[], flight) => {
    const path = formatFlightPath(flight);
    const { airports: layovers } = getLayovers(flight);

    return path
      ? acc.concat({ ...flight, connection: getFlightPath(path, layovers) })
      : acc;
  }, []);

export const totalFlightCost = (list: (ActiveFlight | ReadOnlyFlight)[]) =>
  list?.reduce((acc, { price }) => acc + (price?.amount || 0), 0).toFixed(0);

// check any of the flights in the table has fare information
export const hasFareInfo = (flights: (ActiveFlight | ReadOnlyFlight)[] = []) =>
  flights.some((f) => !!f.fareInformation);

export const getFlightRows = (flights: (ActiveFlight | ReadOnlyFlight)[]) =>
  flights.map((flight) => {
    const {
      arrival,
      departure,
      crew,
      flightNumbers = [],
      totalFlightTime,
      price: { amount = 0, currency = '' } = {},
    } = flight;
    const { airports: layovers = [], time: layoverTime = 0 } =
      getLayovers(flight);

    return {
      ...flight,
      id: flight.id,
      crew,
      flight: formatFlightPath(flight),
      cost: `${amount.toFixed(0)} ${currency}`,
      flightTime: totalFlightTime || 0,
      layoverTime,
      time: secondsToDHM(totalFlightTime * 3600),
      flightLayoverTime: secondsToDHM(layoverTime * 3600),
      layovers,
      flightNumbers: flightNumbers.map(removeWhiteSpace),
      arrivalTime: arrival && formatDate(arrival.time),
      departureTime: departure && formatDate(departure.time),
    };
  });

export const getReadOnlyFlightRows = (
  crewList: Crew[],
  flights: ReadOnlyFlight[] = [],
  portAirportCode: string,
  onlyUpdating?: boolean
): (ReadOnlyFlightRow | EmptyFlight)[] => {
  const rows = getFlightRows(flights);
  const flightRows = crewList.map((crew) => {
    const flight = rows.find((r) => r?.crew?.id === crew.id);
    return (
      flight || {
        id: String(crew.id),
        crew,
        path:
          // connection for unavailable flight
          crew.type === 'onsigner'
            ? `${crew.homeAirport?.iataCode || 'Unknown'} to ${portAirportCode}`
            : `${portAirportCode} to ${
                crew.homeAirport?.iataCode || 'Unknown'
              }`,
        flight: null,
      }
    );
  });

  // filter only the flights that can be updated, if the flag is true
  return onlyUpdating
    ? flightRows.filter((flight) => {
        if (!(flight as EmptyFlight).flight) return false;
        return (flight as ReadOnlyFlightRow).fetchedAt;
      }, false)
    : flightRows;
};

// replace a flight in flights list with a newly selected flight
export const replaceFlight = (
  newFlight: ActiveFlight,
  flights: ActiveFlight[]
) =>
  flights.map((flight) =>
    newFlight.crew.id === flight.crew.id
      ? { ...newFlight, confirmed: true }
      : flight
  );

// list of flights to show in compare view
export const getCompareableFlights = (
  flights: (ActiveFlight | EmptyFlight)[]
) => {
  const [empty, active] = partition(flights, ({ flight }) => !flight);
  return [
    // not-required flight(s) with same departure & arrival airports
    ...(empty as EmptyFlight[]).filter(({ path }) => {
      const [from, to] = formatPath(path);
      return from === to;
    }),
    // confirmed flights
    ...(active as FlightRow[]).filter(({ confirmed }) => confirmed),
  ];
};

// find departure dates of ports for both crew types
export const getPortDepartures = (port?: MergedPort) => {
  const { eta, etd } = port || {};
  const today = moment().endOf('day').toISOString();
  // default onsigner departure date: 2 days before ETA
  const defaultOnsignerDate = moment(eta)
    .endOf('day')
    .subtract(2, 'day')
    .toISOString();
  // fallback to today if default date is before today
  const onsignerDate = moment(defaultOnsignerDate).isAfter(moment(today))
    ? defaultOnsignerDate
    : today;
  // default offsigner departure date based on ETA: 24 hours after ETA
  const defaultOffsignerDateFromETA = moment(eta).add(24, 'hour').toISOString();
  // if ETD is before default offsigner date coming from ETA
  // set ETD as default offsigner date
  const defaultOffsignerDate = moment(etd).isBefore(
    moment(defaultOffsignerDateFromETA)
  )
    ? etd
    : defaultOffsignerDateFromETA;
  // fallback to current time, if default onsigner is after default offsigner, to cover any edge case
  const offsignerDate = moment(onsignerDate).isBefore(
    moment(defaultOffsignerDate)
  )
    ? defaultOffsignerDate
    : today;

  return {
    onsigner: onsignerDate,
    offsigner: offsignerDate,
  };
};

/********** Flight request & response related utils start **********/

// prepare flight requests for crew & port combinations
export const getFlightRequests = (
  planningData: PlanningData,
  departureInput?: DepartureInput
) => {
  const { locodeKey = '', departures } = departureInput || {};
  const { crew: crewList, ports, route } = planningData;
  const { locode: portLocode, portETA } = getLocodeKeyDetails(locodeKey);
  // get merged ports with route to cover multiple ETA cases
  const mergedPorts = mergePortsWithRoute(ports, route) as MergedPort[];
  // if departure is updated,  prepare requests for specific port only
  const flightDetails = (
    portLocode // available for departure update only
      ? mergedPorts.filter(
          // for a delarture update, filter port that is updated
          ({ locode, eta }) =>
            locode === portLocode && (!portETA || portETA === eta)
        )
      : mergedPorts
  ).reduce<FlightDetails>((acc, port) => {
    const { locode, selectedAirport, uniqETA } = port as MergedPort;
    if (selectedAirport) {
      const locodeKey = uniqETA ? `${locode}(${uniqETA})` : locode;
      const { onsigner, offsigner } = getPortDepartures(port) || {};
      return {
        ...acc,
        [locodeKey]: {
          port,
          onsigner: departures?.onsigner || onsigner,
          offsigner: departures?.offsigner || offsigner!,
          nearbyAirport: selectedAirport,
        },
      };
    }
    return acc;
  }, {});

  const formatFlightRequest = (type: string) =>
    crewList.reduce<FlightReqDetails[]>((acc, crew) => {
      const { homeAirport, type: crewType } = crew;
      const { iataCode } = homeAirport!;
      if (crewType.toLowerCase() !== type || !iataCode) {
        return acc;
      }

      Object.keys(flightDetails).forEach((locodeKey) => {
        const isOffsigner = type === 'offsigner';
        const { port, nearbyAirport, offsigner, onsigner } =
          flightDetails[locodeKey];
        const endCode = isOffsigner ? iataCode : nearbyAirport.iataCode;
        const startCode = isOffsigner ? nearbyAirport.iataCode : iataCode;
        const flightTime = isOffsigner ? offsigner : onsigner;
        const startDate = getFlightReqTime(flightTime);
        const requestId = `${startCode || ''}--${endCode || ''}--${startDate}`;
        acc.push({ requestId, crew, port });
      });

      return acc;
    }, []);

  return [
    ...formatFlightRequest('offsigner'),
    ...formatFlightRequest('onsigner'),
  ];
};

export const getReqFromId = (requestId: string): FlightRequest => {
  const [startCode, endCode, startDate] = requestId.split('--');
  return { startCode, endCode, startDate };
};

const uniqRequest = (
  requests: FlightReqDetails[],
  requestId: string,
  index: number
) => {
  const alreadyFetched =
    isCompletedRequest(requestId) ||
    // else check in current list of requests
    requests.slice(0, index).find((req) => requestId === req.requestId);
  const invalid = Object.values(getReqFromId(requestId)).some((val) => !val);
  return !invalid && !alreadyFetched;
};

const partitionRequests = (
  allRequests: FlightReqDetails[],
  handler?: PortProgressHandler
) =>
  allRequests.reduce<{
    uniqRequests: FlightReqDetails[];
    duplicateRequests: FlightReqDetails[];
  }>(
    (acc, request, index) => {
      const { requestId, port } = request;
      const isUniqRequest = uniqRequest(allRequests, requestId, index);
      if (isUniqRequest) {
        // prevent resetting progress for departure update
        handler?.(port, true);
        return { ...acc, uniqRequests: [...acc.uniqRequests, request] };
      }
      return {
        ...acc,
        duplicateRequests: [...acc.duplicateRequests, request],
      };
    },
    {
      uniqRequests: [],
      duplicateRequests: [],
    }
  );

const completeFetching = (callback: (value: boolean) => void) => {
  // adds half a second delay to set the action time
  // after flights are being confirmed in individual port-cards
  showTableData();
  callback(true);
  sleep(500).then(() => {
    setActionTime('flights', 'end');
  });
};

// fetch flights for requests created by `getFlightRequests`
// unique requests are fetched & duplicates are matched with fetched ones
export const fetchCrewFlights =
  (handler: PortProgressHandler) =>
  (planningData: PlanningData, departureInput?: DepartureInput) => {
    const allRequests = getFlightRequests(planningData, departureInput);
    const { uniqRequests, duplicateRequests } = partitionRequests(
      allRequests,
      !departureInput ? handler : undefined
    );
    // initialize flight requests table
    populateRequestTable(allRequests);
    return new Promise((resolve) => {
      // start calculating the requied time to get flights
      setActionTime('flights', 'start');
      const promises = uniqRequests.map(async (req) => {
        const flightRequest = getReqFromId(req.requestId);
        const { flights } = await fetchFlightsApi(flightRequest);
        const updateRecords = populateRecordTable(handler);
        updateRecords(req, flights);
      });

      // find flights for duplicate requests
      Promise.all(promises).then(() => {
        if (!duplicateRequests.length) {
          completeFetching(resolve);
          return;
        }

        duplicateRequests.forEach((requestData, index) => {
          const isDone = duplicateRequests.length === index + 1;
          duplicateReqFlights(requestData);
          if (isDone) completeFetching(resolve);
        });
      });
    });
  };

/********** Flight request & response related utils end **********/

/********** Utils to filter & sort of flights - similar to `search-frontend` **********/

const totalCO2Emissions = (flight: Flight) => {
  return ((flight?.co2 || []) as CO2Details[]).reduce(
    (acc, co2Details) => acc + (co2Details.co2Emissions || 0),
    0
  );
};

const flightDuration = (flight: Flight): number => {
  if (flight?.arrival?.time && flight?.departure?.time) {
    const departureDate = moment(flight.departure.time);
    const arrivalDate = moment(flight.arrival.time);
    return arrivalDate.diff(departureDate);
  } else {
    return -1;
  }
};

export const flightSortUtils: SortUtils = {
  Cheapest: (a, b) => {
    if (a?.price?.amount && b?.price?.amount) {
      return a.price.amount >= b.price.amount ? 1 : -1;
    } else if (a?.price?.amount) {
      return -1;
    }
    return 1;
  },
  Fastest: (a, b) => flightDuration(a) - flightDuration(b),
  'Lowest CO2': (a, b) => totalCO2Emissions(a) - totalCO2Emissions(b),
};

const convertToHour = (time: string | undefined) =>
  time ? moment(time).utc().hour() : 0;

// filter flights based on selecred layovers
export const allLayoversSelected = (
  stops: LayoverAirport[],
  layovers: string[]
) =>
  layovers.length
    ? // layovers filtering is all inclusive
      // i. e - select a flight ONLY if all layovers in filters are included
      every(layovers, (iataCode) =>
        includes(
          map(filter(stops, 'selected'), 'iataCode'),
          iataCode.split('-')[0]
        )
      )
    : true; // avoid filtering for direct flights

// filter flights based on selecred airlines
export const allAirlinesSelected = (
  airlines: Airline[],
  segments: FlightSegment[]
) => {
  const selectedAirlines = map(filter(airlines, 'selected'), ({ airline }) =>
    airline.toLowerCase()
  );
  return some(segments, ({ operatingCarrierName }) =>
    includes(
      selectedAirlines,
      (operatingCarrierName || '').trim().toLowerCase()
    )
  );
};

// find if flight is within and allowed arrival & departure hours
export const filterFlightSchedules = (
  filters: FlightFilters | ResultFilters | ReadOnlyFlightFilters,
  flight: Flight
) => {
  const { arrival, departure } = flight;
  const { arrivalTime, departureTime } = filters;
  const isArrivalAllowed =
    arrivalTime[0] <= convertToHour(arrival.time) &&
    convertToHour(arrival.time) <= arrivalTime[1];
  const isDepartureAllowed =
    departureTime[0] <= convertToHour(departure.time) &&
    convertToHour(departure.time) <= departureTime[1];

  return isArrivalAllowed && isDepartureAllowed;
};

// filter flight based on airport transfer
export const filterFlightForAirportTransfer = (
  layovers: string[],
  allowed: boolean | undefined
) =>
  // if airport transfer is allowed select flight
  // otherwise filter out flights iwth layovers containing `-` in between
  // which indicates airport transfer: `${arrivalAirport}-${departureAirport}`
  allowed || every(layovers, (str) => !includes(str, '-'));

// calculate the delay between crew event & flight time
export const getFlightDelay = (flight: FlightRow | ReadOnlyFlight) => {
  const { crew, port, arrival, departure } = flight;
  if (!crew || !port) {
    return 0;
  }

  if (crew.type === 'onsigner') {
    if (!arrival?.time) return 0;
    const delayMSFromETA = moment(port.eta).diff(moment(arrival.time));
    // Time delay when flight arrival is before port ETA
    if (delayMSFromETA >= 0) return moment.duration(delayMSFromETA).asDays();
    const isAfterVesselETD = moment(arrival.time).isAfter(moment(port.etd));
    // Time delay when flight arrival is after port ETD. Also, returns `0` for arrival in-between ETA & ETD
    return isAfterVesselETD
      ? moment.duration(moment(port.etd).diff(moment(arrival.time))).asDays()
      : 0;
  }

  // Offisgner flight delay calculated from port ETA to flight departure time
  return departure?.time
    ? moment.duration(moment(departure.time).diff(moment(port.eta))).asDays()
    : 0;
};

export const filterFlightFareType = (flight: Flight, fareType: FareType) => {
  const isMarineFlight = flight.type?.type?.toLowerCase() === FareType.marine;
  switch (fareType) {
    case FareType.all:
      return true;
    case FareType.marine:
      return isMarineFlight;
    case FareType.general:
      return !isMarineFlight;
  }
};

const filterFlightForPortDates = (
  flight: Flight,
  portDates: PortDates | undefined
) => {
  const { crew, departure, arrival } = flight;
  const { eta, etd } = portDates || {};

  // port ETA is before flight departure time (for offsigner)
  if (crew.type === CrewType.offsigner) {
    return eta ? moment(eta).isBefore(moment(departure.time)) : true;
  }
  // port ETD is after flight arrival time (for onsigner)
  return etd ? moment(etd).isAfter(moment(arrival.time)) : true;
};

// apply flight filters set by user
const applySortAndFilters = (filters: FlightFilters) => (flight: Flight) => {
  const { segments, source, totalFlightTime } = flight;
  const { airports: layovers = [], time: layoverTime = 0 } =
    getLayovers(flight);

  return (
    // filter for flight provider ( select all if source is `ALL`)
    (filters.source === 'ALL' || source === filters.source) &&
    // filter for flight time
    totalFlightTime <= filters.time &&
    // filter for layover range
    (layoverTime
      ? filters.layover[0] <= layoverTime && layoverTime <= filters.layover[1]
      : true) &&
    // filter for stops count
    layovers.length <= filters.stopsCount &&
    // filter whether is marine flight
    filterFlightFareType(flight, filters.fareType) &&
    (filters.selectedStops.length
      ? // filters for selected layovers/stops
        allLayoversSelected(filters.selectedStops, layovers)
      : // Include all flights if `filters.selectedStops` is empty
        true) &&
    (filters.airlines.length
      ? // filters for airlines
        allAirlinesSelected(filters.airlines, segments)
      : // Include all flights if `filters.airlines` is empty
        true) &&
    // filter for arrival & departure time
    filterFlightSchedules(filters, flight) &&
    // filter for port ETA & ETD date match - avoid flights that are beyond these timestamps
    filterFlightForPortDates(flight, filters.portDates) &&
    // filter for airport transfer
    filterFlightForAirportTransfer(layovers, filters.allowAirportTransfer)
  );
};

const getEmptyFlight = (crew: Crew, filters: FlightFilters) => {
  const crewId = String(crew.id);
  // connection for unavailable flight
  const path =
    crew.type === 'onsigner'
      ? `${crew.homeAirport?.iataCode || 'Unknown'} to ${filters.portAirport}`
      : `${filters.portAirport} to ${crew.homeAirport?.iataCode || 'Unknown'}`;
  return { id: crewId, crew, path, flight: null };
};

// in-depth check if all crew flights are already confirmed
const allFlightsConfirmed = (
  confirmedFlights: ConfirmedFlight[],
  crewList: Crew[]
) => {
  if (confirmedFlights.length !== crewList.length) {
    return false;
  }
  return crewList.every(({ id: crewId, homeAirport, type }) => {
    const { crew: flightCrew } =
      confirmedFlights.find(
        (f) => f.crew.id === crewId && f.crew.type === type
      ) || {};
    return (
      flightCrew && flightCrew?.homeAirport?.iataCode === homeAirport?.iataCode
    );
  });
};

export const getBestFlights = (
  crewList: Crew[],
  filters: FlightFilters
): (ActiveFlight | ReadOnlyFlightRow | EmptyFlight)[] => {
  // don't calculate best flights if all are confirmed
  if (allFlightsConfirmed(filters.confirmed, crewList)) {
    const crewIds = crewList.map(({ id }) => id);
    // maintain crew list order
    return [...filters.confirmed].sort(
      (a, b) => crewIds.indexOf(a.crew.id) - crewIds.indexOf(b.crew.id)
    );
  }

  // common flights for current filters
  const commonFlights = getCommonFlights(filters);

  // prepare empty flights until the flights are fetched
  if (!commonFlights.length) {
    return crewList.map((crew) => getEmptyFlight(crew, filters));
  }

  // get all crew flights for a set of flight filters (port-card specific)
  const filteredFlights = commonFlights
    // applying additional filters
    .filter(applySortAndFilters(filters))
    // sorting based on price, time & CO2
    .sort(flightSortUtils[filters.type]) as ActiveFlight[];

  return crewList.map((crew) => {
    // check if we already have a confirmed flight in table for this crew
    const confirmedFlight = filters.confirmed.find(
      (f) =>
        f.crew.id === crew.id &&
        f.crew.type === crew.type &&
        f.crew.homeAirport?.iataCode === crew.homeAirport?.iataCode
    );
    // return existing confirmed flight
    if (confirmedFlight) {
      return confirmedFlight;
    }
    const crewFlight = filteredFlights.find(
      (f) =>
        f.crew.id === crew.id &&
        f.crew.type === crew.type &&
        f.crew.homeAirport?.iataCode === crew.homeAirport?.iataCode
    );
    return crewFlight
      ? getFlightRows([crewFlight])[0]
      : getEmptyFlight(crew, filters);
  });
};

/********** Filtering & sorting utils end **********/

// find if there's any vendor duplicate port-cards available in the flights step
// this is used to show a confirmation modal when user tries to go back to previous step
export const hasVendorDuplicate = (filters: {
  [locodeKey: string]: FlightFilters;
}) =>
  some(
    values(filters),
    ({ source, duplicate }) => source !== 'ALL' && duplicate
  );

// sanitize flight source in filters before using in QuickFly
// replace flight source with `ALL`, if source coming from Settings not available in flights fetched
export const sanitizeFlightFilters = (
  flights: Flight[] = [],
  filters: ResultFilters | FlightFilters | ReadOnlyFlightFilters
) => ({
  ...filters,
  source: includes(getAllFlightSources(flights), filters.source)
    ? filters.source
    : 'ALL',
});

export const getAllFlightSources = (flights: Flight[]) =>
  flights
    .reduce(
      (acc, { source }) =>
        !source || acc.includes(source) ? acc : [...acc, source],
      ['ALL']
    )
    .reverse();

// updates port departure dates in flights table with arrow icons
export const updateDeparture = (date: string, type: 'add' | 'subtract') =>
  type === 'add'
    ? moment(date).add(1, 'day').toISOString()
    : moment(date).subtract(1, 'day').toISOString();

// set different class names to table rows depending on the loading & flight status
export const getFlightRowClassName =
  ({
    crewType, // available when departure date is updated
    isReportView,
    portProgress,
  }: {
    crewType?: DepartureCrewUpdate;
    isReportView?: boolean;
    portProgress?: number;
  }) =>
  ({ row }: { row: FlightRow | EmptyFlight }) => {
    const { crew, flight, confirmed } = row as FlightRow;

    if (flight) {
      return confirmed || isReportView ? 'confirmed' : 'changing';
    }

    const [from, to] = formatPath((row as EmptyFlight).path);
    // when `creType` is available, set state based on that
    return (portProgress === 1 ||
      (crewType && crew?.type && !crewType[crew.type])) &&
      from !== to
      ? 'missing'
      : '';
  };

export const getFlightCellClassName =
  (readOnlyView: boolean) => (params: GridCellParams<string, FlightRow>) => {
    const { field, row } = params;
    const { flight, confirmed } = row;
    const difference = getFlightDelay(row as FlightRow | ReadOnlyFlight);

    if (field !== 'delay' || !flight || (!confirmed && !readOnlyView)) {
      return '';
    }

    switch (true) {
      case difference > 3 || difference < 0:
        return 'long-delay';
      case difference > 1:
        return 'medium-delay';
      case difference > 0:
        return 'delay';
      default:
        return '';
    }
  };

/* ----- Utils to find dynamic ranges for filter sliders ----- */

const INITIAL_FLIGHT_PARAM_VALUES: FlightParamsRange = {
  min: { stopsCount: 0, layoverTime: 0, flightTime: 0, hotelCostDelay: 0 },
  max: null,
};

// finds out the max & min values of flight-time, layover-time, & stops-count
// equivalent to sorting out `flights` array for each of them & picking head & tail values
export const prepareTimeRange = (flights: Flight[]) =>
  flights.reduce<FlightParamsRange>((acc, flight) => {
    const { stops, totalFlightTime } = flight;
    const { time: layoverTime = 0 } = getLayovers(flight);
    const { min: minValues, max: maxValues } = acc;
    const hotelCostDelay = getFlightDelay(flight) * 24;
    const min = {
      flightTime:
        // don't allow 0(zero) as min value
        minValues.flightTime && minValues.flightTime <= totalFlightTime
          ? minValues.flightTime
          : Math.ceil(totalFlightTime),
      layoverTime: 0, // use 0(zero) as slider min value for flights table filters
      hotelCostDelay: 0,
      stopsCount:
        // allow 0(zero) as min value
        minValues.stopsCount <= stops.length
          ? minValues.stopsCount
          : stops.length,
    };
    const max = {
      flightTime:
        maxValues && maxValues.flightTime >= totalFlightTime
          ? maxValues.flightTime
          : Math.ceil(totalFlightTime),
      layoverTime:
        maxValues && maxValues.layoverTime >= layoverTime
          ? maxValues.layoverTime
          : Math.ceil(layoverTime),
      stopsCount:
        maxValues && maxValues.stopsCount >= stops.length
          ? maxValues.stopsCount
          : stops.length,
      hotelCostDelay:
        maxValues && maxValues.hotelCostDelay >= hotelCostDelay
          ? maxValues.hotelCostDelay
          : Math.ceil(hotelCostDelay),
    };

    return { min, max };
  }, INITIAL_FLIGHT_PARAM_VALUES);

export const prepareCustomFilterRange = (
  flights: Flight[],
  filters?: FlightFilters
) => {
  const selectedStops = uniqBy(
    [
      // keep existing layover values as is
      ...(filters?.selectedStops || []),
      ...flatten(
        flights.map(({ stops, airports }) =>
          airports
            ? Object.keys(airports)
                .filter((iataCode) =>
                  stops.some(({ airportCode }) => airportCode === iataCode)
                )
                .map((iataCode) => ({
                  iataCode,
                  selected: true,
                  airport: `${airports[iataCode].name}, ${airports[iataCode].municipality}`,
                }))
            : []
        )
      ),
    ],
    'iataCode'
  ).sort((a, b) => a.airport.localeCompare(b.airport));

  const airlines = uniqBy(
    [
      // keep existing airline values as is
      ...(filters?.airlines || []),
      ...flatten(
        flights.map(({ segments }) =>
          segments
            .filter(({ operatingCarrierName }) => operatingCarrierName?.trim())
            .map(({ operatingCarrierName }) => ({
              airline: operatingCarrierName.trim(),
              selected: true,
            }))
        )
      ),
    ],
    'airline'
  ).sort((a, b) => a.airline.localeCompare(b.airline));

  return { selectedStops, airlines };
};

// get all ranges for all filter values in flights panel
export const getFilterRangesForPort = (filters: FlightFilters) => {
  const commonFlights = getCommonFlights(filters);
  return commonFlights.length
    ? {
        ...prepareCustomFilterRange(commonFlights, filters),
        flightParamsRange: prepareTimeRange(commonFlights),
      }
    : {
        selectedStops: [],
        airlines: [],
        flightParamsRange: INITIAL_FLIGHT_PARAM_VALUES,
      };
};

/* ----- Utils to find dynamic ranges for filter sliders end ----- */

/* ----- Starts: Utils related to duplicating port cards ----- */

// find the port cards list in flights action
export const getPortCards = (
  ports: Port[],
  route: RoutePort[],
  allFilters: { [locodeKey: string]: FlightFilters }
): PortCardType[] => {
  // find the locode keys that are vendor duplicates
  let filteredLocodeKeys = Object.keys(allFilters).filter((key) =>
    key.includes('--')
  );

  return (mergePortsWithRoute(ports, route) as MergedPort[]).reduce<
    PortCardType[]
  >((acc, port) => {
    const { locode, uniqETA } = port;
    const portLocode = uniqETA ? `${locode}(${uniqETA})` : locode;
    const allFlights = getCommonFlights(allFilters[portLocode]);
    const portUniqFlights = uniqBy(allFlights, 'originalId');
    // split the duplicate keys into two - duplicate of current port & remaining
    const [duplicateKeys, remaining] = partition(
      filteredLocodeKeys,
      (locodeKey) => locodeKey.includes(portLocode)
    );
    const originalPortCard = {
      ...port,
      flightsCount: portUniqFlights.length,
      sources: getAllFlightSources(allFlights),
      uniqETA,
    };
    // reset filtered locode keys for next iteration
    filteredLocodeKeys = [...remaining];

    if (!duplicateKeys.length) {
      return [...acc, originalPortCard];
    }
    // prepare duplicate cards
    const duplicateCards = duplicateKeys.map((text) => {
      const { flightSource } = getLocodeKeyDetails(text);
      return {
        ...port,
        flightSource,
        flightsCount: filter(portUniqFlights, ['source', flightSource]).length,
        uniqETA,
      };
    });
    return [...acc, originalPortCard, ...duplicateCards];
  }, []);
};

// calculates the locode key for filters- uses `PortCardtType` as param
export const getLocodeKeyFromPortData = (
  port: PopupPort | DuplicateReadOnlyPort
) => {
  const { locode: portLocode, flightSource, uniqETA } = port;
  const baseLocode = uniqETA ? `${portLocode}(${uniqETA})` : portLocode;
  return flightSource ? `${baseLocode}--${flightSource}` : baseLocode;
};

// get the list of available vendors to duplicate
export const getVendorsList = (
  currentLocodeKey: string,
  port: PortCardType,
  allFilters: { [locodeKey: string]: FlightFilters },
  allowedVendors: string[]
) => {
  const { locode, sources = [] } = port;
  const { flightSource, portETA } = getLocodeKeyDetails(currentLocodeKey);
  const baseLocode = portETA ? `${locode}(${portETA})` : locode;
  const alreadyDuplicatedVendors = Object.keys(allFilters).reduce<string[]>(
    (acc, filterKey) => {
      if (!filterKey.includes(currentLocodeKey)) return acc;
      return flightSource ? [...acc, flightSource] : acc;
    },
    []
  );
  // sources available to split flights
  const availableSources = sources.filter(
    (item) =>
      item !== 'ALL' && // don't show `ALL` as a duplicate source
      !allFilters[`${baseLocode}--${item}`] && // not already duplicated
      allowedVendors.includes(item) // user is allowed to add duplicate
  );
  const allDuplicated = availableSources.every((source) =>
    alreadyDuplicatedVendors.includes(source)
  );

  return { availableSources, allDuplicated };
};

/* ----- Ends: Utils related to duplicating port cards ----- */
