import { useMemo, useContext, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useMobile } from 'hooks';
import {
  borderGray,
  blue,
  green,
  hoverGray,
  white,
  gray30,
  gray40,
} from 'lib/colors';
import { BREAK_POINT_L, BREAK_POINT_XS } from 'lib/breakpoints';
import { isProduction } from 'lib/environments';
import { RootState } from 'redux/types';
import { CrewChangeStep } from 'utils/types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { CollapseButton } from 'components/shared';
import { CrewCounter, PortCounter, RouteCounter } from './iconCounters';
import { findStepIndex, toggleTableCollapse } from '../helpers';
import MetaData from './MetaData';
import CrewLink from './CrewLink';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StepsHeight = '40px';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoBar = styled(FlexWrapper)`
  flex-grow: 0;
  justify-content: start;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
`;

const InfoBarOverflow = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const InfoDetails = styled.div`
  display: flex;
  column-gap: 0.25rem;
  padding: 0 0.5rem;
  width: max-content;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${StepsHeight};
  align-items: center;
  padding-left: 1rem;
  border-bottom: 1px solid ${borderGray};
  overflow-x: clip;
`;

const StepsWrapper = styled(FlexWrapper)`
  flex-shrink: 0;
  margin: 0 0 0 auto;
`;

const Step = styled(FlexWrapper)<{
  $active?: boolean;
  $disabled: boolean;
  $completed: boolean;
}>`
  height: ${StepsHeight};
  padding: 0 0.5rem;
  border-left: 1px solid ${borderGray};
  font-weight: bold;
  position: relative;
  transition: all 150ms ease;

  ${({ $disabled }) =>
    $disabled &&
    `
    ${Name} {
      opacity: 0.4;
    };
    ${CountWrapper} {
      opacity: 0.4;
    }
  `};
  background-color: ${({ $active, $completed }) =>
    $active ? gray40 : ($completed && gray30) || 'none'};
  display: flex;
  column-gap: 0.5rem;
`;

const CountWrapper = styled(FlexWrapper)<{
  $active?: boolean;
  $completed?: boolean;
}>`
  border: 1px solid ${hoverGray};
  border-radius: 50%;
  flex-shrink: 0;
  height: 1.6rem;
  width: 1.6rem;
  ${({ $active, $completed }) =>
    $active || $completed
      ? `
    border: none;
    color: ${white};
    background-color: ${($active && blue) || ($completed && green) || '#fff'};
  `
      : `background-color: ${hoverGray}`};
  @media screen and (max-width: ${BREAK_POINT_L}) {
    height: 25px;
    width: 25px;
  }
`;

const Count = styled.div`
  font-size: 0.9rem;
  @media screen and (max-width: ${BREAK_POINT_L}) {
    font-size: 0.8rem;
  }
`;

const Name = styled.span`
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const ScrollButton = styled.span<{ $direction: 'left' | 'right' }>`
  cursor: pointer;
  position: relative;
  padding: ${({ $direction }) =>
    $direction === 'left' ? '0.25rem' : '0.25rem 0.5rem 0.25rem 0.25rem'};
  align-self: stretch;
  display: flex;
  align-items: center;

  ${({ $direction }) =>
    $direction === 'left'
      ? `&::before {
      content: '';
      position: absolute;
      left: 100%;
      width: 20px;
      height: 100%;
      z-index: 1;
      background: linear-gradient(
        to left,
        hsla(0, 0%, 100%, 0) 1%,
        hsla(0, 0%, 100%, 0.8) 50%,
        hsla(0, 0%, 100%, 1) 90%
      );
    }`
      : `&::before {
      content: '';
      position: absolute;
      right: 100%;
      width: 20px;
      height: 100%;
      z-index: 1;
      background: linear-gradient(
        to right,
        hsla(0, 0%, 100%, 0) 1%,
        hsla(0, 0%, 100%, 0.8) 50%,
        hsla(0, 0%, 100%, 1) 90%
      );
    }`}
`;

type StepType = {
  step: number;
  name: CrewChangeStep;
  disabled: boolean;
};

function Steps(): JSX.Element {
  const overflowRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const stepsWrapperRef = useRef<HTMLDivElement | null>(null);
  const infoDetailsRef = useRef<HTMLDivElement | null>(null);

  const activity = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.active
  );
  const userInfo = useSelector(({ settings }: RootState) => settings.userInfo);
  const { planningData, tableState, updateTableState } =
    useContext(CCPanelContext);
  const isMobile = useMobile(BREAK_POINT_XS);

  const { collapse, step: activeStep, view } = tableState;
  const isReportView = activity === 'readOnly';
  // condition to show CrewLink button in compare view of flights step
  const showCrewLink =
    userInfo?.access?.['CrewLink'] &&
    activeStep === 'flights' &&
    view === 'compare' &&
    !isProduction;

  const data = useMemo((): StepType[] => {
    const { crew = [], ports = [] } = planningData;
    return [
      { step: 1, name: 'crew', disabled: false },
      { step: 2, name: 'route', disabled: !crew.length } as StepType,
      { step: 3, name: 'ports', disabled: !crew.length },
      { step: 4, name: 'flights', disabled: !crew.length || !ports.length },
    ];
  }, [planningData]);

  const handleUpdate = (type: string, payload: any) => {
    updateTableState({ type, payload });
  };

  const handleScrollRight = () => {
    if (!overflowRef.current) return;
    overflowRef.current.scrollTo({
      left: overflowRef.current.scrollLeft + 40,
      behavior: 'smooth',
    });
  };

  const handleScrollLeft = () => {
    if (!overflowRef.current) return;
    overflowRef.current.scrollTo({
      left: overflowRef.current.scrollLeft - 40,
      behavior: 'smooth',
    });
  };

  const [showScrollButtons, setShowScrollButtons] = useState(false);

  useEffect(() => {
    if (
      !wrapperRef.current ||
      !stepsWrapperRef.current ||
      !overflowRef.current
    ) {
      return;
    }
    const resizeObserver = new ResizeObserver((e: ResizeObserverEntry[]) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(e) || !e.length) {
          return;
        }
        const mainWidth = wrapperRef.current?.clientWidth;
        const stepsWidth = stepsWrapperRef.current?.clientWidth;
        const contentWidth = infoDetailsRef.current?.clientWidth;
        const collapseButtonWidth = 26 + 5 * 2 + 10;
        const leftPadding = 16;
        if (mainWidth && stepsWidth && contentWidth) {
          const availableSpace =
            mainWidth - stepsWidth - collapseButtonWidth - leftPadding;
          if (contentWidth > availableSpace) setShowScrollButtons(true);
          else setShowScrollButtons(false);
        }
      });
    });
    resizeObserver.observe(wrapperRef.current);
    return () => resizeObserver.disconnect();
  }, [planningData]); // eslint-disable-line

  const renderCounterDetails = () => {
    switch (activeStep) {
      case 'crew':
        return <CrewCounter />;
      case 'ports':
        return <PortCounter />;
      case 'route':
        return <RouteCounter />;
      default:
        return null;
    }
  };

  return (
    <Wrapper ref={wrapperRef}>
      <InfoBar>
        <CollapseButton
          collapse={collapse}
          onClick={() => {
            toggleTableCollapse(!collapse);
            handleUpdate('collapse', !collapse);
          }}
        />
        {!isMobile && (
          <>
            {showScrollButtons && (
              <ScrollButton $direction="left" onClick={handleScrollLeft}>
                <ChevronLeftIcon />
              </ScrollButton>
            )}
            <InfoBarOverflow ref={overflowRef}>
              <InfoDetails ref={infoDetailsRef}>
                {renderCounterDetails()}
                {/* render metadata for crew change beside icons */}
                <MetaData />
              </InfoDetails>
            </InfoBarOverflow>

            {showScrollButtons && (
              <ScrollButton $direction="right" onClick={handleScrollRight}>
                <ChevronRightIcon />
              </ScrollButton>
            )}
          </>
        )}
      </InfoBar>

      <StepsWrapper ref={stepsWrapperRef}>
        {/* render button in compare view of flights step in order send flight data to CrewLink */}
        {showCrewLink && <CrewLink />}

        {data.map(({ step, name, disabled }) => {
          const isActive = activeStep === name;
          return (
            <Step
              key={step}
              $active={isActive}
              $completed={findStepIndex(activeStep) > step - 1}
              $disabled={!isReportView ? disabled : false}
            >
              <CountWrapper
                $active={isActive}
                $completed={findStepIndex(activeStep) > step - 1}
              >
                <Count>{step}</Count>
              </CountWrapper>
              <Name>{name}</Name>
            </Step>
          );
        })}
      </StepsWrapper>
    </Wrapper>
  );
}

export default Steps;
