import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCrewChangePanel, selectSettings } from 'redux/selectors';
import { RootState } from 'redux/types';
import {
  getTravelCost,
  getLocodeKeyFromPortData,
  getUnneededFlightsCount,
  includeHotelCost,
  totalFlightCost as getTotalFlightCost,
} from 'components/CrewChangePanel/helpers';
import {
  ActiveFlight,
  CrewChangeCost,
  DuplicateReadOnlyPort,
  PopupPort,
  ReadOnlyFlight,
} from 'components/CrewChangePanel/types';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { AgencyCostModule } from 'components/CrewChangePanel/modules';

type ActiveFlightsForCost = (ReadOnlyFlight | ActiveFlight)[];

// calculats various crew change costs based on cost parameters from settings
function useCrewChangeCosts(port: PopupPort | DuplicateReadOnlyPort) {
  const { locode, costs: agencyCosts } = port;
  const { active: activity, readOnlyPlanningData: reportData } = useSelector(
    selectCrewChangePanel
  );
  const hotelResults = useSelector(
    ({ crewChangeResources }: RootState) => crewChangeResources.hotelResults
  );
  const {
    crewChange: { costParams },
  } = useSelector(selectSettings);
  const {
    filters: allFilters,
    portParams,
    planningData: { crew: planningCrew },
  } = useContext(CCPanelContext);

  const locodeKey = getLocodeKeyFromPortData(port);
  const filters = allFilters?.[locodeKey] || {};
  const isReportView = activity === 'readOnly' && Boolean(reportData);
  const {
    crew: readOnlyCrew = [],
    flights: readOnlyFlights = {},
    portFilters: readOnlyPortParams,
  } = reportData?.crewChangePlan || {};
  const { costOptions } = costParams;
  const { hotels = [] } = hotelResults?.[locode] || {};
  const crewList = isReportView ? readOnlyCrew : planningCrew;

  // find if a cost option/type should be included in report
  const showCost = useCallback(
    (type: CrewChangeCost) => costOptions.includes(type),
    [costOptions]
  );

  // flghts to use for cost calculation
  const activeFlights = useMemo(
    () =>
      (isReportView
        ? readOnlyFlights[locodeKey] || []
        : filters.confirmed || []) as ActiveFlightsForCost,
    [locodeKey, readOnlyFlights, isReportView, filters.confirmed]
  );
  // currentcy in flights data
  const { currency: flightCurrency = '' } = activeFlights[0]?.price || {};
  // crew list included in the plan, based on flights - needed & uneeded both
  const { crewListWithFlight, totalCrewCount } = useMemo(() => {
    const crewListWithFlight = activeFlights.map(({ crew }) => crew);
    const totalCrewCount =
      activeFlights.length + getUnneededFlightsCount(crewList, port);
    return { crewListWithFlight, totalCrewCount };
  }, [activeFlights, crewList, port]);

  // crewlist depending on the step of crew-change plan and/or activity
  const activeCrewList = crewListWithFlight.length
    ? crewListWithFlight
    : crewList;
  // find port agency cost
  const { preferredAgent, agencyCurrency, totalAgencyCost } = useMemo(() => {
    // get the port agency from ports table or from report data
    const portAgent = isReportView
      ? readOnlyPortParams?.agency || 'ALL'
      : portParams.agency;
    const module = new AgencyCostModule(activeCrewList, agencyCosts);
    const {
      agent,
      agencyCost: totalCost,
      costDetails,
    } = module.getPreferredCostSummary(portAgent);
    const { currency: agencyCurrency = '' } = costDetails || {};
    return {
      agencyCurrency,
      preferredAgent: agent,
      totalAgencyCost:
        totalCost && showCost('Agency') ? parseInt(totalCost) : 0,
    };
  }, [
    portParams.agency,
    readOnlyPortParams?.agency,
    isReportView,
    activeCrewList,
    agencyCosts,
    showCost,
  ]);

  /* Cost breakdown based on settings */
  const totalFlightCost = showCost('Flight')
    ? Number(getTotalFlightCost(activeFlights))
    : 0;
  const getExtraCosts = getTravelCost(costOptions);
  const { totalWageCost, totalHotelCost } = useMemo(
    () =>
      activeFlights.reduce(
        (
          acc: { totalWageCost: number; totalHotelCost: number },
          flight: ReadOnlyFlight | ActiveFlight
        ) => {
          const crew = crewList.find(({ id }) => id === flight.crew.id);
          // in case of shared report data, `hotelCost` is already included in flight
          const crewFlight =
            (isReportView && flight) ||
            includeHotelCost(flight as ActiveFlight, hotels);
          const { total, difference = 0 } =
            getExtraCosts(crewFlight, crew) || {};
          const hotelCostDelay = isReportView
            ? (flight as ReadOnlyFlight).filters.hotelCostDelay
            : filters.hotelCostDelay;
          const hasHotelCost = hotelCostDelay <= difference * 24;
          return {
            totalWageCost:
              acc.totalWageCost + ((difference > 0 && total?.wage) || 0),
            totalHotelCost:
              acc.totalHotelCost + ((hasHotelCost && total?.hotel) || 0),
          };
        },
        {
          totalWageCost: 0,
          totalHotelCost: 0,
        }
      ),
    [
      activeFlights,
      crewList,
      hotels,
      isReportView,
      filters.hotelCostDelay,
      getExtraCosts,
    ]
  );

  const totalCostInNum =
    totalAgencyCost + totalFlightCost + totalWageCost + totalHotelCost;
  const totalCost = totalCostInNum ? `${totalCostInNum} ${flightCurrency}` : '';

  return {
    totalCrewCount,
    totalAgencyCost,
    totalFlightCost,
    totalWageCost,
    totalHotelCost,
    totalCost,
    preferredAgent,
    currency: flightCurrency || agencyCurrency,
  };
}

export default useCrewChangeCosts;
