import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import { NavigateFunction } from 'react-router-dom';
import moment from 'moment';

import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import {
  addNewOffsignerToEvent,
  deleteEmptyEMPEvent,
  fetchMatrixEventDetails,
  suggestAndSaveOnsigners,
  updateMatrixEventStatus,
} from 'api/crew-matrix';
import { formatDate } from 'utils/format-date';
import { removeScheduleEvent } from 'redux/actions';
import { AppDispatch, ScheduleMatrixEvents } from 'redux/types';
import {
  TRACK_ACCEPT_EVENT,
  TRACK_DELETE_CMP_EVENT,
  TRACK_UNACCEPT_EVENT,
} from 'utils/analytics/constants';

import { getEventOnsigners, sortCrewByRank } from './common';
import { BASE_MATRIX_ROUTE, EVENTS_MERGE_COMPLIANCE } from '../constants';
import {
  CrewChangeEventDetailed,
  OnBoardCrew,
  OffsignerWithOptions,
  OnsignerDetails,
  VesselWithOnBoardCrew,
  SuggestedOnsignerRequest,
  ComplianceCheckCrewInfo,
  TrimmedEvent,
  ComplianceType,
  ComputeOilMajorComplianceRequest,
  CalculateCompliances,
  FailedRequirement,
  FailedReqDetails,
  RequirementType,
  Compliance,
  CrewSortOption,
  CrewChangeEventSummary,
} from '../types';

// format selected onsigner to onboard crew
const formatOnsignerToOnBoardCrew =
  (eventDate: string) => (selectedOnsigner: OnsignerDetails) => {
    const { externalCrewId, nationalityIso3, name, rank, meta } =
      selectedOnsigner;
    return {
      externalCrewId,
      nationalityIso3,
      name,
      signOnRank: rank,
      currentRank: rank,
      startDate: eventDate,
      endDate: null,
      meta,
    };
  };

// list of onboard crew after crew-change
const getAfterChangeOnBoardCrew = (
  vessel: VesselWithOnBoardCrew,
  event: CrewChangeEventDetailed
) => {
  const { offsigners } = event;
  const { onBoardCrew } = vessel;
  const selectedOnsigners = getEventOnsigners(event);
  return uniqBy(
    [
      ...onBoardCrew.filter(
        (crew) =>
          !offsigners.some(
            ({ externalCrewId: crewId }) => crewId === crew.externalCrewId
          )
      ),
      ...selectedOnsigners.map(formatOnsignerToOnBoardCrew(event.eventDate)),
    ],
    'externalCrewId'
  );
};

// format an offsigner with `OffsignerWithOptions` type to `OnBoardCrew` type for API request
export const convertOffsignerForReq = (
  offsigner: OffsignerWithOptions & { type?: 'CURRENT' | 'PLANNED' }
): Partial<OnBoardCrew> => {
  const onBoardCrewFields = [
    'externalCrewId',
    'nationalityIso3',
    'name',
    'currentRank',
    'signOnRank',
    'startDate',
    'endDate',
    'meta',
    'signOnEventUuid',
    'type',
  ];
  return pick(offsigner, onBoardCrewFields);
};

export const generateOffsignerWithOptions =
  (
    crew: OnBoardCrew,
    event: CrewChangeEventDetailed,
    vessel: VesselWithOnBoardCrew
  ) =>
  async (
    reason: string
  ): Promise<{
    eventUpdatedAt: string | null | undefined;
    droppedOffsigner: OffsignerWithOptions;
  } | null> => {
    const currentDate = new Date().toISOString();
    const {
      success: addSuccess,
      message: addOffsignerMessage,
      eventUpdatedAt: initialUpdatedAt,
      result: addedOffsigner,
    } = await addNewOffsignerToEvent(event, { ...crew, reason });

    if (!addSuccess || !addedOffsigner || !initialUpdatedAt) {
      showToaster({
        message: addOffsignerMessage,
        placement: 'left',
        type: 'error',
      });
      return null;
    }

    const formattedRequest: SuggestedOnsignerRequest = {
      eventDate: event.eventDate,
      offsigner: crew,
      vessel: {
        ...omit(vessel, ['numCrewOnBoard', 'onBoardCrew']),
        // replace the original vessel `onBoardCrew` field with the after crew-change `onBoardCrew` list
        onBoardCrew: getAfterChangeOnBoardCrew(vessel, event).map(
          (crew) => omit(crew, 'meta') // remove `meta` data from request
        ),
      },
    };

    const {
      success: suggestionSuccess,
      message: suggestionMessage,
      eventUpdatedAt: finalUpdatedAt,
      result: onsignerSuggestions,
    } = await suggestAndSaveOnsigners(
      // insert event updated time for the next api request (to be passed in the header)
      { ...event, updatedAt: initialUpdatedAt },
      formattedRequest
    );

    const updatedOffsigner = {
      ...crew,
      createdAt: currentDate,
      updatedAt: currentDate,
      crewChangeEventId: event.id,
      isAlgoGenerated: false,
      reason,
      selectedOnsigner: null,
      alternativeOnsigners: [],
    };

    if (!suggestionSuccess || !onsignerSuggestions) {
      showToaster({
        message: suggestionMessage,
        placement: 'left',
        type: 'error',
      });
      return {
        eventUpdatedAt: initialUpdatedAt,
        droppedOffsigner: updatedOffsigner,
      };
    }

    showToaster({
      message: suggestionMessage,
      placement: 'left',
      testId: 'e2e_cmp-add-offsigner-toaster',
    });

    // format the offsigner dragged from sidebar & dropped to on-offpaies section
    return {
      eventUpdatedAt: finalUpdatedAt,
      droppedOffsigner: { ...updatedOffsigner, ...onsignerSuggestions },
    };
  };

export const getAvailableOffsigners = (
  offsigners: OffsignerWithOptions[],
  vesselOnBoardCrew: OnBoardCrew[] | undefined = []
) =>
  uniqBy(
    vesselOnBoardCrew.filter(
      ({ externalCrewId: crewId }) =>
        !offsigners.find((offsigner) => offsigner.externalCrewId === crewId)
    ),
    'externalCrewId'
  );

export const sortOnBoardCrew = (
  crewList: OnBoardCrew[],
  type: CrewSortOption | null
) => {
  const rankSortedList = sortCrewByRank(crewList);

  switch (type) {
    case 'RANK':
    case null: {
      return rankSortedList;
    }
    case 'NAME': {
      return [...crewList].sort((a, b) =>
        (a.name || '').localeCompare(b.name || '')
      );
    }
    case 'SIGN-ON DATE': {
      return [...crewList].sort((a, b) =>
        a.startDate.localeCompare(b.startDate)
      );
    }
    default:
      return rankSortedList;
  }
};

// find if the event has offsigner with onsigner that's not confirmed, i.e - `selectedByHuman` as false
export const hasUnconfirmedOnsigner = (event: CrewChangeEventDetailed) =>
  event.offsigners.some(
    ({ selectedOnsigner }) =>
      selectedOnsigner && !selectedOnsigner.selectedByHuman
  );

// find if the event has offsigner with no selected onsigner but available alternative onsigner(s)
export const hasUnselectedOnsigner = (event: CrewChangeEventDetailed) =>
  event.offsigners.some(
    ({ selectedOnsigner, alternativeOnsigners }) =>
      !selectedOnsigner && alternativeOnsigners.length
  );

/* ---------------------------------------- */
/* ----- Compliance calculation utils ----- */
/* ---------------------------------------- */

const getFailedReqDetails =
  (failedRequirements: FailedRequirement[]) =>
  (crewList: (OnBoardCrew | OnsignerDetails)[]) =>
    failedRequirements.reduce<FailedReqDetails[]>(
      (acc, item) => [
        ...acc,
        {
          message: item.reqHumanFriendlyDesc,
          actualVal: item.actualVal,
          reqVal: item.reqVal,
          reqType: item.reqType as RequirementType,
          crew: crewList
            .filter(({ externalCrewId: crewId }) =>
              item.crewIds.includes(crewId)
            )
            .map((crew) => ({
              crewId: crew.externalCrewId,
              name: crew.name || '',
              rank:
                (crew as OnsignerDetails).rank ||
                (crew as OnBoardCrew).signOnRank,
            })),
        },
      ],
      []
    );

// formats the compliance info for UI presentation
const formatCompliances = (
  compliances: Compliance[],
  crewList: (OnBoardCrew | OnsignerDetails)[]
) =>
  sortBy(
    compliances.map((item) => {
      const { pass: compliant, oilMajorName, failedRequirements } = item;
      const formatDetails = getFailedReqDetails(failedRequirements);
      return {
        compliant,
        oilMajorName,
        failedDetails: formatDetails(crewList),
      };
    }),
    ['compliant', 'oilMajorName']
  );

// caclculates before & after compliance for all oil major comploiances
export const calculateCrewChangeCompliances: CalculateCompliances = ({
  vessel,
  event,
  compliances,
}) => {
  const { onBoardCrew: beforeComplianceCrew } = vessel;
  const afterComplianceCrew = getAfterChangeOnBoardCrew(vessel, event);
  return {
    after: formatCompliances(compliances.after || [], afterComplianceCrew),
    before: formatCompliances(compliances.before || [], beforeComplianceCrew),
  };
};

// util to find the request for API util that calculates compliance
export const getComplianceRequest = (
  event: TrimmedEvent,
  vessel: VesselWithOnBoardCrew,
  type: ComplianceType
): ComputeOilMajorComplianceRequest => {
  const {
    vesselName,
    vesselType,
    vesselCrewMatrixType: matrixType,
    onBoardCrew = [],
  } = vessel;
  const { eventDate, offsigners } = event;
  const commonFields = {
    vesselName,
    vesselType: vesselType || '',
    vesselCrewMatrixType: matrixType || '',
  };

  if (type === 'before') {
    const crewInfoList: ComplianceCheckCrewInfo[] = onBoardCrew.map(
      ({ externalCrewId: crewId, signOnRank, currentRank, startDate }) => ({
        crewId,
        signOnRank,
        currentRank,
        startDate,
        endDate: eventDate,
      })
    );
    return { ...commonFields, crew: crewInfoList };
  }

  const selectedOnsigners = getEventOnsigners(event);
  const availableOffsigners = getAvailableOffsigners(offsigners, onBoardCrew);
  // format offsigners & onsigners for API request
  const crewInfoList: ComplianceCheckCrewInfo[] = [
    ...availableOffsigners.map(
      ({ externalCrewId: crewId, signOnRank, currentRank, startDate }) => ({
        crewId,
        signOnRank,
        currentRank,
        startDate,
        endDate: eventDate,
      })
    ),
    ...selectedOnsigners.map(({ externalCrewId: crewId, rank }) => ({
      crewId,
      signOnRank: rank,
      currentRank: rank,
      startDate: eventDate,
      endDate: null,
    })),
  ];

  return { ...commonFields, crew: crewInfoList };
};

// common util to handle accepting of an event
export const acceptCMPEvent = async (
  currentEvent: CrewChangeEventDetailed | null
): Promise<CrewChangeEventDetailed | null> => {
  if (!currentEvent) return null;

  const { success, message, eventUpdatedAt, result } =
    await updateMatrixEventStatus(currentEvent);

  if (!success || !result || !eventUpdatedAt) {
    showToaster({ message, placement: 'left', type: 'error' });
    return null;
  }

  const updatedEvent: CrewChangeEventDetailed = {
    ...currentEvent,
    ...result,
    updatedAt: eventUpdatedAt || currentEvent.updatedAt,
  };
  trackUserAction(
    currentEvent.isAccepted ? TRACK_UNACCEPT_EVENT : TRACK_ACCEPT_EVENT,
    'click',
    {
      event: updatedEvent,
    }
  );
  showToaster({
    message,
    placement: 'left',
    testId: 'e2e_cmp-accept-event-toaster',
  });

  return updatedEvent;
};

// delete current CMP event - user can delete only self-created empty events
export const removeCurrentEvent =
  (dispatch: AppDispatch, navigate: NavigateFunction) =>
  async (currentEvent: CrewChangeEventDetailed | null, currentYear: number) => {
    if (!currentEvent) return;

    const { id: eventId, offsigners, creator } = currentEvent;
    const deletedBy =
      `${creator?.firstName || ''} ${creator?.lastName || ''}`.trim() ||
      creator?.email;

    // show error message if non-empty event is being deleted
    if (offsigners.length) {
      showToaster({
        message: 'Event has offsigners. Cannot delete non-empty event.',
        placement: 'left',
        type: 'error',
      });
      return;
    }

    const { success, message } = await deleteEmptyEMPEvent(eventId);
    if (!success) {
      showToaster({ message, placement: 'left', type: 'error' });
      return;
    }

    showToaster({
      message,
      placement: 'left',
      testId: 'e2e_cmp-remove-event-toaster',
    });
    // update schedule events in the redux store
    dispatch(removeScheduleEvent({ eventId, currentYear }));
    // trigger amplitude event for deleting event
    trackUserAction(TRACK_DELETE_CMP_EVENT, 'click', {
      event: currentEvent,
      ...(deletedBy ? { deletedBy } : {}),
    });
    // navigate to schedule page
    navigate(`/${BASE_MATRIX_ROUTE}`);
  };

// fetch & prepare detailed events on selected date using summary events
export const prepareEventsOnSelectedDate = async (
  events: CrewChangeEventSummary[]
) => {
  const eventIds = events.map(({ id }) => id);
  const promises = eventIds.map((id) => fetchMatrixEventDetails(id));
  const results = await Promise.all(promises);
  return results
    .filter(({ success, result }) => success && result)
    .map(({ result }) => result) as CrewChangeEventDetailed[];
};

// util to get summary events of the vessel
// get the events on provided date, if available
export const getSummaryEventsOnSelectedDate =
  (
    vesselId: number,
    storedEvents: ScheduleMatrixEvents | null // stored events in redux store
  ) =>
  (eventDate?: string) => {
    const vesselEvents = filter(
      uniqBy(flatten(values(storedEvents || {})), 'id'),
      ['vessel.id', vesselId]
    );
    // return events on specific date, if available
    // otherwise return all vessel events
    return eventDate
      ? filter(
          vesselEvents,
          (event) =>
            // check equality after formatting date for safety
            formatDate(event.eventDate, 'DD/MM/YYYY') ===
            formatDate(eventDate, 'DD/MM/YYYY')
        )
      : vesselEvents;
  };

// util to determine if two events can be merged into one, checking against the following conditions
/*
1. BP Requirement
  (Min 2 weeks between joining dates)
  • Master and C/O
  • C/E and 1/E
  • 2/O and 3/O
  • 2/E and 3/E

2. TOTAL Requirement
  (Ranked changing together is seen as a negative observation)
  • Master and C/O
  • C/E and 1/E

3. ADNOC Requirement
  (Min. 14 days between joining dates)
  • Master and C/O
  • Master and C/E
  • C/E and 1/E(First Engineer)
*/
export const getMergeEventsCompliance = (
  offsigners: (OffsignerWithOptions | OnBoardCrew)[],
  targetEvent: CrewChangeEventDetailed
) => {
  const { eventDate, offsigners: eventOffsigners = [] } = targetEvent;
  const conflictList = eventOffsigners.reduce<Record<string, any>[]>(
    (acc, crew) => {
      const { currentRank, startDate } = crew;
      // check if the current crew rank & start date has any conflict
      // with offsigners inside eventOffsigners, based on eventsMergeConflicts
      const mergeConflicts = offsigners.reduce((acc, offsigner) => {
        const rankCombo =
          `${currentRank} & ${offsigner.currentRank}`.toLowerCase();
        const reverseRankCombo =
          `${offsigner.currentRank} & ${currentRank}`.toLowerCase();
        const joiningConflicts = EVENTS_MERGE_COMPLIANCE.filter(
          ({ name, ranks }) => {
            const formattedRanks = ranks.map((rank) => rank.toLowerCase());
            const isRankComboMatch =
              formattedRanks.includes(rankCombo) ||
              formattedRanks.includes(reverseRankCombo);
            // check for conflicting ranks match
            if (!isRankComboMatch) return false;

            return (
              // when confliucting ranks are changed together
              name === 'TOTAL Requirement' ||
              // check if the 2 offsigners have joining date conflict
              Math.abs(
                moment(startDate).diff(moment(offsigner.startDate), 'days')
              ) > 14
            );
          }
        );
        return rankCombo && joiningConflicts.length
          ? { ...acc, [rankCombo]: joiningConflicts }
          : acc;
      }, {});

      return [...acc, mergeConflicts];
    },
    []
  );
  const conflicts = conflictList
    // filter out empty objects
    .filter((item) => keys(item).length)
    .map((item) => {
      const rankCombo = keys(item)[0].toUpperCase();
      return {
        rankCombo,
        reasons: flatten(values(item)).map(
          ({ name, reason }) => `${name}: ${rankCombo} ${reason}`
        ),
      };
    });

  return { eventDate, conflicts };
};
