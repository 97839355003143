import delay from 'lodash/delay';
import { v4 as uuidv4 } from 'uuid';
import { getAuthToken } from '@greywing-maritime/frontend-library/dist/utils/auth';
import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import type { ListCrewShortResp } from '@greywing-maritime/frontend-library/dist/types/crew';
import type { FlightResult } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';
import type { CrewChangeEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import type {
  RestrictionsData,
  RestrictionsDataWithUserFeedbackResp,
} from '@greywing-maritime/frontend-library/dist/types/restrictionsDataQuery';
import type { RestrictionsDataFeedbackReqBody } from '@greywing-maritime/frontend-library/dist/types/restrictionsDataFeedbackRequest';
import type { ListPortNotesResp as PortNotesResponse } from '@greywing-maritime/frontend-library/dist/types/portNotes';
import type {
  CreateCrewChangePlanReq,
  CrewChangePlanWithDetailsResp,
  SavedCrewChangePlanResponse,
} from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';
import type { AirportCommon } from '@greywing-maritime/frontend-library/dist/types/airports';
import type { RiskArea } from '@greywing-maritime/frontend-library/dist/types/riskAreas';
import type {
  TrackFlightReqBody,
  TrackFlightsSettings,
} from '@greywing-maritime/frontend-library/dist/types/trackFlightsTypes';
import type {
  FlightBookingTravelerInfoUpsertDtoCommon,
  ListFlightBookingTxResp,
  GetFlightBookingTxResp,
  ListFlightBookingTxQueryParams,
} from '@greywing-maritime/frontend-library/dist/types/flightBooking';
import type { RiskAreaTrackingReqCommon } from '@greywing-maritime/frontend-library/dist/types/riskAreaTrackingRequests';
import type { GetRiskAreaTrackingReportResp } from '@greywing-maritime/frontend-library/dist/types/riskAreaTrackingReport';
import type { FindHotelsResp } from '@greywing-maritime/frontend-library/dist/types/hotels';
import type {
  BatchGetPortCallsReqBody,
  GetPortCallsV2Resp,
} from '@greywing-maritime/frontend-library/dist/types/portCalls';
import type {
  Alert,
  AlertResponse,
  CreateAlertRequest,
  UpdateAlertRequest,
} from '@greywing-maritime/frontend-library/dist/types/alerts';
import type { GetCompanyUsersJsonResp } from '@greywing-maritime/frontend-library/dist/types/companyUsers';
import type {
  AddAgentReq,
  AddAgentResp,
  AgentSearchReq,
  AgentSearchResp,
  SendAgentEmailReq,
  SendAgentEmailResp,
} from '@greywing-maritime/frontend-library/dist/types/emailReqResp';
import type { PreferredPort } from '@greywing-maritime/frontend-library/dist/types/preferredPorts';
import { SESSION_TOKEN_EXPIRY } from '@greywing-maritime/frontend-library/dist/config/tokens';
import * as Sentry from '@sentry/react';
import { create, mask } from 'superstruct';
import type {
  ListPortAgentsQueryParams,
  ListPortAgentsResp,
} from '@greywing-maritime/frontend-library/dist/types/portAgents';
import type { GeofenceCommon } from '@greywing-maritime/frontend-library/dist/types/geofences';

import { PROXPORTS_PAGE_SIZE } from 'lib/constants';
import {
  UserInfo,
  NotificationUpdate,
  ProximityPort,
  ReminderResponse,
  Reminder,
  VesselUpdateResponse,
  VesselNameResponse,
  UserSelectedPortsReq,
  PortRestrictionSearchResponse,
  SearchedPortResponse,
  RestrictionsReqParams,
  ReminderStatusType,
  OnboardCompletion,
  UserSignUpValues,
  UserVerifySignUp,
  UserForgotPasswordValues,
  UserResetPasswordRequest,
  MagicLinkResetPasswordValues,
  AuthPacket,
  HotelsResponse,
  BatchPortCallsResponse,
  PortCallResponse,
  VesselTag,
  CopilotCommandObject,
  SeaGPTRestrictionsReqParams,
} from 'utils/types';
import { DEFAULT_FLIGHT_RESULT } from 'redux/helpers/flotillaSearch';
import {
  NotificationSubscriptionResp,
  UpdateNotificationSubscriptionReq,
  UpdateUserInfoRequest,
} from 'utils/types/notification-event-types';
import { PortRequest, PortResponse } from 'utils/types/crew-change-types';
import { imoChecksum } from 'utils/imo';
import { AppSettings } from 'redux/types';
import { StepsCompletedSchema, StepsContentArraySchema } from 'utils/onboard';
import { OnboardContent } from 'components/MapQuickMenu/components/variables';
import { formatPortCallUserTimeV2 } from 'components/SidePanel/VesselCourse/helpers';
import {
  VesselCCBudgetResponse,
  CreateCrewLinkBookingRequestReqCommon,
  FetchCrewFlightsApi,
} from 'components/CrewChangePanel/types';

import {
  AssistedCrewChangeAgentConvoResp,
  AssistedCrewChangeConvoSummaryResp,
  AssistedCrewChangeSummaryResp,
  ListAssistedCrewChangeConvosQuery,
  PageInfo,
  SeaGPTChatMessage,
} from 'components/SeaGPT/type';
import {
  getAuthTokenValue,
  handleAbort,
  handleErrorToSentry,
  handleAPIError,
  headers,
  formatVesselsResponse,
} from './helpers';

const EVENT_STREAM_TIMEOUT = 5 * 60 * 1000; // 5 minutes
const CONTENT_API_URL =
  process.env.REACT_APP_CONTENT_URL || 'https://write.grey-wing.com';

export const getVesselHasUpdates: (
  date: Date
) => Promise<VesselUpdateResponse> = async (date) => {
  const endpoint = `${getAPIUrl()}/api/flotillav2/vessels/hasUpdatedPortCalls`;

  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false };
  }

  try {
    const res = await fetch(
      endpoint + `?${new URLSearchParams({ updatesSince: date.toString() })}`,
      {
        method: 'GET',
        headers: headers(authToken),
      }
    );

    const data = await res.json();
    return data;
  } catch (error) {
    console.error('Error fetching updates - ', (error as any).message);
    handleErrorToSentry(error, endpoint);
    return { success: false };
  }
};

export const getFlotillaVessels = async (): Promise<{
  success: boolean;
  message: string;
  vessels?: Map<number, Vessel>;
}> => {
  const vesselsEndpoint = `${getAPIUrl()}/api/v2/vessels`;

  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Please login.' };
  }

  try {
    const response = await fetch(vesselsEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'ok',
      vessels: formatVesselsResponse(result),
    };
  } catch (err) {
    console.error('Error fetching vessels - ', err);
    handleErrorToSentry(err, vesselsEndpoint);
    return {
      success: false,
      message: 'Failed to fetch vessels.',
    };
  }
};

export const getVesselWithNameOrIMO = async (
  query: string,
  page: number = 1
) => {
  const endpoint = `${getAPIUrl()}/api/flotillav2/ais-engine/vessels?term=${query}&page=${page}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { message: 'Unauthorized' };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const vessels: VesselNameResponse = await response.json();

    // TODO: remove this when the fix comes through
    const filteredVessels = {
      ...vessels,
      results: vessels.results.filter(({ imo }) => imoChecksum(String(imo))),
    };
    return {
      message: 'OK',
      vessels: filteredVessels,
    };
  } catch (error) {
    console.error('Error fetching vessel names - ', error);
    handleErrorToSentry(error, endpoint);
    return {
      message: (error as any).message,
    };
  }
};

// fetches airports with typing into inputs
export const searchAirports = async (
  query: string
): Promise<AirportCommon[] | null> => {
  const queryParams = new URLSearchParams({
    term: query,
  });
  const airportsEndpoint = `${getAPIUrl()}/api/v2/airports?${queryParams}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return null;
  }

  try {
    const response = await fetch(airportsEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return result;
  } catch (error) {
    console.error('Error fetching crew home airports - ', error);
    handleErrorToSentry(error, airportsEndpoint);
    return null;
  }
};

export const getVesselCrewChangeEvents = async (
  vesselId: number,
  withPastEvents?: boolean
): Promise<CrewChangeEvent | null> => {
  const baseEndpoint = `${getAPIUrl()}/api/v2/vessels/${vesselId}/crew-change-events`;
  const crewEventEndpoint = `${baseEndpoint}?withPastEvents=${Boolean(
    withPastEvents
  )}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return null;
  }

  try {
    const response = await fetch(crewEventEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const crewChangeEvents = await response.json();
    return crewChangeEvents;
  } catch (error) {
    console.error('Error fetching crew change events - ', error);
    handleErrorToSentry(error, crewEventEndpoint);
    return null;
  }
};

export const getUserInfo = async (): Promise<{
  success: boolean;
  message: string;
  userConfig?: UserInfo;
}> => {
  const userInfoEndpoint = `${getAPIUrl()}/api/v2/users/config`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(userInfoEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'User info retrieved successfully.',
      userConfig: result,
    };
  } catch (error) {
    console.log('Error fetching user config.', error);
    handleErrorToSentry(error, userInfoEndpoint);
    return {
      success: false,
      message: 'Failed to fetch user config.',
    };
  }
};

export const updateUserInfo = async (
  updateUserInfoRequest: UpdateUserInfoRequest
): Promise<{
  success: boolean;
  message: string;
  userConfig?: UserInfo;
}> => {
  const userInfoEndpoint = `${getAPIUrl()}/api/v2/users/config`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const response = await fetch(userInfoEndpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(updateUserInfoRequest),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'User info updated successfully.',
      userConfig: result,
    };
  } catch (error) {
    console.log('Error updating user config.', error);
    handleErrorToSentry(error, userInfoEndpoint);
    return {
      success: false,
      message: 'Failed to update user config.',
    };
  }
};

export const getUserNotificationEvents = async (): Promise<{
  success: boolean;
  message: string;
  events?: NotificationSubscriptionResp[];
}> => {
  const route = `${getAPIUrl()}/api/v2/notifications/subscriptions`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const res = await fetch(route, {
      method: 'GET',
      headers: headers(authToken),
    });

    const response = await res.json();
    return {
      success: true,
      message: 'Notification Events fetched successfully',
      events: response,
    };
  } catch (error) {
    console.log('Error fetching user notifications', error);
    handleErrorToSentry(error, route);
    return {
      success: false,
      message: 'Failed to user notifications.',
    };
  }
};

export const updateUserNotificationEvents = async (
  eventSubscriptionRequests: UpdateNotificationSubscriptionReq[]
): Promise<{
  success: boolean;
  message: string;
}> => {
  const route = `${getAPIUrl()}/api/v2/notifications/subscriptions`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const response = await fetch(route, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(eventSubscriptionRequests),
    });
    if (response.status >= 200 && response.status < 300) {
      return {
        success: true,
        message: 'User Notification updated successfully.',
      };
    } else {
      const { message } = await response.json();
      return {
        success: false,
        message,
      };
    }
  } catch (error) {
    console.log('Error updating user notification', error);
    handleErrorToSentry(error, route);
    return {
      success: false,
      message: 'Failed to update user notification.',
    };
  }
};

export const assignMyselfAsPIC = async (
  vesselIds: number[]
): Promise<{
  success: boolean;
  message: string;
  vesselIds?: number[];
}> => {
  const assignMyselfAsPICRoute = `${getAPIUrl()}/api/flotillav2/assign_myself_as_pic`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }
  try {
    const res = await fetch(assignMyselfAsPICRoute, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify({ vessels: vesselIds }),
    });

    return await res.json();
  } catch (err) {
    console.error(err);
    handleErrorToSentry(err, assignMyselfAsPICRoute);
    return {
      success: false,
      message: 'Could not assign PIC.',
    };
  }
};

export const assignPIC = async (
  picId: number,
  vesselIds: number[]
): Promise<{
  success: boolean;
  message: string;
}> => {
  const assignPICRoute = `${getAPIUrl()}/api/v2/vessels/assign-pic`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }
  try {
    const response = await fetch(assignPICRoute, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify({ newPicId: picId, flotVesIds: vesselIds }),
    });

    // throw exception for invalid response code
    handleAPIError(response.status);
    return {
      success: true,
      message: 'Assigned PIC successfully.',
    };
  } catch (err) {
    handleErrorToSentry(err, assignPICRoute);
    return {
      success: false,
      message: 'Could not assign PIC.',
    };
  }
};

export const addVessel = async (
  vesselIMO: string
): Promise<{
  success: boolean;
  message: string;
  id?: number;
  vessel?: {
    name: string;
  };
}> => {
  if (!imoChecksum(vesselIMO))
    return {
      success: false,
      message: 'Invalid IMO',
    };

  const addVesselEndpoint = `${getAPIUrl()}/api/flotillav2/add_vessel`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }
  try {
    const res = await fetch(addVesselEndpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify({ imo: vesselIMO }),
    });

    return await res.json();
  } catch (err) {
    console.error(err);
    handleErrorToSentry(err, addVesselEndpoint);
    return {
      success: false,
      message: 'Could not add vessel.',
    };
  }
};

export const getVesselPortCalls = async (
  vesselId: number
): Promise<PortCallResponse> => {
  const routeEndpoint = `${getAPIUrl()}/api/v2/vessels/${vesselId}/port-calls`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated',
      portCalls: [],
    };
  }
  try {
    const response = await fetch(routeEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });

    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);

    const { portCallsV2, dictionary } = result as GetPortCallsV2Resp;
    const portMap = dictionary.ports.reduce(
      (map, port) => ({ ...map, [port.locode]: port }),
      {}
    );
    const portCalls = portCallsV2.map((o) =>
      formatPortCallUserTimeV2(o, portMap, vesselId)
    );

    return {
      success: true,
      message: 'Successfully fetched port call',
      portCalls,
      meta: result.meta,
    };
  } catch (err) {
    console.error('Error fetching port call - ', err);
    handleErrorToSentry(err, routeEndpoint);
    return {
      success: false,
      message: (err as any).message,
      portCalls: [],
    };
  }
};

// new batch port-calls API
export const getBatchPortCalls = async (
  request: BatchGetPortCallsReqBody
): Promise<BatchPortCallsResponse> => {
  const endpoint = `${getAPIUrl()}/api/v2/vessels/port-calls`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(request),
    });
    const parsedResponse = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, parsedResponse);
    return {
      success: true,
      message: 'Port calls are successfully fetched in batches.',
      portCalls: parsedResponse,
    };
  } catch (error) {
    console.log('Error fetching port calls in batches.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch port calls in batches.',
    };
  }
};

export const addReminder = async (
  reminder: Reminder
): Promise<{
  success: boolean;
  message: string;
}> => {
  const addReminderEndpoint = `${getAPIUrl()}/api/v2/reminders`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }
  try {
    await fetch(addReminderEndpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(reminder),
    });

    return {
      success: true,
      message: 'Reminder Added Successfully',
    };
  } catch (err) {
    console.error(err);
    handleErrorToSentry(err, addReminderEndpoint);
    return {
      success: false,
      message: 'Could not add reminder.',
    };
  }
};

export const hideNotification = async (
  id: number
): Promise<{
  success: boolean;
  message: string;
  notification?: NotificationUpdate;
}> => {
  const route = `${getAPIUrl()}/api/flotillav2/update_notifications/hide/${id}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const res = await fetch(route, {
      method: 'POST',
      headers: headers(authToken),
    });

    if (res.status !== 200) {
      return {
        success: false,
        message: ((await res.json()) as any)['message'],
      };
    }

    return {
      success: true,
      message: 'success',
      notification: await res.json(),
    };
  } catch (error) {
    handleErrorToSentry(error, route);
    return {
      success: false,
      message: (error as any).message,
    };
  }
};

export const markNotificationAsRead = async (
  id: number
): Promise<{
  success: boolean;
  message: string;
  notification?: NotificationUpdate;
}> => {
  const route = `${getAPIUrl()}/api/flotillav2/update_notifications/${id}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }
  try {
    const res = await fetch(route, {
      method: 'POST',
      headers: headers(authToken),
    });

    if (res.status !== 200) {
      return {
        success: false,
        message: ((await res.json()) as any)['message'],
      };
    }

    return {
      success: true,
      message: 'success',
      notification: await res.json(),
    };
  } catch (error) {
    handleErrorToSentry(error, route);
    return {
      success: false,
      message: (error as any).message,
    };
  }
};

export const markSelectedAsRead = async (
  ids: number[]
): Promise<{
  success: boolean;
  message: string;
  notifications?: NotificationUpdate[];
}> => {
  const route = `${getAPIUrl()}/api/flotillav2/update_notifications/markSelectedAsRead`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const res = await fetch(route, {
      method: 'POST',
      body: JSON.stringify(ids),
      headers: headers(authToken),
    });

    if (res.status !== 200) {
      return {
        success: false,
        message: ((await res.json()) as any)['message'],
      };
    }

    return {
      success: true,
      message: 'success',
      notifications: await res.json(),
    };
  } catch (error) {
    handleErrorToSentry(error, route);
    return {
      success: false,
      message: (error as any).message,
    };
  }
};

export const fetchPortsInRange = async (
  lng: number,
  lat: number
): Promise<{
  success: boolean;
  message: string;
  ports?: ProximityPort[];
}> => {
  const route = `${getAPIUrl()}/api/flotillav2/ports/proximityports?lat=${lat}&lng=${lng}&rangeNauticalMiles=1000`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const res = await fetch(route, {
      method: 'GET',
      headers: headers(authToken),
    });

    return {
      success: true,
      message: 'success',
      ports: await res.json(),
    };
  } catch (error) {
    handleErrorToSentry(error, route);
    return {
      success: false,
      message: 'Error fetching ports in range - ' + error,
    };
  }
};

export const fetchOrgPreferredPorts = async (): Promise<
  PreferredPort[] | null
> => {
  const endpoint = `${getAPIUrl()}/api/v2/preferred-ports`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return null;
  }

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return result;
  } catch (error) {
    console.error('Error fetching user preferred ports - ', error);
    handleErrorToSentry(error, endpoint);
    return null;
  }
};

export const fetchCrewChangePorts = async (
  request: PortRequest | null,
  page: number = 1
): Promise<PortResponse | null> => {
  const portsEndpoint = `${getAPIUrl()}/api/flotillav2/ports/proximityports?page=${page}&size=${PROXPORTS_PAGE_SIZE}`;
  const authToken = getAuthTokenValue();

  if (!authToken || !request) {
    return null;
  }

  try {
    const response = await fetch(portsEndpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(request),
    });

    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return result;
  } catch (error) {
    console.error('Error fetching ports - ', error);
    handleErrorToSentry(error, portsEndpoint);
    return null;
  }
};

// fetch organization crew with searched term - can be crew name, country or rank
export const fetchSearchedOrgCrew = async (
  query: string
): Promise<{
  success: boolean;
  message: string;
  response: ListCrewShortResp | null;
}> => {
  const authToken = getAuthTokenValue();
  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
      response: null,
    };
  }

  const endpoint = `${getAPIUrl()}/api/v2/crew/summarized/search?searchTerm=${query}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const parsedResponse = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, parsedResponse);
    return {
      success: true,
      message: 'Searched crew fetched successfully.',
      response: parsedResponse,
    };
  } catch (error) {
    console.log('Error fetching searched crew.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch searched crew.',
      response: null,
    };
  }
};

export const saveCrewChangeData = async (
  ccPlanRequest: CreateCrewChangePlanReq
): Promise<{
  success: boolean;
  message: string;
  response: CrewChangePlanWithDetailsResp | null;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/crew-change-plans`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
      response: null,
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(ccPlanRequest),
    });
    const parsedResponse = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, parsedResponse);
    return {
      success: true,
      message: 'Crew change data saved successfully',
      response: parsedResponse,
    };
  } catch (error) {
    console.log('Error saving crew change data', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to save crew change data.',
      response: null,
    };
  }
};

// override the existing crew-change plan
export const updateCrewChangeData = async (
  planId: string,
  ccPlanRequest: CreateCrewChangePlanReq
): Promise<{
  success: boolean;
  message: string;
  response: CrewChangePlanWithDetailsResp | null;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/crew-change-plans/${planId}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
      response: null,
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'PUT',
      headers: headers(authToken),
      body: JSON.stringify(ccPlanRequest),
    });
    const parsedResponse = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, parsedResponse);
    return {
      success: true,
      message: 'Crew change data updated successfully',
      response: parsedResponse,
    };
  } catch (error) {
    console.log('Error updating crew change data', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to update crew change data.',
      response: null,
    };
  }
};

// This is a soft delete of crew-change plan. Doesn't remove permenantly from back-end
export const deleteCrewChangePlan = async (
  uuid: string
): Promise<{
  success: boolean;
  message: string;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/crew-change-plans/${uuid}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'DELETE',
      headers: headers(authToken),
    });
    // throw exception for invalid response code
    handleAPIError(response.status);
    return {
      success: true,
      message: 'Crew change plan is removed successfully!',
    };
  } catch (error) {
    console.log('Error removing crew change data', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to removed the crew-change plan.',
    };
  }
};

export const getCrewChangeData = async (
  id: string
): Promise<{
  success: boolean;
  message: string;
  response: CrewChangePlanWithDetailsResp | null;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/crew-change-plans/${id}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
      response: null,
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const parsedResponse = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, parsedResponse);
    return {
      success: true,
      message: 'Crew change data fetched successfully',
      response: parsedResponse,
    };
  } catch (error) {
    console.log('Error fetching crew change data', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to fetch crew change data.',
      response: null,
    };
  }
};

export const fetchVesselCrewChangePlans = async (
  vesselId: number,
  options?: { page?: number; isCompleted?: boolean }
): Promise<{
  success: boolean;
  message: string;
  response?: SavedCrewChangePlanResponse;
}> => {
  const { page = 1, isCompleted = true } = options || {};
  const queryParams = `planType=company&filter.flotillaVesselId=${vesselId}&filter.isCompleted=${isCompleted}&page=${page}`;
  const endpoint = `${getAPIUrl()}/api/v2/crew-change-plans?${queryParams}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const parsedResponse = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, parsedResponse);
    return {
      success: true,
      message: 'Saved crew change plans fetched successfully',
      response: parsedResponse,
    };
  } catch (error) {
    console.log('Error fetching crew change plans', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch crew change plans.',
    };
  }
};

export const fetchFlights: FetchCrewFlightsApi = (reqParams, options) =>
  new Promise(async (resolve) => {
    // always pass formatted time as `startDate` by using `getFlightReqTime` in `lib/common`
    // whereever this API util is applied
    // see existing implementations
    const { startCode, endCode, startDate } = reqParams;
    const { refresh, cabinClass } = options || {};
    const authToken = getAuthTokenValue();

    if (!authToken) {
      resolve(DEFAULT_FLIGHT_RESULT);
      return;
    }

    const currentTime = new Date().toISOString();
    let result = DEFAULT_FLIGHT_RESULT;
    const baseQueryParams = new URLSearchParams({
      startCode,
      endCode,
      startDate,
      ...(cabinClass ? { cabinClass } : {}),
    });
    const flightReqQueryParams = refresh
      ? `${baseQueryParams}&refreshCache=${refresh}`
      : baseQueryParams;
    const flightsEndpoint = `${getAPIUrl()}/api/flotillav2/get_flights?${flightReqQueryParams}`;

    try {
      const response = await fetch(flightsEndpoint, {
        method: 'POST',
        headers: headers(authToken),
      });
      const { resultsURL: streamingURL } = await response.json();
      const stream = new EventSource(getAPIUrl() + streamingURL);

      stream.addEventListener('flight_returned', (response: any) => {
        const flightResult = JSON.parse(response.data.trim());
        const newFlights = (flightResult?.data || []).map(
          (flight: FlightResult & { requestId: string }) => ({
            ...flight,
            // insert unique id for fetched flights
            id: uuidv4(),
            // insert the request details used to fetch this flight
            requestId: `${startCode}--${endCode}--${startDate}`,
            // timestamp when the flights are fetched
            fetchedAt: currentTime,
          })
        );
        result = {
          flights: result.flights.concat(newFlights),
          fromCache: Boolean(flightResult?.meta?.isCacheHit),
        };
      });

      stream.addEventListener('done', () => {
        stream.close();
        console.log(
          `Fetched flights for ${startCode} to ${endCode} from ${startDate}:`,
          result.flights
        );
        console.log(`Fetched from cache: ${result.fromCache}`);
        resolve(result);
      });

      // close the stream after 5 minutes & resolve with the current flight result
      delay(() => {
        stream.close();
        resolve(result);
      }, EVENT_STREAM_TIMEOUT);
    } catch (error) {
      console.error('Error fetching flights - ', error);
      handleErrorToSentry(error, flightsEndpoint);
      resolve(DEFAULT_FLIGHT_RESULT);
    }
  });

// fetch vessel budgets for crew change
export const fetchVesselBudgets = async (
  vesselId: number
): Promise<{
  success: boolean;
  message: string;
  result?: VesselCCBudgetResponse;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/vessels/${vesselId}/per-crew-budgets`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const parsedResponse = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, parsedResponse);
    return {
      success: true,
      message: 'Fetched vessel budgets successfully.',
      result: parsedResponse,
    };
  } catch (error) {
    console.log('Error fetching vessel budgets.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch vessel budgets.',
    };
  }
};

export const getReminders = async (
  pageToken?: string,
  size: number = 10,
  reminderStatus?: ReminderStatusType | undefined
): Promise<ReminderResponse> => {
  const reminderEndpoint = `${getAPIUrl()}/api/v2/reminders?pageSize=${size}
  ${pageToken ? `&pageToken=${pageToken}` : ''}
  ${reminderStatus ? `&reminderStatus=${reminderStatus}` : ''}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const res = await fetch(reminderEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    return {
      success: true,
      message: 'success',
      reminders: await res.json(),
    };
  } catch (error) {
    handleErrorToSentry(error, reminderEndpoint);
    return {
      success: false,
      message: 'Error fetching reminders - ' + error,
    };
  }
};

export const deleteReminder = async (
  reminderId: string
): Promise<{ success: boolean; message: string }> => {
  const reminder = `${getAPIUrl()}/api/v2/reminders/${reminderId}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    await fetch(reminder, {
      method: 'DELETE',
      headers: headers(authToken),
    });

    return {
      success: true,
      message: 'success',
    };
  } catch (error) {
    handleErrorToSentry(error, reminder);
    return {
      success: false,
      message: 'Error removing reminder - ' + error,
    };
  }
};

export const sendSeaGPTOutboundEmail = async (
  req: any
): Promise<{
  success: boolean;
  message?: string;
  output?: any;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/assisted-crew-changes/new-outbound`;

  try {
    const authToken = getAuthTokenValue();
    if (!authToken) {
      return {
        success: false,
        message: 'User is not authenticated. Please login.',
      };
    }
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(req),
    });
    handleAPIError(response.status);
    const output = await response.json();
    return {
      success: true,
      message: 'Email sent successfully.',
      output,
    };
  } catch (error) {
    handleErrorToSentry(error, endpoint);
    return { success: false, message: (error as any).message };
  }
};

export const sendAgentEmail = async (
  emailReq: SendAgentEmailReq & {
    isSemGpt?: boolean;
  }
): Promise<{
  success: boolean;
  message?: string;
  sendResponse?: SendAgentEmailResp;
}> => {
  const sendAgentEmailEndpoint = `${getAPIUrl()}/api/flotillav2/emails/agent/outreach`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const res = await fetch(sendAgentEmailEndpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(emailReq),
    });

    return {
      success: true,
      sendResponse: await res.json(),
    };
  } catch (err) {
    console.error(err);
    handleErrorToSentry(err, sendAgentEmailEndpoint);
    return {
      success: false,
      message: 'Could not send email.',
    };
  }
};

export const searchAgents = async (
  searchReq: AgentSearchReq
): Promise<{
  success: boolean;
  message?: string;
  searchResponse?: AgentSearchResp;
}> => {
  const searchParams = new URLSearchParams();
  Object.keys(searchReq).forEach((k) => {
    const v = searchReq[k as keyof AgentSearchReq];
    if (v) {
      searchParams.set(k, `${v}`);
    }
  });
  const searchAgentsEndpoint = `${getAPIUrl()}/api/flotillav2/emails/agents/search?${searchParams}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const res = await fetch(searchAgentsEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });

    return {
      success: true,
      searchResponse: await res.json(),
    };
  } catch (err) {
    console.error(err);
    handleErrorToSentry(err, searchAgentsEndpoint);
    return {
      success: false,
      message: 'Could not search for agents.',
    };
  }
};

export const searchPortAgents = async (
  searchReq: ListPortAgentsQueryParams
): Promise<{
  success: boolean;
  message: string;
  data?: ListPortAgentsResp;
}> => {
  const searchParams = new URLSearchParams();
  Object.keys(searchReq).forEach((k) => {
    const v = searchReq[k as keyof ListPortAgentsQueryParams];
    if (v) {
      searchParams.set(k, `${v}`);
    }
  });
  const endpoint = `${getAPIUrl()}/api/v2/port-agents?${searchParams}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const res = await fetch(endpoint, {
      method: 'GET',
      headers: headers(authToken),
    });

    return {
      success: true,
      message: 'Successfully fetched.',
      data: await res.json(),
    };
  } catch (err) {
    console.error(err);
    handleErrorToSentry(err, endpoint);
    return {
      success: false,
      message: 'Could not search for agents.',
    };
  }
};

export const addAgent = async (
  addAgentReq: AddAgentReq
): Promise<{
  success: boolean;
  message?: string;
  addAgentResp?: AddAgentResp;
}> => {
  const addAgentEndpoint = `${getAPIUrl()}/api/flotillav2/emails/agents`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const res = await fetch(addAgentEndpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(addAgentReq),
    });

    return {
      success: true,
      addAgentResp: await res.json(),
    };
  } catch (err) {
    console.error(err);
    handleErrorToSentry(err, addAgentEndpoint);
    return {
      success: false,
      message: 'Could not add agent.',
    };
  }
};

// fetch search & update alerts
export const fetchAlerts = async (
  page: number
): Promise<{
  success: boolean;
  alertResponse: AlertResponse | null;
}> => {
  const alertEndpoint = `${getAPIUrl()}/api/flotillav2/alerts/alertrules?page=${page}`;
  const authToken = getAuthTokenValue();

  if (!authToken || !page) {
    return { success: false, alertResponse: null };
  }

  try {
    const response = await fetch(alertEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const alertResponse = await response.json();
    return { success: true, alertResponse };
  } catch (error) {
    console.log('Error while fetching alerts', error);
    handleErrorToSentry(error, alertEndpoint);
    return { success: false, alertResponse: null };
  }
};

// update a search/update alert
export const updateAlert = async (
  request: UpdateAlertRequest
): Promise<{
  success: boolean;
  alert: Alert | null;
}> => {
  const { id: alertId, ...requestBody } = request;
  const alertEndpoint = `${getAPIUrl()}/api/flotillav2/alerts/alertrules/${alertId}`;
  const authToken = getAuthTokenValue();

  if (!authToken || !request) {
    return { success: false, alert: null };
  }

  try {
    const response = await fetch(alertEndpoint, {
      method: 'PUT',
      headers: headers(authToken),
      body: JSON.stringify(requestBody),
    });
    const alert = await response.json();
    return { success: true, alert };
  } catch (error) {
    console.log('Error while updating alert', error);
    handleErrorToSentry(error, alertEndpoint);
    return { success: false, alert: null };
  }
};

// update search/update alert
export const deleteAlert = async (
  alertId: number
): Promise<{
  success: boolean;
}> => {
  const alertEndpoint = `${getAPIUrl()}/api/flotillav2/alerts/alertrules/${alertId}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false };
  }

  try {
    await fetch(alertEndpoint, {
      method: 'DELETE',
      headers: headers(authToken),
    });
    return { success: true };
  } catch (error) {
    console.log('Error while deleting alert', error);
    handleErrorToSentry(error, alertEndpoint);
    return { success: false };
  }
};

// Create Search Filter or Update Notification Alerts
export const createNewAlert = async (
  props: CreateAlertRequest
): Promise<{ success: boolean; message: string; alert: Alert | null }> => {
  const alertEndpoint = `${getAPIUrl()}/api/flotillav2/alerts/alertrules`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Please login.', alert: null };
  }

  try {
    const res = await fetch(alertEndpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(props),
    });

    const newAlert = await res.json();

    return {
      success: true,
      message: 'Alert successfully created.',
      alert: newAlert,
    };
  } catch (error) {
    handleErrorToSentry(error, alertEndpoint);
    return {
      success: false,
      message: 'Error creating alert - ' + error,
      alert: null,
    };
  }
};

export const storeUserSelectedPorts = async (
  userSelectedPortsReq: UserSelectedPortsReq
): Promise<{ success: boolean; message: string }> => {
  const url = `${getAPIUrl()}/api/v2/pois`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Unauthenticated user. Please login.' };
  }

  try {
    await fetch(url, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(userSelectedPortsReq),
    });

    return {
      success: true,
      message: 'Successfully stored selected ports.',
    };
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Error saving user selected ports - ' + error,
    };
  }
};

export const searchPortsForRestrictions = async (
  term: string
): Promise<PortRestrictionSearchResponse> => {
  const url = `${getAPIUrl()}/api/v2/ports/search?${new URLSearchParams({
    term,
    meta: 'restrictionsAvailable',
  })}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: headers(authToken),
    });

    return {
      success: true,
      message: 'success',
      ...(await res.json()),
    };
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Error fetching ports - ' + error,
    };
  }
};

export const getRestrictions = async ({
  type,
  source,
  portOrCountryCode,
}: RestrictionsReqParams): Promise<{
  success: boolean;
  message: string;
  data: RestrictionsDataWithUserFeedbackResp | null;
}> => {
  const params = new URLSearchParams({
    type,
    ...(source ? { source } : {}),
  });
  const restrictionsEndpoint = `${getAPIUrl()}/api/v2/restrictions/${portOrCountryCode}?${params}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Please login.', data: null };
  }

  try {
    const res = await fetch(restrictionsEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });

    const data = await res.json();

    return {
      success: true,
      message: 'Restriction details are fetched successfully.',
      data,
    };
  } catch (error) {
    handleErrorToSentry(error, restrictionsEndpoint);
    return {
      success: false,
      message: 'Error fetching restriction details.',
      data: null,
    };
  }
};

export const getSeaGptRestrictions = async ({
  locode,
  agent,
}: SeaGPTRestrictionsReqParams): Promise<{
  success: boolean;
  message: string;
  data: RestrictionsData | null;
}> => {
  const restrictionsEndpoint = `${getAPIUrl()}/api/v2/sem-gpt/restrictions/port/${locode}/${agent}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Please login.', data: null };
  }

  try {
    const res = await fetch(restrictionsEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });

    const data = await res.json();

    return {
      success: true,
      message: 'Restriction details are fetched successfully.',
      data,
    };
  } catch (error) {
    handleErrorToSentry(error, restrictionsEndpoint);
    return {
      success: false,
      message: 'Error fetching proteus restriction details.',
      data: null,
    };
  }
};

export const updateRestrictionsFeedback = async (
  feedbackRequest: RestrictionsDataFeedbackReqBody
): Promise<{ success: boolean; message: string }> => {
  const feedbackEndpoint = `${getAPIUrl()}/api/v2/restrictions/feedback`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'User is not authenticated.' };
  }

  try {
    await fetch(feedbackEndpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(feedbackRequest),
    });
    return { success: true, message: 'Successfully updated feedback.' };
  } catch (error) {
    handleErrorToSentry(error, feedbackEndpoint);
    return {
      success: false,
      message: 'Failed to update your feedback.',
    };
  }
};

// fetches ports with typing into inputs
export const searchPorts = async (
  term: string,
  page: number = 1,
  size: number = 20,
  options?: { nearbyAirports?: boolean; pastCce?: boolean },
  signal?: AbortSignal
): Promise<{
  success: boolean;
  message: string;
  portsResponse: SearchedPortResponse | null;
}> => {
  const metaParams = [
    ...(options?.nearbyAirports ? ['nearbyAirports'] : []),
    ...(options?.pastCce ? ['pastCceCount', 'pastCceSummaries'] : []),
  ];
  const portsEndpoint = `${getAPIUrl()}/api/v2/ports/search?term=${term}&page=${page}&size=${size}${
    metaParams.length ? `&meta=${metaParams.join(',')}` : ''
  }`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Please login.', portsResponse: null };
  }

  try {
    const res = await fetch(portsEndpoint, {
      method: 'GET',
      headers: headers(authToken),
      signal,
    });

    const portsResponse = await res.json();

    return {
      success: true,
      message: 'Searched ports fetched successfully.',
      portsResponse,
    };
  } catch (error) {
    if ((error as Error).name !== 'AbortError')
      handleErrorToSentry(error, portsEndpoint);

    if ((error as Error).name === 'AbortError') {
      return {
        success: false,
        message: 'Aborted Request.',
        portsResponse: null,
      };
    }
    return {
      success: false,
      message: 'Error fetching searched ports - ' + (error as Error)?.message,
      portsResponse: null,
    };
  }
};

// fetch port notes
export const fetchPortNotes = async (
  locode: string,
  pageToken: string = '',
  size: number = 15
): Promise<{
  success: boolean;
  message: string;
  notesResponse: PortNotesResponse | null;
}> => {
  const baseEndpoint = `${getAPIUrl()}/api/v2/ports/${locode}/notes?pageSize=${size}`;
  const notesEndpoint = pageToken
    ? `${baseEndpoint}&pageToken=${pageToken}`
    : baseEndpoint;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
      notesResponse: null,
    };
  }

  try {
    const res = await fetch(notesEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });

    const notesResponse = await res.json();

    return {
      success: true,
      message: 'Ports notes fetched successfully.',
      notesResponse,
    };
  } catch (error) {
    handleErrorToSentry(error, notesEndpoint);
    return {
      success: false,
      message: 'Error fetching port notes - ' + error,
      notesResponse: null,
    };
  }
};

// add new port notes
export const savePortNotes = async (
  locode: string,
  notes: { text: string }
): Promise<{ success: boolean; message: string }> => {
  const feedbackEndpoint = `${getAPIUrl()}/api/v2/ports/${locode}/notes`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    await fetch(feedbackEndpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(notes),
    });
    return { success: true, message: 'Successfully added port notes.' };
  } catch (error) {
    handleErrorToSentry(error, feedbackEndpoint);
    return {
      success: false,
      message: 'Failed to add port notes.',
    };
  }
};

export const fetchAppSettings = async (): Promise<{
  success: boolean;
  message: string;
  settings: AppSettings | null;
}> => {
  const settingsEndPoint = `${getAPIUrl()}/api/v2/users/app-settings`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Please login.', settings: null };
  }

  try {
    const response = await fetch(settingsEndPoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const { settings } = await response.json();
    return {
      success: true,
      message: 'App settings fetched successfully!',
      settings,
    };
  } catch (error) {
    handleErrorToSentry(error, settingsEndPoint);
    return {
      success: false,
      message: 'Failed to fetch app settings.',
      settings: null,
    };
  }
};

export const saveAppSettings = async (
  appSettings: AppSettings
): Promise<{
  success: boolean;
  message: string;
  settings: AppSettings | null;
}> => {
  const settingsEndPoint = `${getAPIUrl()}/api/v2/users/app-settings`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Please login.', settings: null };
  }

  try {
    const response = await fetch(settingsEndPoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(appSettings),
    });
    const { settings } = await response.json();
    return {
      success: true,
      message: 'App settings saved successfully!',
      settings,
    };
  } catch (error) {
    handleErrorToSentry(error, settingsEndPoint);
    return {
      success: false,
      message: 'Failed to save app settings.',
      settings: null,
    };
  }
};

export const getUserCompletedOnboardingSteps = async (): Promise<{
  success: boolean;
  message: string;
  onboardingSteps: OnboardCompletion[] | null;
}> => {
  const url = `${getAPIUrl()}/api/v2/users/onboarding-steps`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Please login.', onboardingSteps: null };
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers(authToken),
    });
    const { error, message, onboardingSteps } = await response.json();
    if (error && message === 'No onboarding steps stored for user.') {
      return {
        success: true,
        message,
        onboardingSteps: [],
      };
    }
    try {
      const validated = create(onboardingSteps, StepsCompletedSchema);
      return {
        success: true,
        message: 'Onboard steps loaded successfully!',
        onboardingSteps: validated,
      };
    } catch (error) {
      return {
        success: true,
        message: 'Onboarding steps were invalid!',
        onboardingSteps: [],
      };
    }
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Failed to load onboarding steps',
      onboardingSteps: [],
    };
  }
};

export const saveUserCompletedOnboardingSteps = async (
  onboardingSteps: OnboardCompletion[]
): Promise<{
  success: boolean;
  message: string;
}> => {
  const url = `${getAPIUrl()}/api/v2/users/onboarding-steps`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Please login.' };
  }

  try {
    await fetch(url, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify({ onboardingSteps }),
    });
    return {
      success: true,
      message: 'Onboard saved successfully!',
    };
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Failed to save onboarding steps',
    };
  }
};

export const getUserOnboardingContent = async (): Promise<{
  success: boolean;
  message: string;
  onboardContent: OnboardContent[] | null;
}> => {
  const url = `${CONTENT_API_URL}/api/onboard-details`;
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    const { data } = await response.json();
    try {
      const validated = mask(
        data.map((o: any) => o.attributes),
        StepsContentArraySchema
      );
      return {
        success: true,
        message: 'Onboard content loaded successfully!',
        onboardContent: validated,
      };
    } catch (error) {
      return {
        success: true,
        message: 'Onboarding content is invalid!',
        onboardContent: [],
      };
    }
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Failed to load onboarding content',
      onboardContent: [],
    };
  }
};

export const userForgotPassword = async (
  forgotPasswordValues: UserForgotPasswordValues
): Promise<{
  success: boolean;
  message: string;
}> => {
  const url = `${getAPIUrl()}/api/v2/users/forgot-password`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(forgotPasswordValues),
    });
    if (response.status >= 200 && response.status < 300) {
      return { success: true, message: 'Sent reset password link.' };
    } else {
      const { message } = await response.json();

      return {
        success: false,
        message,
      };
    }
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Failed to send reset password link.',
    };
  }
};

export const userResetPassword = async (
  resetPasswordValues: UserResetPasswordRequest
): Promise<{
  success: boolean;
  message: string;
}> => {
  const url = `${getAPIUrl()}/api/v2/users/reset-password`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resetPasswordValues),
    });
    if (response.status >= 200 && response.status < 300) {
      return { success: true, message: 'Password Reset is Successful.' };
    } else {
      const { error, validationErrors, message } = await response.json();
      if (error && validationErrors) {
        return {
          success: false,
          message: validationErrors
            .map((o: any) => o.errorMessages.join(','))
            .join(', '),
        };
      }

      return {
        success: false,
        message,
      };
    }
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Failed to reset password.',
    };
  }
};

export const magicLinkResetPassword = async (
  resetPasswordValues: Omit<MagicLinkResetPasswordValues, 'retypePassword'>
): Promise<{
  success: boolean;
  message: string;
}> => {
  const url = `${getAPIUrl()}/api/v2/users/authed-reset-password`;
  const authToken = getAuthTokenValue();
  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(resetPasswordValues),
    });
    if (response.status >= 200 && response.status < 300) {
      return { success: true, message: 'Password Reset is Successful.' };
    } else {
      const { error, validationErrors, message } = await response.json();
      if (error && validationErrors) {
        return {
          success: false,
          message: validationErrors
            .map((o: any) => o.errorMessages.join(','))
            .join(', '),
        };
      }

      return {
        success: false,
        message,
      };
    }
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Failed to reset password.',
    };
  }
};

export const userSignUp = async (
  signupValues: Omit<UserSignUpValues, 'agree'>
): Promise<{
  success: boolean;
  message: string;
}> => {
  const url = `${getAPIUrl()}/api/v2/users/sign-up`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(signupValues),
    });

    if (response.status >= 200 && response.status < 300) {
      return { success: true, message: 'Created new user.' };
    } else {
      const result = await response.json();
      const { error, validationErrors, message } = result;
      if (error && validationErrors) {
        return {
          success: false,
          message: validationErrors
            .map((o: any) => o.errorMessages.join(','))
            .join(', '),
        };
      } else {
        return {
          success: false,
          message,
        };
      }
    }
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Failed to sign up new user',
    };
  }
};

export const userSignUpVerify = async (
  verifySignupToken: UserVerifySignUp
): Promise<{
  success: boolean;
  message: string;
}> => {
  const url = `${getAPIUrl()}/api/v2/users/verify-token`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(verifySignupToken),
    });

    if (response.status >= 200 && response.status < 300) {
      return { success: true, message: 'Completed verification.' };
    } else {
      const { message } = await response.json();
      return {
        success: false,
        message,
      };
    }
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Failed to verify email',
    };
  }
};

export const userRequestToken = async (
  email: string
): Promise<{
  success: boolean;
  message: string;
}> => {
  const url = `${getAPIUrl()}/api/v2/users/request-verification-token`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    if (response.status >= 200 && response.status < 300) {
      return { success: true, message: 'Successfully requested a new token.' };
    } else {
      const { message } = await response.json();
      return {
        success: false,
        message,
      };
    }
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Failed to request new token',
    };
  }
};

export const magicLinkLogin = async (
  code: string,
  email: string
): Promise<{
  success: boolean;
  message: string;
  authPacket?: AuthPacket;
}> => {
  const url = `${getAPIUrl()}/api/v2/users/sign-in/magic-link/${code}?${new URLSearchParams(
    { email }
  )}`;
  try {
    const response = await fetch(url, {
      method: 'POST',
    });
    const { message, error, authPacket } = await response.json();
    return {
      success: !error,
      message,
      authPacket,
    };
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: 'Failed to login',
    };
  }
};

export const getRiskAreas = async (): Promise<{
  success: boolean;
  message: string;
  riskAreas: RiskArea[] | null;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/risk-areas`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
      riskAreas: null,
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'GET',
      headers: headers(authToken),
    });

    const { riskAreas } = await response.json();

    return {
      success: true,
      message: 'Risk area fetched successfully.',
      riskAreas,
    };
  } catch (error) {
    console.log('Error fetching risk area data', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to fetch risk areas.',
      riskAreas: null,
    };
  }
};

export const getGeofences = async (): Promise<{
  success: boolean;
  message: string;
  geofences: GeofenceCommon[] | null;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/geofences`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
      geofences: null,
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'GET',
      headers: headers(authToken),
    });

    const { geofences } = await response.json();

    return {
      success: true,
      message: 'Geofence fetched successfully.',
      geofences,
    };
  } catch (error) {
    console.log('Error fetching geofence data', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to fetch geofences.',
      geofences: null,
    };
  }
};

export const getRiskAreaTrackingRequests = async (
  vesselId: number
): Promise<{
  success: boolean;
  message: string;
  riskAreaTrackingRequests: RiskAreaTrackingReqCommon[] | null;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/risk-area-tracking-requests?flotillaVesselId=${vesselId}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
      riskAreaTrackingRequests: null,
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'GET',
      headers: headers(authToken),
    });

    const { riskAreaTrackingRequests } = await response.json();
    return {
      success: true,
      message: 'Risk area tracking request fetched successfully.',
      riskAreaTrackingRequests,
    };
  } catch (error) {
    console.log('Error fetching risk area tracking request data', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to fetch risk area tracking requests.',
      riskAreaTrackingRequests: null,
    };
  }
};

export const deleteRiskAreaTrackingRequest = async (
  vesselId: number,
  riskAreaId: number
): Promise<{
  success: boolean;
  message: string;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/risk-area-tracking-requests`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'DELETE',
      headers: headers(authToken),
      body: JSON.stringify({ flotillaVesselId: vesselId, riskAreaId }),
    });

    if (response.status >= 200 && response.status < 300) {
      return {
        success: true,
        message: 'Risk area tracking request deleted successfully.',
      };
    } else {
      const { message } = await response.json();
      return {
        success: false,
        message,
      };
    }
  } catch (error) {
    console.log('Error deleting risk area tracking request', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to delete risk area tracking request.',
    };
  }
};

export const addRiskAreaTrackingRequest = async (
  vesselId: number,
  riskAreaId: number
): Promise<{
  success: boolean;
  message: string;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/risk-area-tracking-requests`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify({ flotillaVesselId: vesselId, riskAreaId }),
    });

    if (response.status >= 200 && response.status < 300) {
      return {
        success: true,
        message: 'Risk area tracking request added successfully.',
      };
    } else {
      const { message } = await response.json();
      return {
        success: false,
        message,
      };
    }
  } catch (error) {
    console.log('Error adding risk area tracking request', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to add risk area tracking request.',
    };
  }
};

export const getRiskAreaTrackingReport = async (
  trackingRequestId: number,
  fromDate: string,
  toDate: string
): Promise<{
  success: boolean;
  message: string;
  report?: GetRiskAreaTrackingReportResp;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/risk-area-tracking-requests/${trackingRequestId}/report?${new URLSearchParams(
    { fromDate, toDate }
  )}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'GET',
      headers: headers(authToken),
    });

    const result = await response.json();

    if (response.status >= 200 && response.status < 300) {
      return {
        success: true,
        message: 'Risk area report fetched successfully.',
        report: result,
      };
    } else {
      return {
        success: false,
        message: result.message,
      };
    }
  } catch (error) {
    console.log('Error loading risk area report', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to load risk area report.',
    };
  }
};

// retrieve users and assigned vessels within a company
export const fetchCompanyUsers = async (): Promise<{
  success: boolean;
  message: string;
  companyUsers?: GetCompanyUsersJsonResp | null;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/companies/users`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Company Users retrieved successfully.',
      companyUsers: result,
    };
  } catch (error) {
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to fetch Company Users.',
    };
  }
};

// retrieve track settings for flights
export const fetchTrackSettings = async (): Promise<{
  success: boolean;
  message: string;
  trackSettings?: TrackFlightsSettings | null;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/users/track-flights-settings`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result, [401]);
    if (result.error) {
      return {
        success: false,
        message: result.error,
      };
    }
    return {
      success: true,
      message: 'Track flights settings retrieved successfully.',
      trackSettings: result,
    };
  } catch (error) {
    console.log('Err', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to fetch track flights settings.',
    };
  }
};

// Deletes track settings from track-flights-settings table
// Does NOT delete from user-app-settings table
// Only use it for E2E test(s)
// Details: https://github.com/Greywing-Maritime/greywing-server/pull/360
export const deleteTrackSettings = async (): Promise<{
  success: boolean;
  message: string;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/users/track-flights-settings`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'DELETE',
      headers: headers(authToken),
    });
    // throw exception for invalid response code
    handleAPIError(response.status);
    return {
      success: true,
      message: 'Track flights settings deleted successfully.',
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed to delete track flights settings.',
    };
  }
};

// update track lfight settings from settings modal
export const updateTrackFlightSettings = async (
  trackSettingRequest: TrackFlightsSettings
): Promise<{
  success: boolean;
  message: string;
  trackSettings?: TrackFlightsSettings | null;
}> => {
  const endPoint = `${getAPIUrl()}/api/v2/users/track-flights-settings`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(endPoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(trackSettingRequest),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Track flights settings updated successfully.',
      trackSettings: result,
    };
  } catch (error) {
    console.log('Error updating track flights settings.', error);
    handleErrorToSentry(error, endPoint);
    return {
      success: false,
      message: 'Failed to update track flights settings.',
    };
  }
};

// api util to track a flight
export const trackFlight = async (
  trackFlightReq: TrackFlightReqBody
): Promise<{
  success: boolean;
  message: string;
}> => {
  const trackFlightEndpoint = `${getAPIUrl()}/api/v2/flights/track`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Unauthenticated user. Please login.',
    };
  }

  try {
    const response = await fetch(trackFlightEndpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(trackFlightReq),
    });
    // throw exception for invalid response code
    handleAPIError(response.status);
    return {
      success: true,
      message: 'This flight is now being tracked.',
    };
  } catch (error) {
    console.log('Error tracking flight.', error);
    handleErrorToSentry(error, trackFlightEndpoint);
    return {
      success: false,
      message: 'Failed to track this flight. Try again later.',
    };
  }
};

// fetch avaialble hotels for a specific port
export const fetchPortHotels = async (
  locode: string
): Promise<{
  success: boolean;
  message: string;
  hotelSearchResult?: HotelsResponse | null;
}> => {
  // Currently, backend response fails for lowercase locode
  const portLocode = locode.toUpperCase();
  const endpoint = `${getAPIUrl()}/api/v2/hotels?locode=${portLocode}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    // throw exception for invalid response code
    handleAPIError(response.status);
    const result: FindHotelsResp = await response.json();
    // insert unique id for fetched hotels
    const hotels = result.hotels.map((hotel) => ({ ...hotel, id: uuidv4() }));
    const hotelSearchResult = { meta: result.meta, hotels };
    console.log(`Hotels fetched for ${portLocode}.`, hotels);
    return {
      success: true,
      message: 'Hotels fetched successfully.',
      hotelSearchResult,
    };
  } catch (error) {
    console.log('Error fetching hotels.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch hotels!',
    };
  }
};

export const addTagToVessels = async (request: {
  flotVesIds: number[];
  displayName: string;
  colour: string;
}): Promise<{
  success: boolean;
  message: string;
  tag?: VesselTag;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/vessels/tag`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(request),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Tags added successfully.',
      tag: result,
    };
  } catch (error) {
    console.log('Error adding tags.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to add tags!',
    };
  }
};

export const removeTagFromVessels = async (request: {
  flotVesIds: number[];
  tagId: number;
}): Promise<{
  success: boolean;
  message: string;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/vessels/untag`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'DELETE',
      headers: headers(authToken),
      body: JSON.stringify(request),
    });
    // throw exception for invalid response code
    handleAPIError(response.status);
    return {
      success: true,
      message: 'Tags removed successfully.',
    };
  } catch (error) {
    console.log('Error removing tags.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to remove tags!',
    };
  }
};

export const authorizeMsalSSO = async (): Promise<{
  success: boolean;
  message: string;
  data?: {
    redirectUrl: string;
    verifier: string;
  };
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/users/auth/ms/authorize`;

  try {
    const response = await fetch(endpoint, { method: 'POST' });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Successfully retrieved redirect',
      data: result,
    };
  } catch (error) {
    console.log('Error logging in.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to retrieve redirect',
    };
  }
};

export const loginViaMicrosoftSSO = async (
  authCode: string,
  verifier: string
): Promise<{
  success: boolean;
  message: string;
  errorWithLogin?: any;
  authPacket?: AuthPacket;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/users/auth/ms/sign-in`;

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ authCode, verifier }),
    });
    handleAPIError(response.status);
    return await response.json();
  } catch (error) {
    console.log('Error logging in.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to login',
    };
  }
};

export const getCrewPassport = async (
  cid: string
): Promise<{
  success: boolean;
  message: string;
  data?: Blob;
  filename?: string;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/passports/${cid}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: { ...headers(authToken), Accept: 'application/pdf' },
    });
    // throw exception for invalid response code
    if (response.status === 404) {
      return {
        success: false,
        message: 'Passport not found for this crew.',
      };
    } else {
      handleAPIError(response.status);
    }

    return {
      success: true,
      message: 'Passport fetched successfully.',
      data: await response.blob(),
      filename:
        response.headers.get('content-disposition') ||
        response.headers.get('Content-Disposition') ||
        '',
    };
  } catch (error) {
    console.log('Error fetching passport.', error);
    handleErrorToSentry(error, endpoint);
    return {
      success: false,
      message: 'Failed to fetch passport',
    };
  }
};

export const questionLatestCopilot = async ({
  query,
  user,
  caseId,
  signal,
}: {
  query: string;
  user: UserInfo;
  caseId: string;
  signal: AbortSignal;
}): Promise<{
  success: boolean;
  message: string;
  command?: CopilotCommandObject;
  commandInputJson?: any;
}> => {
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    const endpoint = `${getAPIUrl()}/api/v2/seagpt-stream/question`;
    try {
      const authToken = getAuthTokenValue();
      if (!authToken) {
        return {
          success: false,
          message: 'User is not authenticated. Please login.',
        };
      }
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: headers(authToken),
        body: JSON.stringify({ query, user, caseId }),
        signal,
      });
      return await response.json();
    } catch (error) {
      console.log('Error asking copilot.', error);
      if ((error as Error).name !== 'AbortError')
        handleErrorToSentry(error, endpoint);
      return handleAbort(error as Error, {
        success: false,
        message: 'Failed to get a response, try again later',
      });
    }
  } else {
    const endpoint = `${process.env.REACT_APP_COPILOT_URL}/copilot/question`;
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({ query, user, caseId }),
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      });
      return await response.json();
    } catch (error) {
      console.log('Error asking copilot.', error);

      if ((error as Error).name !== 'AbortError')
        handleErrorToSentry(error, endpoint);
      return handleAbort(error as Error, {
        success: false,
        message: 'Failed to get a response, try again later',
      });
    }
  }
};

export async function initStreamGpt({
  signal,
  ...options
}: {
  signal: AbortSignal;
  [key: string]: any;
}): Promise<{ success: boolean; reqId?: string; message?: string }> {
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    const endpoint = `${getAPIUrl()}/api/v2/seagpt-stream/init-stream`;
    try {
      const authToken = getAuthTokenValue();
      if (!authToken) {
        return {
          success: false,
          message: 'User is not authenticated. Please login.',
        };
      }
      const getRequestId = await fetch(endpoint, {
        method: 'POST',
        headers: headers(authToken),
        body: JSON.stringify(options),
        signal,
      });
      return await getRequestId.json();
    } catch (error) {
      if ((error as Error).name !== 'AbortError')
        handleErrorToSentry(error, endpoint);
      return handleAbort(error as Error, {
        success: false,
        message: (error as any).message,
      });
    }
  } else {
    const endpoint = `${process.env.REACT_APP_COPILOT_URL}/copilot/init-stream`;
    try {
      const getRequestId = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(options),
        signal,
      });
      return await getRequestId.json();
    } catch (error) {
      if ((error as Error).name !== 'AbortError')
        handleErrorToSentry(error, endpoint);
      return handleAbort(error as Error, {
        success: false,
        message: (error as any).message,
      });
    }
  }
}

export async function getGptSuggestions(
  caseId: string,
  signal: AbortSignal
): Promise<{ success: boolean; data?: string[]; message: string }> {
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    const endpoint = `${getAPIUrl()}/api/v2/seagpt-stream/suggestions/${caseId}?count=3`;
    try {
      const authToken = getAuthTokenValue();
      if (!authToken) {
        return {
          success: false,
          message: 'User is not authenticated. Please login.',
        };
      }
      const response = await fetch(endpoint, {
        headers: headers(authToken),
        signal,
      });
      return await response.json();
    } catch (error) {
      if ((error as Error).name !== 'AbortError')
        handleErrorToSentry(error, endpoint);
      return handleAbort(error as Error, {
        success: false,
        message: (error as any).message,
      });
    }
  } else {
    const endpoint = `${process.env.REACT_APP_COPILOT_URL}/copilot/suggestions/${caseId}?count=3`;
    try {
      const response = await fetch(endpoint, {
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      });
      return await response.json();
    } catch (error) {
      if ((error as Error).name !== 'AbortError')
        handleErrorToSentry(error, endpoint);
      return handleAbort(error as Error, {
        success: false,
        message: (error as any).message,
      });
    }
  }
}

export async function getWhisperTranscription({
  audio,
  context,
}: {
  audio: string; // in base64string format
  context?: string;
}): Promise<{ success: boolean; message: string; data?: string }> {
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    const endpoint = `${getAPIUrl()}/api/v2/seagpt-stream/transcribe`;
    try {
      const authToken = getAuthTokenValue();
      if (!authToken) {
        return {
          success: false,
          message: 'User is not authenticated. Please login.',
        };
      }
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: headers(authToken),
        body: JSON.stringify({
          file: audio,
          model: 'whisper-1',
          context,
        }),
      });
      return await response.json();
    } catch (error) {
      handleErrorToSentry(error, endpoint);
      return { success: false, message: (error as any).message };
    }
  } else {
    const endpoint = `${process.env.REACT_APP_COPILOT_URL}/copilot/transcribe`;
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          file: audio,
          model: 'whisper-1',
          context,
        }),
      });
      return await response.json();
    } catch (error) {
      handleErrorToSentry(error, endpoint);
      return { success: false, message: (error as any).message };
    }
  }
}

export async function sendMessageToMM(body: {
  user: UserInfo;
  caseId?: string;
  isAudio: boolean;
  messages: SeaGPTChatMessage[];
  meta?: any;
  retries?: number;
}): Promise<{ success: boolean; message: string }> {
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    const endpoint = `${getAPIUrl()}/api/v2/seagpt-stream/log`;
    try {
      const authToken = getAuthTokenValue();
      if (!authToken) {
        return {
          success: false,
          message: 'User is not authenticated. Please login.',
        };
      }
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: headers(authToken),
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      handleErrorToSentry(error, endpoint);
      return { success: false, message: (error as any).message };
    }
  } else {
    const endpoint = `${process.env.REACT_APP_COPILOT_URL}/copilot/log`;
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      handleErrorToSentry(error, endpoint);
      return { success: false, message: (error as any).message };
    }
  }
}

export async function sendAmplitudeMessageToMM(body: {
  user: UserInfo;
  caseId?: string;
  message: string;
}): Promise<{ success: boolean; message: string }> {
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    const endpoint = `${getAPIUrl()}/api/v2/seagpt-stream/analytics`;
    try {
      const authToken = getAuthTokenValue();
      if (!authToken) {
        return {
          success: false,
          message: 'User is not authenticated. Please login.',
        };
      }
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: headers(authToken),
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      handleErrorToSentry(error, endpoint);
      return { success: false, message: (error as any).message };
    }
  } else {
    const endpoint = `${process.env.REACT_APP_COPILOT_URL}/copilot/analytics`;
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      handleErrorToSentry(error, endpoint);
      return { success: false, message: (error as any).message };
    }
  }
}

export async function generateNewGptTopic(
  caseId: string
): Promise<{ success: boolean; data?: string; message: string }> {
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    const endpoint = `${getAPIUrl()}/api/v2/seagpt-stream/topic/${caseId}`;
    try {
      const authToken = getAuthTokenValue();
      if (!authToken) {
        return {
          success: false,
          message: 'User is not authenticated. Please login.',
        };
      }
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: headers(authToken),
      });
      return await response.json();
    } catch (error) {
      handleErrorToSentry(error, endpoint);
      return {
        success: false,
        message: (error as any).message,
      };
    }
  } else {
    const endpoint = `${process.env.REACT_APP_COPILOT_URL}/copilot/topic/${caseId}`;
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return await response.json();
    } catch (error) {
      if ((error as Error).name !== 'AbortError')
        handleErrorToSentry(error, endpoint);
      return handleAbort(error as Error, {
        success: false,
        message: (error as any).message,
      });
    }
  }
}

export const selectFlightToBook = async (
  flightId: string
): Promise<{ success: boolean; message: string }> => {
  const endpoint = `${getAPIUrl()}/api/v2/flights/booking/select-flight`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify({
        flightId,
      }),
    });

    if (response.status === 409) {
      return {
        success: false,
        message:
          (await response.json())?.message ||
          `Failed to select flight - ${flightId}`,
      };
    } else {
      // throw exception for invalid response code
      handleAPIError(response.status);
    }
    return {
      success: true,
      message: 'Successfully selected flight.',
    };
  } catch (error) {
    console.log('Error selecting flight', error);
    return {
      success: false,
      message: `Failed to select flight - ${flightId}`,
    };
  }
};

export const getFlightBookings = async (
  filters: ListFlightBookingTxQueryParams
): Promise<{
  success: boolean;
  message: string;
  data?: ListFlightBookingTxResp;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/flights/booking?${new URLSearchParams(
    // @ts-ignore
    filters
  )}`;

  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      headers: headers(authToken),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Successfully fetched bookings.',
      data: result,
    };
  } catch (error) {
    console.log('Error fetching bookings', error);
    return {
      success: false,
      message: 'Failed to fetch bookings',
    };
  }
};

export const getFlightBooking = async (
  flightTxId: string
): Promise<{
  success: boolean;
  message: string;
  data?: GetFlightBookingTxResp;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/flights/booking/${flightTxId}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      headers: headers(authToken),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);

    return {
      success: true,
      message: 'Successfully fetched booking.',
      data: result,
    };
  } catch (error) {
    console.log('Error fetching booking', error);
    return {
      success: false,
      message: `Failed to fetch booking - ${flightTxId}`,
    };
  }
};

export const updateBookingTraveller = async (
  flightTxId: string,
  etag: string,
  travellerInfo: FlightBookingTravelerInfoUpsertDtoCommon
): Promise<{
  success: boolean;
  message: string;
  data?: GetFlightBookingTxResp;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/flights/booking/${flightTxId}/traveler-infos`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'PUT',
      headers: headers(authToken),
      body: JSON.stringify({ etag, travelerInfos: [travellerInfo] }),
    });
    if (response.status === 400) {
      return {
        success: false,
        message:
          (await response.json())?.message || 'Failed to update traveller info',
      };
    } else {
      handleAPIError(response.status);
    }
    return {
      success: true,
      message: 'Successfully updated traveller info.',
      data: await response.json(),
    };
  } catch (error) {
    console.log('Error updating traveller info', error);
    return {
      success: false,
      message: 'Failed to update traveller info',
    };
  }
};

export const submitBookFlight = async (
  flightTxId: string,
  etag: string
): Promise<{
  success: boolean;
  message: string;
  data?: GetFlightBookingTxResp;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/flights/booking/${flightTxId}/hold-flight`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify({ etag }),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Successfully made booking',
      data: result,
    };
  } catch (error) {
    console.log('Error making booking', error);
    return {
      success: false,
      message: 'Failed to submit booking',
    };
  }
};

export const refreshTokenForUser = async () => {
  try {
    console.log('Verifying auth...');
    const authToken = getAuthToken();

    if (!authToken.success) return false;

    const res = await fetch(`${getAPIUrl()}/api/v2/users/refresh-token`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken.token}`,
      },
    });

    const result = await res.json();

    if (result.updatedExpiry) {
      window.localStorage.setItem(SESSION_TOKEN_EXPIRY, result.updatedExpiry);
      return true;
    }

    return false;
  } catch (err) {
    Sentry.captureException(err);
    console.error('Error verifying auth - ', err);
    return false;
  }
};

export const getTmcFlightBookings = async (
  filters: ListFlightBookingTxQueryParams
): Promise<{
  success: boolean;
  message: string;
  data?: ListFlightBookingTxResp;
}> => {
  const endpoint = `${getAPIUrl()}/api/v2/tmc/flights/booking?${new URLSearchParams(
    // @ts-ignore
    filters
  )}`;

  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(endpoint, {
      headers: headers(authToken),
    });
    const result = await response.json();
    // throw exception for invalid response code
    handleAPIError(response.status, result);
    return {
      success: true,
      message: 'Successfully fetched bookings.',
      data: result,
    };
  } catch (error) {
    console.log('Error fetching bookings', error);
    return {
      success: false,
      message: 'Failed to fetch bookings',
    };
  }
};

export const crewLinkRequest = async (
  req: CreateCrewLinkBookingRequestReqCommon
): Promise<{ success: boolean; message: string }> => {
  const url = `${getAPIUrl()}/api/v2/crew-link/booking-request`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(req),
    });
    await response.json();
    return { success: true, message: 'Successfully made crew link request.' };
  } catch (error) {
    return {
      success: false,
      message:
        (error as Error).message ?? 'Failed to create crew link request.',
    };
  }
};

export const getCrewLinkDashboardURL = async (): Promise<{
  success: boolean;
  message: string;
  data?: { link: string };
}> => {
  const url = `${getAPIUrl()}/api/v2/crew-link/generate-cl-dashboard-link`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers(authToken),
    });
    const result = await response.json();
    return {
      success: true,
      message: 'Successfully generated crew link dashboard url.',
      data: result,
    };
  } catch (error) {
    return {
      success: false,
      message:
        (error as Error).message ?? 'Failed to create crew link dashboard url.',
    };
  }
};

export const getFixedPayload = async (): Promise<{
  success: boolean;
  message: string;
  timeTaken?: number;
}> => {
  const url = `${getAPIUrl()}/files/randfile`;

  try {
    const start = performance.now();
    const response = await fetch(url, {
      mode: 'no-cors',
      method: 'GET',
      cache: 'no-store',
    });
    const finish = performance.now();
    handleAPIError(response.status, undefined, [0]);
    return {
      success: true,
      message: 'Successfully made request.',
      timeTaken: finish - start,
    };
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: (error as Error).message ?? 'Failed to make request.',
    };
  }
};

export const stopFlightTrackingRequest = async ({
  uid,
  ...props
}: {
  uid: string;
  greywingSuggestedFlightRebooked: boolean;
  reason: string;
  depCode: string;
  arrCode: string;
  depTime: string;
}): Promise<{
  success: boolean;
  message: string;
  timeTaken?: number;
}> => {
  const url = `${getAPIUrl()}/api/v2/flights/${uid}/stop-tracking`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(props),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    handleAPIError(response.status);
    return {
      success: true,
      message: 'Successfully stopped flight tracking.',
    };
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message: (error as Error).message ?? 'Failed to stop flight tracking.',
    };
  }
};

// TODO: Not currently used yet
export const getAssistedCrewChangeEmails = async ({
  showCompanyRes,
  page,
}: {
  showCompanyRes: boolean;
  page: number;
}): Promise<
  | {
      success: false;
      message: string;
    }
  | {
      success: true;
      message: string;
      output: {
        data: AssistedCrewChangeSummaryResp[];
        meta: PageInfo;
      };
    }
> => {
  const params: { showCompanyRes: string; page?: string } = {
    showCompanyRes: String(showCompanyRes),
  };
  if (page) params.page = String(page);
  const url = `${getAPIUrl()}/api/v2/assisted-crew-changes?${new URLSearchParams(
    params
  )}`;

  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(url, {
      headers: headers(authToken),
    });
    handleAPIError(response.status);
    return {
      success: true,
      message: 'Successfully fetched assisted crew change emails.',
      output: await response.json(),
    };
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message:
        (error as any).message ??
        'Failed to fetch assisted crew change emails.',
    };
  }
};

export const getAssistedCrewChangeConvo = async (
  query: ListAssistedCrewChangeConvosQuery
): Promise<
  | {
      success: false;
      message: string;
    }
  | {
      success: true;
      message: string;
      output: {
        data: AssistedCrewChangeConvoSummaryResp[];
        nextPageToken: string;
        totalCount: number;
      };
    }
> => {
  const queryParam: any = {
    scope: query.scope,
    pageSize: 10,
  };
  if (query.searchFilters?.portName) {
    queryParam['searchFilters[portName]'] = query.searchFilters.portName!;
  }
  if (query.searchFilters?.creatorEmail) {
    queryParam['searchFilters[creatorEmail]'] =
      query.searchFilters.creatorEmail!;
  }
  if (query.nextPageToken) {
    queryParam.nextPageToken = query.nextPageToken;
  }
  const url = `${getAPIUrl()}/api/v2/assisted-crew-changes/convos?${new URLSearchParams(
    queryParam
  )}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(url, {
      headers: headers(authToken),
    });
    handleAPIError(response.status);
    return {
      success: true,
      message: 'Successfully fetched assisted crew change email conversation.',
      output: await response.json(),
    };
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message:
        (error as any).message ??
        'Failed to fetch assisted crew change email conversation',
    };
  }
};

export const getAssistedCrewChangeEmailConvo = async (
  id: number
): Promise<
  | {
      success: false;
      message: string;
    }
  | {
      success: true;
      message: string;
      data: AssistedCrewChangeAgentConvoResp;
    }
> => {
  const url = `${getAPIUrl()}/api/v2/assisted-crew-changes/convos/${id}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'User is not authenticated. Please login.',
    };
  }

  try {
    const response = await fetch(url, {
      headers: headers(authToken),
    });
    handleAPIError(response.status);
    return {
      success: true,
      message: 'Successfully fetched assisted crew change email conversation.',
      data: await response.json(),
    };
  } catch (error) {
    handleErrorToSentry(error, url);
    return {
      success: false,
      message:
        (error as any).message ??
        'Failed to fetch assisted crew change email conversation',
    };
  }
};
