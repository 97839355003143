import React, {
  Dispatch,
  SetStateAction,
  Suspense,
  createContext,
  useCallback,
  useState,
} from 'react';

import { useAppDispatch, useModal } from 'hooks';
import { fetchVesselScheduleEventsAsync } from 'redux/thunks';
import {
  getEventUpdateText,
  getInitialCrewChangeSchedule,
} from 'components/CrewMatrixPlanning/helpers';
import {
  CrewChangeSchedule,
  CrewChangeEventDetailed,
  VesselInfo,
  EventUpdateType,
  ConfirmEventUpdate,
  CalendarViewType,
} from 'components/CrewMatrixPlanning/types';

import { CommonConfirmModal } from 'components/shared';

const CrewMatrixPage = React.lazy(() => import('pages/CrewMatrixPage'));

type ContextState = {
  selectedVessel: VesselInfo | null;
  currentEvent: CrewChangeEventDetailed | null;
  crewChangeSchedule: CrewChangeSchedule;
  calendarView: CalendarViewType;
  updateCalendarView: Dispatch<SetStateAction<CalendarViewType>>;
  updateSelectedVessel: Dispatch<SetStateAction<VesselInfo | null>>;
  updateCurrentEvent: Dispatch<SetStateAction<CrewChangeEventDetailed | null>>;
  updateCrewChangeSchedule: Dispatch<SetStateAction<CrewChangeSchedule>>;
  confirmEventUpdate: ConfirmEventUpdate;
};

export const CCMatrixContext = createContext({} as ContextState);

export default function CrewChangeMatrix() {
  const dispatch = useAppDispatch();

  // vessel selected in schedule page. `null` if all vessels are selected
  const [selectedVessel, updateSelectedVessel] = useState<VesselInfo | null>(
    null
  );
  const [currentEvent, updateCurrentEvent] =
    useState<CrewChangeEventDetailed | null>(null);
  const [crewChangeSchedule, updateCrewChangeSchedule] =
    useState<CrewChangeSchedule>(getInitialCrewChangeSchedule);
  // state to update calendar between monthly & yearly view
  const [calendarView, updateCalendarView] =
    useState<CalendarViewType>('monthly');

  const { modal, setModal } = useModal();

  // update event with a confirmation modal
  // this handles indirect event updates, e. g - through WS or follow-up change after block-off
  const confirmEventUpdate = useCallback(
    (event: CrewChangeEventDetailed, type: EventUpdateType) => {
      setModal('confirmUpdate', {
        type,
        updateEvent: () => {
          const { id: vesselId, imo: vesselImo } = event.vessel;
          // update context level state
          updateCurrentEvent(event);
          // update vessel schedule events in store to reflect changes to any related event(s)
          dispatch(fetchVesselScheduleEventsAsync({ vesselId, vesselImo }));
          setModal(null);
        },
      });
  }, [currentEvent]); // eslint-disable-line

  const renderModal = () => {
    if (!modal?.type) {
      return null;
    }

    if (modal.type === 'confirmUpdate') {
      const { type, updateEvent } = modal.data;
      const description = (
        <>
          <div>{getEventUpdateText(type)}</div>
          <div>Receive the changes and update current event.</div>
        </>
      );
      return (
        <CommonConfirmModal
          title="Event Updated!"
          description={description}
          onConfirm={updateEvent}
        />
      );
    }
  };

  return (
    <CCMatrixContext.Provider
      value={{
        selectedVessel,
        currentEvent,
        crewChangeSchedule,
        calendarView,
        updateCalendarView,
        updateSelectedVessel,
        updateCurrentEvent,
        updateCrewChangeSchedule,
        confirmEventUpdate,
      }}
    >
      <Suspense fallback={<></>}>
        <CrewMatrixPage />
      </Suspense>

      {renderModal()}
    </CCMatrixContext.Provider>
  );
}
