import map from 'lodash/map';
import partition from 'lodash/partition';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataGridPro } from '@mui/x-data-grid-pro';
import styled from 'styled-components/macro';

import { useMobile } from 'hooks';
import { green, red } from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { selectCrewChangePanel, selectSettings } from 'redux/selectors';
import { Crew } from 'utils/types/crew-change-types';

import { OffsignerIcon, OnsignerIcon } from 'components/icons';
import {
  columnGroupingModel,
  getColumnVisibility,
  getReadOnlyColumns,
} from './Header';
import { NoRowsOverlay } from '../../common/TableItems';
import ReadOnlyControls from '../../common/Controls/ReadOnly';
import {
  headerStyles,
  ReadOnlyRow,
  ReadOnlyLabel,
  TableWrapper,
} from '../../common';
import { getCrewRowClassName } from '../../helpers';

const ActionsWrapper = styled.div`
  margin-left: 5%;
`;

const StyledRow = styled(ReadOnlyRow)`
  margin-top: 0.5rem;
`;

const StyledLabel = styled(ReadOnlyLabel)`
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 0.5rem;
`;

const Actions = ({ crewList }: { crewList: Crew[] }) => {
  const [onsignerCount, offsignerCount] = useMemo(
    () =>
      map(
        partition(crewList, ({ type }) => type!.toLowerCase() === 'onsigner'),
        (list) => list.length
      ),
    [crewList]
  );

  return (
    <ActionsWrapper>
      <StyledRow>
        {Boolean(offsignerCount) && (
          <>
            <StyledLabel>
              {offsignerCount} Offsigner{offsignerCount > 1 ? 's' : ''}
            </StyledLabel>
            <OffsignerIcon color={red} />
          </>
        )}
      </StyledRow>

      <StyledRow>
        {Boolean(onsignerCount) && (
          <>
            <StyledLabel>
              {onsignerCount} Onsigner{onsignerCount > 1 ? 's' : ''}
            </StyledLabel>
            <OnsignerIcon color={green} />
          </>
        )}
      </StyledRow>
    </ActionsWrapper>
  );
};

function ReadOnlyCrewTable() {
  const { readOnlyPlanningData: reportData } = useSelector(
    selectCrewChangePanel
  );
  const {
    crewChange: { compact: isCompact },
  } = useSelector(selectSettings);

  const isMobile = useMobile(BREAK_POINT_XS);
  const { crew = [] } = reportData?.crewChangePlan || {};
  const columns = getReadOnlyColumns();

  return (
    <>
      <ReadOnlyControls>
        <Actions crewList={crew} />
      </ReadOnlyControls>

      <TableWrapper $crew $isReportView>
        <DataGridPro
          // TODO: remove this ignore
          // @ts-ignore
          rows={crew}
          columns={columns}
          columnVisibilityModel={getColumnVisibility(isMobile)}
          checkboxSelection={false}
          disableSelectionOnClick
          keepNonExistentRowsSelected
          disableColumnMenu
          disableColumnReorder
          hideFooter
          density={isCompact ? 'compact' : 'standard'}
          getRowClassName={getCrewRowClassName()}
          sx={headerStyles}
          components={{ NoRowsOverlay }}
          componentsProps={{
            noRowsOverlay: { type: 'crew' },
          }}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel}
        />
      </TableWrapper>
    </>
  );
}

export default memo(ReadOnlyCrewTable);
