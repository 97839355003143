import {
  memo,
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { DataGridPro, GridRowModel } from '@mui/x-data-grid-pro';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { useAppDispatch, useMobile } from 'hooks';
import { trackUserAction } from 'lib/amplitude';
import { TRACK_ADD_CREW, TRACK_REMOVE_CREW } from 'utils/analytics/constants';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { selectCrewChangePanel, selectSettings } from 'redux/selectors';
import {
  selectCrewChangeEvent as selectEvent,
  updateColumnVisibility,
} from 'redux/actions';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { Crew } from 'utils/types/crew-change-types';

import HomeAirports from '../../Map/HomeAirports';
import {
  columnGroupingModel,
  getColumnVisibility,
  getGridColumns,
} from './Header';
import Actions from '../Actions/Crew';
import Controls from '../../common/Controls';
import { Header, NoRowsOverlay } from '../../common/TableItems';
import { headerStyles, TableWrapper } from '../../common';
import {
  hideCrewPlanning,
  isValidCrew,
  isAllCrewSelected,
  removeCrew,
  addToCrewRows,
  getCrewRows,
  CREW_ROWS,
  getCrewRowClassName,
  isValidCrewUpdate,
  setActionTime,
  sortCrewOrder,
  initialCrewAddStatus,
  addMissingFields,
  isCrewSelectable,
  sortCrewRank,
  getCrewInitialColumnVisibility,
} from '../../helpers';
import {
  AddCrew,
  CrewAddStatus,
  CrewInput,
  RemoveCrewFunc,
  SelectCrewItem,
} from '../../types';

function CrewTable(): JSX.Element {
  const dispatch = useAppDispatch();
  const ccPanelInfo = useSelector(selectCrewChangePanel);
  const {
    crewChange: { compact: isCompact },
  } = useSelector(selectSettings);
  const { data, updatePlanningData } = useContext(CCPanelContext);
  const isMobile = useMobile(BREAK_POINT_XS);

  const hidePlanning = hideCrewPlanning(ccPanelInfo);
  const { vesselId, event, active, columnVisibility } = ccPanelInfo;
  const isPlanningStep = active === 'plan';
  const isEmptyCrewChange = isPlanningStep && event && !event.id;

  const crewRows = useMemo(
    () => getCrewRows(data as Crew[], (event as CrewEvent)?.crew),
    [] // eslint-disable-line
  );
  const [rows, setRows] = useState(crewRows);
  const [allSelected, setAllSelected] = useState(
    isAllCrewSelected(data as Crew[], rows)
  );
  const [addStatus, setAddStatus] =
    useState<CrewAddStatus>(initialCrewAddStatus);
  const [newCrew, setNewCrew] = useState<CrewInput | null>(null);

  const { addingId } = addStatus;
  const activeRows = (rows as CrewInput[]).filter(isValidCrew);
  const selectedCrewIds = ((allSelected ? activeRows : data) as Crew[]).map(
    ({ id }) => id
  );

  useEffect(() => {
    addToCrewRows(crewRows as Crew[]);
    if (active === 'plan') {
      setActionTime('crew', 'start');
    }
    if (!columnVisibility?.crew) {
      dispatch(
        updateColumnVisibility({
          crew: getCrewInitialColumnVisibility(columns),
        })
      );
    }

    return () => {
      if (active === 'plan') {
        setActionTime('crew', 'end');
      }
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    // For empty/fersh events, groups planning crew data based on type & then sorts based on rank
    if (!event?.id) {
      updatePlanningData((prev) => ({
        ...prev,
        crew: sortCrewRank(prev.crew),
      }));
    }
  }, [rows]); // eslint-disable-line

  useEffect(() => {
    setAllSelected(isAllCrewSelected(data as Crew[], rows));
  }, [data.length, rows]); // eslint-disable-line

  const handleUpdateStatus = (status: Partial<CrewAddStatus>) => {
    setAddStatus((prevStatus) => ({ ...prevStatus, ...status }));
  };

  const handleOpenAddCrew: AddCrew = (newCrew) => {
    setNewCrew({ ...newCrew, name: '' });
    handleUpdateStatus({ addingId: newCrew.id });
  };

  const handleAddCrew = (
    newCrew: CrewInput,
    existing?: boolean // indicates if the crew is from other events
  ): void => {
    const addedCrew = addMissingFields(newCrew);
    // prepare new crew list based on general addition or replacement in row
    const updatedList =
      !existing && addedCrew.replacementCrewId
        ? CREW_ROWS.map((c) => (c.id === addedCrew.id ? addedCrew : c))
        : [addedCrew];

    // reset add crew related states
    setNewCrew(null);
    handleUpdateStatus({ addingId: false });
    // update table rows
    setRows(
      addToCrewRows(
        updatedList,
        existing ? false : Boolean(addedCrew.replacementCrewId)
      )
    );
    updatePlanningData((prevData) => ({
      ...prevData,
      crew: sortCrewOrder([addedCrew, ...prevData.crew]),
    }));

    trackUserAction(TRACK_ADD_CREW);
  };

  const handleRemoveCrew: RemoveCrewFunc = ({ all, crew }) => {
    const updatedList = removeCrew({ all, crew })!;
    setRows(updatedList);
    updatePlanningData((prevData) => ({
      ...prevData,
      crew:
        (updatedList.length &&
          (crew
            ? prevData.crew.filter(({ id }) => id !== crew.id)
            : (all && prevData.crew.filter(({ added }) => !added)) ||
              prevData.crew)) ||
        [],
    }));
    trackUserAction(TRACK_REMOVE_CREW);
  };

  const handleToggleSelectAllCrew = () => {
    const selected = !allSelected;
    const selectedCrew = (selected && activeRows) || [];

    setAllSelected(selected);
    updatePlanningData((prevData) => ({ ...prevData, crew: selectedCrew }));
    // start planning event, if already hasn't, with `SELECT ALL`
    if (vesselId && active === 'crew') {
      dispatch(
        selectEvent({ vesselId, event, active: selected ? 'plan' : 'crew' })
      );
    }
  };

  const handleSelect = (ids: (number | string)[]) => {
    const startPlanning = vesselId && active === 'crew' && Boolean(ids.length);
    if (startPlanning) {
      dispatch(selectEvent({ vesselId, event, active: 'plan' }));
    }
    updatePlanningData((prevData) => ({
      ...prevData,
      crew: activeRows.filter((c) => ids.includes(c.id)),
    }));
  };

  // selects item from auto-select dropd-down
  const handleSelectItem: SelectCrewItem = (crew, item) => {
    const formatList = (crewList: Crew[]) =>
      crewList.map((c) => (c.id === crew.id ? { ...c, ...item } : c));
    setRows((rows) => addToCrewRows(formatList(rows as Crew[])));
    updatePlanningData(({ crew: prevCrew, ...rest }) => ({
      ...rest,
      crew: formatList(prevCrew),
    }));
  };

  const handleUpdateCrew = useCallback(
    (newRow: CrewInput) => {
      const updatedList = CREW_ROWS.map((c) =>
        c.id === newRow.id ? newRow : c
      );
      setRows(addToCrewRows(updatedList));
      updatePlanningData((prevData) => ({
        ...prevData,
        crew: prevData.crew.map((c) => (c.id === newRow.id ? newRow : c)),
      }));
    },
    [updatePlanningData]
  );

  const processRowUpdate = useCallback(
    (newRow: CrewInput, oldRow: CrewInput) =>
      new Promise<GridRowModel>((resolve) => {
        if (!isValidCrewUpdate(newRow, oldRow)) {
          resolve(oldRow);
          return;
        }
        const formattedRow = { ...newRow, wage: Number(newRow.wage || '') };
        handleUpdateCrew(formattedRow);
        resolve(formattedRow);
      }),
    [handleUpdateCrew]
  );

  const funcProps = {
    removeCrew: handleRemoveCrew,
    selectItem: handleSelectItem,
    addCrew: handleOpenAddCrew,
  };
  const columns = useMemo(
    () => getGridColumns(isPlanningStep, addingId, funcProps),
    [isPlanningStep, addingId] // eslint-disable-line
  );
  // For empty/fersh events, groups crew list in table based on type & then sorts based on rank
  const sortedRows = useMemo(
    () => (!event?.id ? sortCrewRank(rows as CrewInput[]) : rows),
    [event, rows]
  );

  return (
    <>
      {isPlanningStep && (
        <Controls
          disableNext={!rows.length}
          crewProps={{
            selectAll: !!rows.length ? handleToggleSelectAllCrew : undefined,
          }}
        >
          <Actions
            crew={newCrew}
            allSelected={allSelected}
            addStatus={addStatus}
            updateStatus={handleUpdateStatus}
            addCrew={handleAddCrew}
            toggleAll={handleToggleSelectAllCrew}
            reset={() => setNewCrew(null)}
            removeAll={() => handleRemoveCrew({ all: true })}
            selectAirport={handleSelectItem}
          />
        </Controls>
      )}

      <TableWrapper $crew>
        <DataGridPro
          rows={sortedRows}
          columns={columns}
          selectionModel={selectedCrewIds}
          onSelectionModelChange={handleSelect}
          columnVisibilityModel={getColumnVisibility(
            isMobile,
            columnVisibility?.crew
          )}
          // allow selection for empty crew change
          checkboxSelection={isEmptyCrewChange || !hidePlanning}
          disableColumnMenu
          disableSelectionOnClick
          disableColumnReorder
          hideFooter
          density={isCompact ? 'compact' : 'standard'}
          isRowSelectable={isCrewSelectable(rows)}
          getRowClassName={getCrewRowClassName(addingId)}
          sx={headerStyles}
          components={{ Header, NoRowsOverlay }}
          componentsProps={{
            noRowsOverlay: {
              type: 'crew',
              updateStatus: handleUpdateStatus,
            },
          }}
          columnGroupingModel={columnGroupingModel}
          experimentalFeatures={{ columnGrouping: true, newEditingApi: true }}
          processRowUpdate={processRowUpdate}
          isCellEditable={({ row }) => isPlanningStep && isValidCrew(row)}
        />
      </TableWrapper>

      {/* @ts-ignore */}
      <HomeAirports crew={data as Crew[]} />
    </>
  );
}

export default memo(CrewTable);
