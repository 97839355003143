import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useModal, usePrevious, useWindowSize } from 'hooks';
import { textGray } from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { selectCrewChangeVessel } from 'redux/selectors';
import { RootState } from 'redux/types';

import { CommonConfirmModal } from 'components/shared';
import PortCard from './PortCard';
import CompareCard from './CompareCard';
import Departures from './Departures';
import CustomLoader from './CustomLoader';
import Filters from './Filters';

import { Label } from '../../../common';
import {
  getBudgetDetailsForPort,
  getCurrentFilters,
  getDefaultTMC,
  getPortCards,
  getUnneededFlightsCount,
} from '../../../helpers';
import { PortCardType, FlightActionProps } from '../../../types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  margin-right: 0;
`;

const ContentWrapper = styled(FlexWrapper)`
  overflow-x: auto;
  width: 100%;
`;

const CardsWrapper = styled(FlexWrapper)<{
  $scrollable: boolean;
  $compare?: boolean;
}>`
  padding: 1px;
  padding-right: 0;
  overflow-x: auto;
  max-width: ${({ $compare }) => ($compare ? '100%' : '60%')};
  flex-shrink: 0;
  ${({ $scrollable }) =>
    $scrollable &&
    `
    box-shadow: inset -10px 0 10px -10px rgba(0, 0, 0, 0.25);
    padding-right: 0.5rem;
    margin-right: 0.5rem;
  `};

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    margin-bottom: 0.5rem;
  }
`;

const Border = styled.div`
  border-left: 2px solid ${textGray};
  margin-right: 0.75rem;
  margin-left: 1rem;
  height: 1rem;
`;

const ControlsWrapper = styled.div`
  width: 100%;
  overflow-y: hidden;

  .MuiInputLabel-root,
  .MuiOutlinedInput-input {
    font-size: 0.8rem;
    font-family: HK Grotesk, Roboto;
  }
`;

function FlightActions({
  view,
  actionStatus,
  loadFlights,
  toggleConfirmAll,
}: FlightActionProps) {
  const flightParams = useSelector(
    (state: RootState) => state.settings.crewChange.flightParams
  );
  const costParams = useSelector(
    (state: RootState) => state.settings.crewChange.costParams
  );
  const vessel = useSelector(selectCrewChangeVessel);
  const vesselBudgets = useSelector(
    ({ crewChangeResources }: RootState) =>
      crewChangeResources.responses.vesselBudgets
  );
  const {
    filters: allFilters,
    fetchStatus: { initialized, progress },
    planningData: { crew, ports, route },
    updateFilters,
    updateTableState,
  } = useContext(CCPanelContext);

  const { filters, locodeKey = '' } = useMemo(
    () => getCurrentFilters(allFilters),
    [allFilters]
  );
  const activeLocode = filters?.locode || ports[0].locode;
  const { confirmed = [] } = (locodeKey && allFilters[locodeKey]) || {};
  const hideFilters = !filters?.departures; // visibility of departures & filters
  const currentPortLoading = (progress?.[locodeKey] || 0) < 1;

  const [width] = useWindowSize();
  const { modal, setModal } = useModal();
  const prevFlightParams = usePrevious(flightParams);

  // calculate if flight params in settings have been updated
  const flightParamsUpdated = useMemo(
    () => !isEqual(prevFlightParams, flightParams),
    [flightParams, prevFlightParams]
  );
  const isScrollable = useMemo(() => {
    const cards = document.getElementById('compare-cards');
    return Boolean(cards && cards.scrollWidth > cards.clientWidth);
  }, [width]); // eslint-disable-line
  const activePort = useMemo(
    () => ports.find(({ locode }) => locode === activeLocode)!,
    [ports, activeLocode]
  );
  const confirmedCount = useMemo(
    () => confirmed.length + getUnneededFlightsCount(crew, activePort),
    [crew, activePort, confirmed.length]
  );
  const portCards = useMemo(
    () => getPortCards(ports, route, allFilters),
    [ports, route, allFilters]
  );
  const flightSources = useMemo(
    () =>
      uniq(
        compact([
          // insert default TMC inside the calculated flight sources for the port card
          getDefaultTMC(vessel),
          // find flight sources for current port-card, calculated from fetched flights for that port
          ...(portCards.find(({ locode }) => locode === locodeKey)?.sources ||
            []),
        ])
      ),
    [locodeKey, vessel, portCards]
  );
  const initialLoading = !initialized && currentPortLoading;

  useEffect(() => {
    updateFilters({
      type: 'HYDRATE_FILTERS',
      payload: {
        ports,
        flightParams,
        costParams,
        defaultTMC: getDefaultTMC(vessel),
      },
    });
  }, []); // eslint-disable-line

  // trigger filters update to latest flight params from settings
  useEffect(() => {
    if (flightParamsUpdated) {
      setModal('updateFlightFilters');
    }
  }, [flightParamsUpdated]); // eslint-disable-line

  useEffect(() => {
    if (currentPortLoading) return;
    // set budget for selected port card
    updateTableState({
      type: 'budgetDetails',
      payload: getBudgetDetailsForPort(confirmed, vesselBudgets),
    });
  }, [currentPortLoading, vesselBudgets, confirmed, updateTableState]);

  const handleChangePort = (port: PortCardType) => {
    updateFilters({ type: 'CHANGE_PORT', payload: { port } });
  };

  // method to update flight filters with updated flight params
  const handleUpdateFlightFilter = useCallback(() => {
    updateFilters({
      type: 'UPDATE_WITH_FLIGHT_PARAMS',
      payload: { flightParams },
    });
    setModal(null);
  }, [flightParams, updateFilters, setModal]);

  const renderModal = () => {
    if (!modal?.type) return null;

    if (modal.type === 'updateFlightFilters') {
      const description = (
        <>
          App settings flight parameters have been updated.
          <div>
            All flight filters in crew-change panel will be updated with the
            latest flight parameters from settings. Do you want to proceed?
          </div>
        </>
      );
      return (
        <CommonConfirmModal
          title="Update Flight Filters?"
          description={description}
          onConfirm={handleUpdateFlightFilter}
          onCancel={() => setModal(null)}
        />
      );
    }
  };

  const renderDepartures = () => {
    if (view !== 'filter' || !locodeKey) return null;

    if (initialLoading) {
      return (
        <CustomLoader
          locodeKey={locodeKey}
          airportCode={activePort.selectedAirport?.iataCode || ''}
        />
      );
    }

    return (
      !hideFilters && (
        <FlexWrapper>
          <Departures
            disabled={actionStatus.allCompareable}
            activeLocode={activeLocode}
            locodeKey={locodeKey!}
            loadFlights={loadFlights}
          />
          <Border />
          <Label data-id="crew-flight-count">{`${confirmedCount}/${crew.length} crew have flights`}</Label>
        </FlexWrapper>
      )
    );
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <CardsWrapper
          id="compare-cards"
          $compare={view === 'compare'}
          $scrollable={isScrollable}
        >
          {portCards.map((port, index) => {
            const commonProps = {
              port,
              onChange: handleChangePort,
            };
            return view === 'filter' ? (
              <PortCard
                key={`${port.id}-${index}`}
                disabled={hideFilters}
                {...commonProps}
              />
            ) : (
              <CompareCard
                key={`${port.id}-${index}`}
                showPreferredOption={portCards.length > 1}
                {...commonProps}
              />
            );
          })}
        </CardsWrapper>

        {renderDepartures()}
      </ContentWrapper>

      {!initialLoading && view === 'filter' && locodeKey && !hideFilters && (
        <ControlsWrapper>
          <Filters
            locodeKey={locodeKey}
            sources={flightSources}
            actionStatus={actionStatus}
            toggleConfirmAll={toggleConfirmAll}
          />
        </ControlsWrapper>
      )}

      {renderModal()}
    </Wrapper>
  );
}

export default memo(FlightActions);
