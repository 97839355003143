import omit from 'lodash/omit';
import pick from 'lodash/pick';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import moment from 'moment';

import { showToaster } from 'lib/toaster';
import { AppDispatch, OnsignerFeedback } from 'redux/types';
import { fetchMatrixScheduleEvents } from 'api/crew-matrix';
import { addScheduleEvents } from 'redux/actions';
import { formatScheduleReqDates } from 'components/CrewMatrixPlanning/helpers';
import {
  CrewChangeEventDetailed,
  CrewChangeEventSummary,
} from 'components/CrewMatrixPlanning/types';

type InputDates = {
  fromDate: string;
  toDate: string;
};

type GetCMPScheduleEvents = (disaptch: AppDispatch) => (
  options: {
    year?: number;
    inputDates?: InputDates;
  },
  storedEvents?: CrewChangeEventSummary[]
) => Promise<void>;

export const CMP_FEEDBACK = 'cmpFeedback';

const getYearFromDate = (dateStr?: string) => moment(dateStr).year();

// sanitize older feedbacks by trimming down `onsigner` - only includes `externalCrewId` field
export const sanitizeCMPFeedback = (allFeedbacks: OnsignerFeedback | null) =>
  allFeedbacks
    ? Object.keys(allFeedbacks).reduce<OnsignerFeedback>((acc, eventId) => {
        const trimmedEventFeedbacks = allFeedbacks[eventId].map((item) => ({
          ...item,
          onsigner: pick(item.onsigner, 'externalCrewId'),
        }));
        return { ...acc, [eventId]: trimmedEventFeedbacks };
      }, {})
    : null;

// save CMP feedback to browser localStorage; handle exception
export const saveFeedbackToLocalStorage = (
  allFeedbacks: OnsignerFeedback | null
) => {
  const sanitizedData = sanitizeCMPFeedback(allFeedbacks);

  try {
    localStorage.setItem(CMP_FEEDBACK, JSON.stringify(sanitizedData));
  } catch (error) {
    // remove 50 old event feedbacks from localStorage
    const updatedFeedback = Object.keys(sanitizedData || {})
      .slice(50)
      .reduce(
        (acc, eventId) =>
          sanitizedData?.[eventId]
            ? { ...acc, [eventId]: sanitizedData[eventId] }
            : acc,
        {}
      );
    localStorage.setItem(CMP_FEEDBACK, JSON.stringify(updatedFeedback));
    showToaster({
      message:
        'Browser storage full. Removed older feedbacks to save new one(s).',
      type: 'warning',
      placement: 'left',
    });
  }
};

// util to group schedule events returned from API response by year
// if no events available, return empty array for that year - to prevent further fetching
export const formatScheduleEventsInStore = (
  events: CrewChangeEventSummary[],
  inputDates: InputDates
) => {
  const groupedEvents = groupBy(events, ({ eventDate }) =>
    getYearFromDate(eventDate)
  );
  return reduce(
    inputDates,
    (result, dateStr) => {
      const year = getYearFromDate(dateStr);
      return { ...result, [year]: groupedEvents[year] || [] };
    },
    {}
  );
};

// this util fetches all events from remaining months of current year
// & all events of entire next year
export const getCMPScheduleEvents: GetCMPScheduleEvents =
  (dispatch: AppDispatch) =>
  async ({ year, inputDates }) => {
    // set `fromDate` to start of current month
    // & `toDate` to a year from `fromDate`
    const currentYear = getYearFromDate();
    const dates =
      inputDates ||
      formatScheduleReqDates(currentYear !== year ? year : undefined);
    const { fromDate, toDate } = dates;
    const response = await fetchMatrixScheduleEvents(fromDate, toDate);
    const { success, message, result: crewChangeEvents = [] } = response;

    // show failed message
    if (!success) {
      showToaster({
        message: `${message} (For ${year || currentYear})`,
        type: 'error',
      });
      dispatch(addScheduleEvents(null));
      return;
    }

    // store events based on the year of the event
    dispatch(
      addScheduleEvents(formatScheduleEventsInStore(crewChangeEvents, dates))
    );

    const nextYear = getYearFromDate(toDate);
    const monthsReaminingForNextYear =
      currentYear + 1 === nextYear && new Date(toDate).getMonth() < 11;

    // if there's remaining months of next year when fetching schedule events
    // make api call again to have all events of next year
    if (monthsReaminingForNextYear) {
      const newMonthIndex = new Date(toDate).getMonth();
      const newInputDates = {
        fromDate: `${nextYear}-${('0' + (newMonthIndex + 1)).slice(
          -2
        )}-01T00:00:00.000Z`,
        toDate: moment(toDate).endOf('year').toISOString(),
      };
      // call this util again with new input dates
      const fetchRemainingEvents = getCMPScheduleEvents(dispatch);
      fetchRemainingEvents({ inputDates: newInputDates });
    }
  };

// get the num of onsigner & offsigner counts update event update
export const getCrewCounts = (event: CrewChangeEventDetailed) => {
  const { offsigners } = event;
  return {
    numOffsigners: offsigners.filter(({ isDummy }) => !isDummy).length,
    numOnsigners: offsigners.filter(({ selectedOnsigner }) => selectedOnsigner)
      .length,
  };
};

// update already stored schedule event OR add the newly created event
// to schedule events list in store
export const updateEventInStore = (
  storedEvents: CrewChangeEventSummary[],
  updatedEvent: CrewChangeEventDetailed
) => {
  // find if the event is in the store already
  const eventInStore = storedEvents.find(({ id }) => id === updatedEvent.id);
  // if already in the store, update the event in store
  return eventInStore
    ? storedEvents.map((item) =>
        item.id === updatedEvent.id
          ? // merge summary & detailed events
            omit({ ...item, ...updatedEvent, ...getCrewCounts(updatedEvent) }, [
              'offsigners',
              'onboard',
            ])
          : item
      )
    : // otherwise, add the event to store
      // this use case is currently for added empty events by user
      [
        ...storedEvents,
        omit({ ...updatedEvent, ...getCrewCounts(updatedEvent) }, [
          'offsigners',
          'onboard',
        ]),
      ];
};
