import keys from 'lodash/keys';
import pick from 'lodash/pick';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';
import moment from 'moment';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import type {
  CrewChangePlanSummary,
  CrewChangePlanSummaryPort,
  CrewChangePlanUserNotes,
} from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { getFlightReqTime } from 'lib/common';
import { getRestrictions } from 'api/flotilla';
import { formatDate } from 'utils/format-date';
import { HotelsResponse, PlanningData } from 'utils/types';
import { Port } from 'utils/types/crew-change-types';
import { findLowestHotelCost } from './costs';
import { getLocodeKeyDetails } from './common';
import { getReqFromId, totalFlightCost as getTotalFlightCost } from './flights';
import {
  ActiveFlight,
  CCExtraCostFunc,
  DuplicateReadOnlyPort,
  FlightFilters,
  PortParams,
  ReadOnlyFlight,
  ReadOnlyPort,
  ReadOnlyPortRestrictions,
  RoutePort,
  SaveableCCReportData,
  SavedCrewChangePlan,
} from '../types';

type CCPanelDetails = {
  event: CrewEvent;
  planningData: PlanningData;
  portFilters: PortParams;
  allFilters: { [locodeKey: string]: FlightFilters };
  hotelResults: { [portLocode: string]: HotelsResponse } | null;
};

type ReadOnlyFlights = { [locodeKey: string]: ReadOnlyFlight[] };

// skip these fields as these are readonly
export const SKIPPED_PORT_FIELDS = ['costs', 'country'] as const;

export const READ_ONLY_PORT_FIELDS = [
  'id',
  'locode',
  'name',
  'eta',
  'etd',
  'calculatedDistance',
  'deviationTimeDifference',
  'lat',
  'lng',
  'isPartOfRoute',
  'distanceKM',
  'daysFromVessel',
  'restrictionsAvailable',
  ...[...SKIPPED_PORT_FIELDS],
] as const;

export const READ_ONLY_FLIGHT_FIELDS = [
  'id',
  'originalId',
  'requestId',
  'fetchedAt',
  'airports',
  'arrival',
  'departure',
  'price',
  'totalFlightTime',
  'stops',
  'segments',
  'flightNumbers',
  'fareInformation',
  'totalCO2',
  'source',
] as const;

export const READ_ONLY_FLIGHT_CREW_FIELDS = [
  'id',
  'name',
  'rank',
  'type',
  'country',
  'homeAirport',
] as const;

export const READ_ONLY_FLIGHT_PORT_FIELDS = [
  'id',
  'locode',
  'eta',
  'etd',
] as const;

export const READ_ONLY_FLIGHT_FILTERS = [
  'locode',
  'duplicate',
  'time',
  'layover',
  'stopsCount',
  'type',
  'source',
  'fareType',
  'departures',
  'arrivalTime',
  'departureTime',
  'airlines',
  'selectedStops',
  'range',
  'hotelCostDelay',
  'allowAirportTransfer',
] as const;

export const READ_ONLY_VESSEL_FIELDS = [
  'id',
  'imo',
  'name',
  'lat',
  'lng',
] as const;

export const READ_ONLY_CREW_EVENT_FIELDS = [
  'id',
  'name',
  'locode',
  'onsigners',
  'offsigners',
  'eta',
  'etd',
  'crew',
] as const;

type PartialPortSummary = {
  totalCost: number;
  sources: string[];
};

const getReadOnlyPortRestrictions = async (
  port: Port
): Promise<ReadOnlyPortRestrictions> => {
  const { locode, restrictionsAvailable } = port;

  if (!restrictionsAvailable?.length) {
    return null;
  }

  const sources = restrictionsAvailable!.map(({ source }) => source);
  const responses = await Promise.all(
    sources.map(async (source) => {
      const { data } = await getRestrictions({
        type: 'port',
        source,
        portOrCountryCode: locode,
      });
      const { restrictionsData } = data || {};
      return { source, restrictionsData };
    })
  );

  return responses.reduce(
    (acc, { source, restrictionsData }) => ({
      ...acc,
      ...(source && restrictionsData ? { [source]: restrictionsData } : {}),
    }),
    {} as ReadOnlyPortRestrictions
  );
};

// crew-change planning summary
const prepareReportSummary = (
  savedCCPlan: SavedCrewChangePlan,
  getExtraCosts: CCExtraCostFunc
): CrewChangePlanSummary => {
  const { crew, ports, flights } = savedCCPlan;
  // format to an object with original locode as key
  // first available value from `flights` in key-value pair
  const formattedFlights = Object.keys(flights).reduce<{
    [locode: string]: ReadOnlyFlight[];
  }>((acc, locodeKey) => {
    const { locode: originalLocode } = getLocodeKeyDetails(locodeKey);
    return acc[originalLocode]
      ? acc
      : { ...acc, [originalLocode]: flights[locodeKey] };
  }, {});
  const [onsigners, offsigners] = partition(
    crew,
    ({ type }) => type.toLocaleLowerCase() === 'onsigner'
  );
  const crewHomeAirports = crew.map(({ homeAirport }) => ({
    iataCode: homeAirport?.iataCode || '',
    name: homeAirport?.name || '',
  }));
  const summaryPorts: CrewChangePlanSummaryPort[] = ports.map(
    ({ locode, name }) => {
      const portFlights = formattedFlights[locode] || [];
      const totalFlightcost = Number(getTotalFlightCost(portFlights));
      const { totalCost, sources } = portFlights.reduce<PartialPortSummary>(
        (acc, flight) => {
          const currentCrew = crew.find((c) => c.id === flight.crew.id);
          const { total } = getExtraCosts(flight, currentCrew) || {};
          const additionalCost = (total?.wage || 0) + (total?.hotel || 0);
          return {
            totalCost: acc.totalCost + additionalCost,
            sources: uniq([...acc.sources, flight.source]),
          };
        },
        { totalCost: totalFlightcost, sources: [] }
      );
      return {
        locode,
        name,
        crewCompletion: portFlights.length,
        totalCost,
        travelSources: sources,
      };
    }
  );

  return {
    crew: {
      onsignerCount: onsigners.length,
      offsignerCount: offsigners.length,
      homeAirports: crewHomeAirports,
    },
    ports: summaryPorts,
  };
};

export const getSaveableCCReportData = async (
  ccPanelDetails: CCPanelDetails,
  vessel: Vessel,
  userNotes: CrewChangePlanUserNotes,
  getExtraCosts: CCExtraCostFunc
): Promise<SaveableCCReportData> => {
  const { event, portFilters, allFilters, planningData, hotelResults } =
    ccPanelDetails;
  const { crew, route, ports } = planningData;

  const readOnlyPorts: ReadOnlyPort[] = await Promise.all(
    ports.map(async (port) => {
      const {
        iataCode = '',
        name: airportName = '',
        address,
      } = port.selectedAirport || {};
      const restrictionsDetails = await getReadOnlyPortRestrictions(port);
      return {
        ...pick(port, [...READ_ONLY_PORT_FIELDS]),
        // only have `iataCode` & `name` fields from `selectedAirport`
        selectedAirport: {
          iataCode,
          name: airportName,
          cityName: address?.cityName || '',
        },
        // only have `count` & countries` field from `pastCrewChanges`
        pastCrewChanges: pick(port.pastCrewChanges, ['count', 'countries']),
        restrictions: restrictionsDetails,
        selected: true,
      };
    })
  );

  const readOnlyFlights = Object.keys(allFilters).reduce((acc, locodeKey) => {
    const { locode: portLocode } = getLocodeKeyDetails(locodeKey);
    const { confirmed: confirmedFlights, preferred } = allFilters[locodeKey];
    const { hotels = [] } = hotelResults?.[portLocode] || {};
    const updatedFlights = confirmedFlights.map((item) => ({
      ...pick(item, [...READ_ONLY_FLIGHT_FIELDS]),
      crew: pick(item.crew, [...READ_ONLY_FLIGHT_CREW_FIELDS]),
      port: pick(item.port, [...READ_ONLY_FLIGHT_PORT_FIELDS]),
      hotelCost: findLowestHotelCost(hotels || []),
      // insert filters to flight for read-only view
      filters: pick(item.filters, [...READ_ONLY_FLIGHT_FILTERS]),
      preferredLocodeKey: Boolean(preferred),
      confirmed: true,
    }));
    return { ...acc, [locodeKey]: updatedFlights };
  }, {} as ReadOnlyFlights);

  const readOnlyVessel = pick(vessel, [...READ_ONLY_VESSEL_FIELDS]);

  const readOnlyEvent = event.id
    ? pick(event, [...READ_ONLY_CREW_EVENT_FIELDS])
    : undefined;

  const crewChangePlan = {
    crew,
    route,
    flights: readOnlyFlights,
    ports: readOnlyPorts,
    vessel: readOnlyVessel,
    event: readOnlyEvent,
    portFilters,
  };

  return {
    crewChangePlan,
    summary: prepareReportSummary(crewChangePlan, getExtraCosts),
    userNotes,
  };
};

export const sanitizeOlderRoutePortsData = (routePorts: any[]): RoutePort[] =>
  routePorts.map((routePort) => {
    // This is from the new PortCallsV2Common type
    if (
      routePort.displayName !== undefined &&
      routePort.portLocode !== undefined
    ) {
      return {
        ...routePort,
        port: {
          name: routePort.displayName || null,
          locode: routePort.portLocode || null,
        },
      };
    }
    if (routePort.displayName !== undefined && routePort.port !== undefined) {
      return routePort;
    }
    return {
      ...routePort,
      port: {
        name: routePort.text || null,
        locode: routePort.locode || null,
      },
      displayName: routePort.text,
    };
  });

// prepare the read-only port cards in flights table for report view
export const getReadOnlyPortCards = (
  ports: ReadOnlyPort[],
  flights: {
    [locodeKey: string]: ReadOnlyFlight[];
  }
) =>
  keys(flights).reduce<DuplicateReadOnlyPort[]>((acc, locodeKey) => {
    const {
      locode: originalLocode,
      portETA: uniqETA,
      flightSource,
    } = getLocodeKeyDetails(locodeKey);
    const currentPort = ports.find(({ locode }) => locode === originalLocode);
    return currentPort
      ? [
          ...acc,
          {
            ...currentPort,
            flightSource,
            uniqETA,
            preferred: Boolean(flights[locodeKey][0]?.preferredLocodeKey),
          },
        ]
      : acc;
  }, []);

const commonReportTitle = (
  crewCount: number,
  ports: (ReadOnlyPort | CrewChangePlanSummaryPort)[]
) => {
  const portNames = ports.map(({ name }) => name).join(', ');
  return portNames
    ? `Crew Change for ${crewCount} at ${portNames}`
    : `Crew Change for ${crewCount}`;
};

// report title to show in the absence of saved one
export const getDefaultReportTitle = ({
  summary,
  createdAt,
  crewChangePlan,
}: {
  summary: CrewChangePlanSummary;
  createdAt: string;
  crewChangePlan?: SavedCrewChangePlan;
}) => {
  const {
    crew: { onsignerCount, offsignerCount },
    ports,
  } = summary;
  const summaryCrewCount = onsignerCount + offsignerCount;
  if (summaryCrewCount) {
    return commonReportTitle(summaryCrewCount, ports);
  }

  // title when crew change plans details are fetched
  if (crewChangePlan) {
    const { crew, ports } = crewChangePlan;
    return commonReportTitle(crew.length, ports);
  }

  return `Crew Change created at ${formatDate(createdAt)}`;
};

// determines the flight request details of a flight in saved report
// finds if it's eligible to fetch flights for available data in request details
// dates from past aren't allowed for fetching flights
export const getFlightReqDetails = (requestId: string | undefined) => {
  if (!requestId) {
    return {
      canUpdate: false,
      flightRequest: null,
    };
  }

  const { startDate, ...restReq } = getReqFromId(requestId);
  // const formattedCurrentTime = formatDate(startDate, 'YYYY/MM/DD');
  const formattedReqTime = new Date(
    formatDate(startDate, 'YYYY/MM/DD')
  ).toISOString();
  const canUpdate =
    moment().isSame(moment(formattedReqTime), 'day') ||
    moment(startDate).isAfter(moment());

  return {
    canUpdate,
    flightRequest: canUpdate
      ? { ...restReq, startDate: getFlightReqTime(startDate) }
      : null,
  };
};

// format a regular Flight type to a ReadOnlyFlight using current Filters
export const formatToReadOnlyFlight = (
  currentFlight: ReadOnlyFlight,
  newFlight: ActiveFlight,
  filters: FlightFilters
): ReadOnlyFlight => ({
  ...currentFlight,
  ...pick(newFlight, [...READ_ONLY_FLIGHT_FIELDS]),
  filters: pick(filters, [...READ_ONLY_FLIGHT_FILTERS]),
});
