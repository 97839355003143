import { memo, useCallback, useEffect, useState } from 'react';
import {
  Stack,
  Tab,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import styled from 'styled-components/macro';

import { useE2ETests } from 'hooks';
import { lightPurple, red, white, borderGray } from 'lib/colors';
import { isDevelopment } from 'lib/environments';
import { CCPanelCostParams } from 'redux/types';
import AwesomeSlider from 'components/shared/AwesomeSlider';
import {
  MAX_HOTEL_COST_DELAY,
  PORT_PRIORITIES,
} from 'components/CrewChangePanel/helpers';
import {
  CrewChangeCost,
  PortParams as CCPanelPortParams,
} from 'components/CrewChangePanel/types';

import { Button, SwitchV2, Tooltip } from 'components/shared';
import CostOptions, { UpdateCostParams } from './CostOptions';
import { CrewChangeTable } from '../Tables';
import {
  CustomCheckbox,
  Divider,
  ParamsWrapper,
  SliderWrapper,
  SliderLabel,
  ToggleWrapper,
  ToggleText,
  Section,
  Text,
  Title,
  ThumbComponent,
  InfoIcon,
} from '../../common';
import { CrewChangesProps } from '../../types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

const StyledParamsWrapper = styled(ParamsWrapper)`
  margin-top: 1rem;
  min-width: 280px;
  width: fit-content;
`;

const TabsWrapper = styled.div`
  margin-top: -4px;

  .MuiButtonBase-root {
    font-size: 0.7rem;
    font-weight: bold;
    font-family: HK Grotesk, Roboto;
    letter-spacing: 0.1rem;
  }
  .MuiTabPanel-root {
    margin: 0;
    padding: 0;
  }
`;

const StyledTab = styled(Tab)`
  &:hover {
    background: ${lightPurple};
    border-radius: 6px;
  }
`;

const ToggleButton = styled(Button)`
  margin-bottom: 0.25rem;
  margin-left: 0.5rem;
  width: fit-content;
  min-height: unset;
  min-width: unset;
  font-size: 0.725rem;
  font-weight: 500;
  letter-spacing: 0.033rem;
  background-color: ${white};
  border: 1px solid ${borderGray};
  padding: 0.1rem 0.33rem;
  color: ${red};
  background-color: ${red}20;
  border: 1px solid ${red};
`;

const StyledInfoIcon = styled(InfoIcon)`
  font-size: 18px !important;
`;

const agencyOptions = ['S5', 'WSS', 'ALL'];

function CrewChanges({ initialValues, updateSettings }: CrewChangesProps) {
  const { isE2ETesting } = useE2ETests();

  const {
    predictive,
    compact,
    showGuide,
    portParams: initialPortParams,
    costParams: initialCostParams,
  } = initialValues;
  const [tableState, setTableState] = useState({
    predictive,
    compact,
    showGuide,
  });
  const [portParams, setPortParams] =
    useState<CCPanelPortParams>(initialPortParams);
  const [costParams, setCostParams] =
    useState<CCPanelCostParams>(initialCostParams);
  const [activeTab, setActiveTab] = useState('compact');

  useEffect(() => {
    setActiveTab(tableState.compact ? 'compact' : 'standard');
  }, [tableState.compact]);

  const updateTableState = (type: 'predictive' | 'compact' | 'showGuide') => {
    const newTalbeState = { ...tableState, [type]: !tableState[type] };
    setTableState(newTalbeState);
    updateSettings((prevSettings) => ({
      ...prevSettings,
      crewChange: { ...prevSettings.crewChange, ...newTalbeState },
    }));
  };

  const updateAgency = (textContent: string) => {
    const newPortParams = {
      ...portParams,
      agency: (textContent || '')?.toLowerCase(),
    };
    setPortParams(newPortParams);
    updateSettings((prevSettings) => ({
      ...prevSettings,
      crewChange: { ...prevSettings.crewChange, portParams: newPortParams },
    }));
  };

  const updatePortSliderValue = useCallback(
    (type: 'range' | 'etaLimit') =>
      (event: Event, newValue: number | number[]) => {
        const newPortParams = { ...portParams, [type]: newValue as number };
        setPortParams(newPortParams);
        updateSettings((prevSettings) => ({
          ...prevSettings,
          crewChange: { ...prevSettings.crewChange, portParams: newPortParams },
        }));
      },
    [portParams, updateSettings]
  );

  const toggleUrgentPorts = () => {
    const newPortParams = {
      ...portParams,
      showUrgentPorts: !portParams.showUrgentPorts,
    };
    setPortParams(newPortParams);
    updateSettings((prevSettings) => ({
      ...prevSettings,
      crewChange: { ...prevSettings.crewChange, portParams: newPortParams },
    }));
  };

  const updatePriority = (
    value: string,
    deselectAll?: boolean // available for testing environment only
  ) => {
    const newPortParams = {
      ...portParams,
      priorities:
        (deselectAll && []) ||
        (portParams.priorities.includes(value)
          ? portParams.priorities.filter((priority) => priority !== value)
          : [...portParams.priorities, value]),
    };
    setPortParams(newPortParams);
    updateSettings((prevSettings) => ({
      ...prevSettings,
      crewChange: { ...prevSettings.crewChange, portParams: newPortParams },
    }));
  };

  const updateCostParams: UpdateCostParams = (type, newValue) => {
    const newCostParams = {
      ...costParams,
      [type]:
        type === 'costOptions'
          ? (newValue as CrewChangeCost[])
          : (newValue as number),
    };
    setCostParams(newCostParams);
    updateSettings((prevSettings) => ({
      ...prevSettings,
      crewChange: {
        ...prevSettings.crewChange,
        costParams: newCostParams,
      },
    }));
  };

  return (
    <>
      <Section>
        <Title data-id="crew-change-options">Crew Change</Title>
        <Text>Manage the details to show on your crew change panel.</Text>
        {/* TODO: enable it when route editing feature is done */}
        <CheckboxWrapper>
          {isDevelopment && (
            <CustomCheckbox
              isCircle={false}
              label="Include predictive ports in crew change planning"
              checked={tableState.predictive}
              onClick={() => updateTableState('predictive')}
            />
          )}
          <CustomCheckbox
            data-id="settings_compact-option"
            isCircle={false}
            label="Compact table rows"
            checked={tableState.compact}
            onClick={() => updateTableState('compact')}
          />
          <CustomCheckbox
            data-id="settings_guide-option"
            isCircle={false}
            label="Show help text in panel"
            checked={tableState.showGuide}
            onClick={() => updateTableState('showGuide')}
          />
        </CheckboxWrapper>
      </Section>

      <Divider />

      <Section>
        <Title data-id="crew-change-sample-table">Table Row Preview</Title>
        <TabsWrapper>
          <TabContext value={activeTab}>
            <FlexWrapper>
              <TabList
                onChange={(_: any, newValue: string) => setActiveTab(newValue)}
              >
                <StyledTab label="Compact" value="compact" />
                <StyledTab label="Standard" value="standard" />
              </TabList>
            </FlexWrapper>
            <TabPanel value="compact">
              <CrewChangeTable isCompact />
            </TabPanel>
            <TabPanel value="standard">
              <CrewChangeTable isCompact={false} />
            </TabPanel>
          </TabContext>
        </TabsWrapper>
      </Section>

      <Divider />

      <Section>
        <Title data-id="settings_port-params">Port Parameters</Title>

        <SliderLabel>Enable to show only vessel-nearby ports</SliderLabel>
        <ToggleWrapper>
          <SwitchV2
            className="e2e_closest-ports-settings"
            checked={portParams.showUrgentPorts}
            onChange={toggleUrgentPorts}
          />
          <ToggleText>Show Closest Ports</ToggleText>
        </ToggleWrapper>

        <StyledParamsWrapper>
          <SliderWrapper $port>
            <SliderLabel>Distance from vessel route to port (NM)</SliderLabel>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{ ml: 2.5 }}
            >
              <AwesomeSlider
                max={200}
                step={1}
                size="small"
                value={portParams.range}
                onChange={updatePortSliderValue('range')}
                components={{ Thumb: ThumbComponent }}
              />
            </Stack>
          </SliderWrapper>

          <SliderWrapper $port>
            <FlexWrapper>
              <SliderLabel>Set ETA time limit (in weeks) for ports</SliderLabel>
              <Tooltip content="Ports with ETA beyond this time limit will NOT be available">
                <FlexWrapper>
                  <StyledInfoIcon />
                </FlexWrapper>
              </Tooltip>
            </FlexWrapper>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{ ml: 2.5 }}
            >
              <AwesomeSlider
                step={1}
                min={2}
                max={12}
                size="small"
                value={portParams.etaLimit}
                onChange={updatePortSliderValue('etaLimit')}
                components={{ Thumb: ThumbComponent }}
              />
            </Stack>
          </SliderWrapper>

          <SliderWrapper>
            <FlexWrapper>
              <SliderLabel>Port Selection Priority</SliderLabel>
              {isE2ETesting && (
                <ToggleButton
                  className="e2e_deselect_all_priorities"
                  onClick={() => updatePriority('', true)}
                >
                  Deselect All
                </ToggleButton>
              )}
            </FlexWrapper>
            <CheckboxWrapper>
              {PORT_PRIORITIES.map((priority, idx) => (
                <CustomCheckbox
                  key={idx}
                  isCircle={false}
                  data-id={`e2e_port-priority-${priority}`}
                  checked={portParams.priorities.includes(priority)}
                  onClick={() => updatePriority(priority)}
                  label={priority}
                />
              ))}
            </CheckboxWrapper>
          </SliderWrapper>

          <FormControl data-id="e2e_port-agency">
            <InputLabel id="agency-label">Agency</InputLabel>
            <Select
              labelId="agency-label"
              size="small"
              label="Agency"
              placeholder="Select port agency"
              value={portParams.agency?.toUpperCase?.()}
              onChange={({ target }) => updateAgency(target.value)}
            >
              {agencyOptions.map((text) => (
                <MenuItem key={text} value={text}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledParamsWrapper>
      </Section>

      <Divider />

      <Section>
        <Title data-id="settings_additional-cost">Cost Parameters</Title>
        <StyledParamsWrapper>
          <CostOptions
            options={costParams.costOptions}
            updateCostParams={updateCostParams}
          />
        </StyledParamsWrapper>
        <StyledParamsWrapper>
          <SliderWrapper>
            <FlexWrapper>
              <SliderLabel>Time difference for hotel cost (Hours)</SliderLabel>
              <Tooltip content="Difference between crew-change & flight time to include hotel cost">
                <FlexWrapper>
                  <StyledInfoIcon />
                </FlexWrapper>
              </Tooltip>
            </FlexWrapper>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{ ml: 2 }}
            >
              <AwesomeSlider
                min={0}
                size="small"
                step={1}
                max={MAX_HOTEL_COST_DELAY}
                value={costParams.hotelCostDelay}
                onChange={(_, value) =>
                  updateCostParams('hotelCostDelay', value)
                }
                components={{ Thumb: ThumbComponent }}
              />
            </Stack>
          </SliderWrapper>
        </StyledParamsWrapper>
      </Section>
    </>
  );
}

export default memo(CrewChanges);
