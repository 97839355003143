import uniq from 'lodash/uniq';
import { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Stack,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import styled from 'styled-components/macro';

import { trackUserAction } from 'lib/amplitude';
import { CCPanelFlightParams, RootState } from 'redux/types';
import {
  TRACK_FLIGHT_PARAMS_LAYOVER_TIME,
  TRACK_FLIGHT_PARAMS_QUICKFLY_TMC,
  TRACK_FLIGHT_PARAMS_STOPS_COUNT,
  TRACK_FLIGHT_PARAMS_TOTAL_TIME,
} from 'utils/analytics/constants';
import AwesomeSlider from 'components/shared/AwesomeSlider';

import { SwitchV2, Tooltip } from 'components/shared';
import {
  Divider,
  ParamsWrapper,
  Section,
  SliderWrapper,
  SliderLabel,
  ToggleWrapper,
  ToggleText,
  Title,
  InfoIcon,
  ThumbComponent,
} from '../common';
import { FlightSearchProps } from '../types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledParamsWrapper = styled(ParamsWrapper)`
  margin-top: 1rem;
  min-width: 280px;
  width: fit-content;
`;

type CommonParamType = 'time' | 'layover' | 'stops';

const commonParamActions: { [key in CommonParamType]: string } = {
  time: TRACK_FLIGHT_PARAMS_TOTAL_TIME,
  layover: TRACK_FLIGHT_PARAMS_LAYOVER_TIME,
  stops: TRACK_FLIGHT_PARAMS_STOPS_COUNT,
};

function FlightSearch({
  initialValues: initialFlightParams,
  quickFlyTMC: initialQuickFlyTMC,
  updateSettings,
}: FlightSearchProps) {
  const userInfo = useSelector(({ settings }: RootState) => settings.userInfo);

  const [quickFlyTMC, setQuickFlyTMC] = useState(initialQuickFlyTMC);
  const [flightParams, setFlightParams] =
    useState<CCPanelFlightParams>(initialFlightParams);

  const flightSources = useMemo(
    () => uniq([...(userInfo?.allowedTravelVendors || []), 'ALL']),
    [userInfo]
  );

  const toggleAirportTransfer = useCallback(() => {
    const newFlightParams = {
      ...flightParams,
      allowAirportTransfer: !flightParams.allowAirportTransfer,
    };
    setFlightParams(newFlightParams);
    updateSettings((prevSettings) => ({
      ...prevSettings,
      crewChange: { ...prevSettings.crewChange, flightParams: newFlightParams },
    }));
  }, [flightParams, updateSettings]);

  const updateFlightParams = useCallback(
    (type: CommonParamType, newValue: number | number[]) => {
      const newFlightParams = { ...flightParams, [type]: newValue };
      setFlightParams(newFlightParams);
      updateSettings((prevSettings) => ({
        ...prevSettings,
        crewChange: {
          ...prevSettings.crewChange,
          flightParams: newFlightParams,
        },
      }));
      // track change of flight params to amplitude
      trackUserAction(commonParamActions[type], 'click', { [type]: newValue });
    },
    [flightParams, updateSettings]
  );

  const updateQuickflyTMC = useCallback(
    (newTMC: string) => {
      setQuickFlyTMC(newTMC);
      updateSettings((prevSettings) => ({
        ...prevSettings,
        quickFlyTMC: newTMC,
      }));
      // track change of quickfly TMC
      trackUserAction(TRACK_FLIGHT_PARAMS_QUICKFLY_TMC, 'click', {
        quickFlyTMC: newTMC,
      });
    },
    [updateSettings]
  );

  return (
    <>
      <Section>
        <FlexWrapper>
          <Title data-id="common_flight-params">Common Search Parameters</Title>
          <Tooltip content="Flight search settings applied to both Crew Change Panel & QuickFly.">
            <InfoIcon />
          </Tooltip>
        </FlexWrapper>

        <SliderLabel>
          Enable to allow transfer between airports for crew flights
        </SliderLabel>
        <ToggleWrapper>
          <SwitchV2
            className="e2e_toggle-airport-transfer"
            checked={flightParams.allowAirportTransfer}
            onChange={toggleAirportTransfer}
          />
          <ToggleText>Transfer between Airports</ToggleText>
        </ToggleWrapper>

        <StyledParamsWrapper>
          <SliderWrapper>
            <SliderLabel>Total journey time (Hours)</SliderLabel>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{ ml: 2 }}
            >
              <AwesomeSlider
                max={72}
                value={flightParams.time}
                step={1}
                size="small"
                onChange={(_, value) => updateFlightParams('time', value)}
                components={{ Thumb: ThumbComponent }}
              />
            </Stack>
          </SliderWrapper>

          <SliderWrapper>
            <SliderLabel>Total layover time (Hours)</SliderLabel>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{ ml: 2 }}
            >
              <AwesomeSlider
                size="small"
                min={0}
                max={36}
                step={0.5}
                value={flightParams.layover}
                onChange={(_, value) => updateFlightParams('layover', value)}
                components={{
                  Thumb: (props: any) => <ThumbComponent range {...props} />,
                }}
              />
            </Stack>
          </SliderWrapper>

          <SliderWrapper>
            <SliderLabel>Number of stops (Transits)</SliderLabel>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{ ml: 1.5 }}
            >
              <AwesomeSlider
                max={6}
                value={flightParams.stops}
                step={1}
                size="small"
                onChange={(_, value) => updateFlightParams('stops', value)}
                components={{ Thumb: ThumbComponent }}
              />
            </Stack>
          </SliderWrapper>
        </StyledParamsWrapper>
      </Section>

      <Divider />

      <Section>
        <Title data-id="quickfly-tmc-params">QuickFly Preferred TMC</Title>
        <SliderLabel style={{ marginBottom: '1.25rem' }}>
          Select a Travel Management Company (TMC) for QuickFly
        </SliderLabel>

        <div />

        <StyledParamsWrapper>
          <FormControl>
            <InputLabel id="preferred-tmc">Preferred TMC</InputLabel>
            <Select
              labelId="preferred-tmc"
              size="small"
              label="Preferred TMC"
              placeholder="Select QuickFly TMC"
              value={quickFlyTMC || 'ALL'}
              onChange={({ target }) => updateQuickflyTMC(target.value)}
            >
              {flightSources.map((text) => (
                <MenuItem key={text} value={text}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledParamsWrapper>
      </Section>
    </>
  );
}

export default memo(FlightSearch);
