import { memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCrewChangePanel, selectSettings } from 'redux/selectors';
import { RootState } from 'redux/types';
import { Crew } from 'utils/types/crew-change-types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import DelayComponent from './Delay';
import TotalCostComponent from './TotalTravelCost';
import AdditionalCostComponent from './AdditionalCost';
import {
  getTravelCost,
  includeHotelCost,
  updateFlightPortDetails,
} from '../../../helpers';
import { ActiveFlight, FlightRow, ReadOnlyFlight } from '../../../types';

type Props = {
  flightDetails: FlightRow | ReadOnlyFlight;
  type: 'additionalCost' | 'delay' | 'totalCost';
};

function EventDetails({ flightDetails, type }: Props) {
  const { crew: selectedCrew, port } = flightDetails;
  const hotelResults = useSelector(
    ({ crewChangeResources }: RootState) => crewChangeResources.hotelResults
  );
  const { active: activity, readOnlyPlanningData: reportData } = useSelector(
    selectCrewChangePanel
  );
  const {
    crewChange: {
      costParams: { costOptions, hotelCostDelay },
    },
  } = useSelector(selectSettings);
  const { filters } = useContext(CCPanelContext);

  const updatePortDates = useMemo(
    () => updateFlightPortDetails(filters),
    [filters]
  );

  if (!selectedCrew || !port) {
    return <span>-</span>;
  }

  const getExtraCosts = getTravelCost(costOptions);
  const DetailComponent =
    type === 'delay'
      ? DelayComponent
      : (type === 'additionalCost' && AdditionalCostComponent) ||
        TotalCostComponent;

  if (activity === 'readOnly' && reportData) {
    const { crew: crewList } = reportData.crewChangePlan;
    const { filters } = flightDetails as ReadOnlyFlight;
    // find the crew to get full crew data
    const crew = crewList.find((c) => c.id === selectedCrew.id);
    const costDetails = getExtraCosts(updatePortDates(flightDetails), crew);
    return (
      <DetailComponent
        details={costDetails}
        filters={filters}
        crewType={crew!.type}
      />
    );
  }

  const { hotels = [] } = hotelResults?.[port.locode] || {};
  const crewInPlanning = selectedCrew as Crew;
  const updatedFlight = includeHotelCost(
    updatePortDates(flightDetails) as ActiveFlight,
    hotels
  );
  const costDetails = getExtraCosts(updatedFlight, crewInPlanning);
  return (
    <DetailComponent
      details={costDetails}
      filters={{ hotelCostDelay }}
      crewType={crewInPlanning.type}
    />
  );
}

export default memo(EventDetails);
