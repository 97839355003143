import every from 'lodash/every';
import find from 'lodash/find';
import get from 'lodash/get';
import values from 'lodash/values';
import partition from 'lodash/partition';
import { SyntheticEvent } from 'react';
import type { Dispatch } from '@reduxjs/toolkit';
import moment from 'moment';
import type { Layover as FlightStop } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

import { CC_PANEL_HEIGHT, CC_PANEL_INITIAL_HEIGHT } from 'lib/constants';
import {
  resetCCPanelRequest,
  resetCCPanelResponses,
  setCCPanelHotels,
  setVesselJourney,
} from 'redux/actions';
import { initialTimeAndStopsCount } from 'redux/helpers/flotillaSearch';
import { CrewChangeStep } from 'utils/types';
import { Flight, Port } from 'utils/types/crew-change-types';

import { clearStoredCrewList } from './crew';
import {
  DEFAULT_ETA_LIMIT,
  DEFAULT_FLIGHT_TIME,
  DEFAULT_HOTEL_COST_DELAY,
  DEFAULT_LAYOVER_RANGE,
  DEFAULT_PRIORITIES,
  DEFAULT_STOPS_COUNT,
} from './constants';
import {
  ActiveFlight,
  ArrangePortPopupsInput,
  CrewChangeCost,
  FareType,
  FetchStatus,
  FlightFilters,
  FlightRow,
  Layover,
  LocodeDetails,
  PopupPort,
  PortParams,
  ReadOnlyFlight,
  ReadOnlyPortCardDetails,
  StepDetails,
  StepDetailsInput,
  TableState,
} from '../types';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import AnchorRoundedIcon from '@mui/icons-material/AnchorRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';

// the step details shown in table header & control sections
export const getStepDetails = ({
  canSelectAllCrew,
  canSelectTop3,
  view,
}: StepDetailsInput = {}): StepDetails => ({
  crew: {
    header: 'Add / Select Crew',
    instructions:
      'Guide: Add and select crew. Double click in cells to update crew info',
    nextButton: canSelectAllCrew ? 'Select All' : 'Review Route',
    icon: canSelectAllCrew ? GroupsRoundedIcon : undefined,
  },
  route: {
    header: 'Route',
    instructions: 'Guide: Update vessel route',
    prevButton: 'back',
    nextButton: 'Select Ports',
  },
  ports: {
    header: 'Ports',
    instructions: 'Guide: Select ports to shortlist',
    prevButton: 'back',
    nextButton: canSelectTop3 ? 'Select Top 3' : 'Find Flights',
    icon: canSelectTop3 ? AnchorRoundedIcon : undefined,
  },
  flights: {
    header: 'Flights',
    instructions:
      'Guide: Confirm flights for each crew member, and send emails to port agents',
    icon: view === 'filter' ? ListAltRoundedIcon : IosShareRoundedIcon,
    nextButton: view === 'filter' ? 'Compare Flights' : 'Share Planning Data',
    prevButton: 'back',
  },
});

export const steps: CrewChangeStep[] = ['crew', 'route', 'ports', 'flights'];

// crew-change cost selection options
export const COST_OPTIONS: CrewChangeCost[] = [
  'Flight',
  'Agency',
  'Wage',
  'Hotel',
];

export const DEFAULT_COST_OPTIONS: CrewChangeCost[] = [
  'Flight',
  'Agency',
  'Wage',
  'Hotel',
];

export const findStepIndex = (step: CrewChangeStep) =>
  steps.findIndex((text) => text === step);

export const initialCrewAddStatus = {
  addingId: false,
  isSelecting: false,
};

export const initialPlanningData = {
  crew: [],
  route: [],
  ports: [],
  flights: [],
};

export const initialTableState: TableState = {
  collapse: false,
  // selected: false,
  step: 'crew' as CrewChangeStep,
  view: 'filter',
  summaryCollapse: true,
  readOnlyDetails: { locode: '' },
  budgetDetails: null,
};

export const initialFetchStatus: FetchStatus = {
  progress: null,
  initialized: false,
};

export const initialPortParams: PortParams = {
  unit: 'NM',
  range: 5, // NM
  agency: 'ALL',
  priorities: DEFAULT_PRIORITIES,
  showUrgentPorts: false,
  etaLimit: DEFAULT_ETA_LIMIT,
};

export const initialFlightFilters: FlightFilters = {
  active: false,
  type: 'Cheapest',
  time: DEFAULT_FLIGHT_TIME,
  layover: DEFAULT_LAYOVER_RANGE,
  stopsCount: DEFAULT_STOPS_COUNT,
  source: 'ALL',
  locode: '',
  portAirport: '',
  fareType: FareType.marine,
  confirmed: [],
  departures: null,
  airlines: [],
  selectedStops: [],
  arrivalTime: [0, 24],
  departureTime: [0, 24],
  emailSent: false,
  hotelCostDelay: DEFAULT_HOTEL_COST_DELAY,
  range: initialTimeAndStopsCount,
  allowAirportTransfer: false,
  settingsLoaded: false,
};

export const initialPagination = { hasMore: false, page: 1 };

export let ACTION_TIMES: { [action: string]: { time: number; show: boolean } } =
  {};

export const resetActionTimes = () => {
  ACTION_TIMES = {};
};

// break down details from `locodeKey`
export const getLocodeKeyDetails = (locodeKey: string): LocodeDetails => {
  const [customLocodeKey, flightSource] = locodeKey.split('--');
  const [locode, etaStr = ''] = customLocodeKey.split('(');
  const [portETA] = etaStr.split(')');
  return { locode, portETA, flightSource };
};

export const formatInputDate = (dateStr: string | undefined) => {
  const format = 'DD/MM/YY';
  const formattedDate = moment(dateStr, format, true);
  return formattedDate.isValid()
    ? moment(formattedDate).toISOString()
    : undefined;
};

export const setActionTime = (
  type: CrewChangeStep | 'emails',
  action: 'start' | 'end',
  reset?: boolean
) => {
  const time = performance.now();
  if (action === 'start') {
    const timeRequired = reset
      ? time
      : Math.round((ACTION_TIMES[type]?.time || 0) + time);
    ACTION_TIMES[type] = { time: timeRequired, show: false };
  } else {
    const timeRequired = Math.abs(
      Math.round(time - (ACTION_TIMES[type]?.time || 0))
    );
    ACTION_TIMES[type] = { time: timeRequired, show: true };
  }
};

export const toggleTableCollapse = (collapsed: boolean) => {
  const panel = document.getElementById('resizable');
  if (panel) {
    if (collapsed) {
      localStorage.setItem(CC_PANEL_HEIGHT, `${panel.offsetHeight}px`);
    } else {
      const prevPanelHeight = localStorage.getItem(CC_PANEL_HEIGHT);
      panel.style.height = prevPanelHeight || `${CC_PANEL_INITIAL_HEIGHT}px`;
    }
  }
};

export const scrolledToEnd = (event: SyntheticEvent) => {
  const { currentTarget: node } = event;
  return node.scrollTop + node.clientHeight === node.scrollHeight;
};

export const triggerCCPanelCloseActions = (closing?: boolean) => {
  clearStoredCrewList(); // clear stored crew in memory
  resetActionTimes();
  if (closing) {
    // remove panel previous height
    localStorage.removeItem(CC_PANEL_HEIGHT);
  }
};

export const getLayovers = (flight: Flight | ActiveFlight | ReadOnlyFlight) => {
  const { airports = {}, stops = [] } = flight;
  return stops.reduce(
    (acc: Layover, layover: FlightStop) => {
      const { airportCode, depAirportCode = '', layoverMs = 0 } = layover;
      if (!airportCode) return acc;
      // include the airport code from stops which is present in `airports` object
      const newLayoverNameArr =
        (airports[airportCode] &&
          airports[depAirportCode] && [`${airportCode}-${depAirportCode}`]) ||
        (airports[airportCode] && [airportCode]) ||
        (airports[depAirportCode] && [depAirportCode]) ||
        [];
      return {
        airports: [...acc.airports, ...newLayoverNameArr],
        time: acc.time + layoverMs / (3600 * 1000),
      };
    },
    { airports: [], time: 0 }
  );
};

// find active port filters & locode without the locode
export const getCurrentFilters = (filters: {
  [locodeKey: string]: FlightFilters;
}): { filters: FlightFilters | undefined; locodeKey: string | undefined } => {
  const currentFilters = Object.values(filters).find(({ active }) => active);
  if (!currentFilters) {
    return { filters: currentFilters, locodeKey: undefined };
  }
  const { locode, source, duplicate, uniqETA } = currentFilters;
  const baseLocode = uniqETA ? `${locode}(${uniqETA})` : locode;
  return {
    filters: currentFilters,
    locodeKey: duplicate ? `${baseLocode}--${source}` : baseLocode,
  };
};

export const resetCCPanelResources = (dispatch: Dispatch) => {
  dispatch(resetCCPanelRequest());
  // setting all crew change responses to `null`
  dispatch(resetCCPanelResponses());
  // remove vessel journey in `crewChangePanel.futureRoute`
  dispatch(setVesselJourney([]));
  // remove all stored hotels
  dispatch(setCCPanelHotels(null));
};

export const getFlightResultsModalWidth = () =>
  window.innerWidth >= 1080 ? 900 : window.innerWidth * (4 / 5);

// prepare filter details for port-card in report view
const prepareReadOnlyFilters = (details: ReadOnlyPortCardDetails) => {
  const { locode, flightSource, uniqETA } = details;
  const filters = {
    locode,
    uniqETA,
    source: flightSource,
    duplicate: Boolean(flightSource),
  };
  return { filters };
};

// arrange port popups based on distance & active port (if available)
export const arrangePortPopups = ({
  ports,
  allFilters,
  readOnlyDetails,
}: ArrangePortPopupsInput): PopupPort[] => {
  const { locode: readOnlyLocode } = readOnlyDetails || {};
  const activeLocode =
    readOnlyLocode || getCurrentFilters(allFilters).filters?.locode;

  let sortedPorts = ports.sort((a, b) => b.distanceKM - a.distanceKM);
  // find filters for settings duplicate cards
  const { filters: currentFilters } = readOnlyDetails
    ? prepareReadOnlyFilters(readOnlyDetails)
    : getCurrentFilters(allFilters);

  // insert `flightSource` & `uniqETA` for duplicated pop-up ports
  if (currentFilters?.duplicate || currentFilters?.uniqETA) {
    const { locode, source: flightSource, uniqETA } = currentFilters;
    sortedPorts = sortedPorts.map((port) => ({
      ...port,
      ...(locode === port.locode
        ? {
            // pass specific `flightSource` to show in the pop-up card title
            flightSource:
              (flightSource || '').toUpperCase() !== 'ALL'
                ? flightSource
                : undefined,
            uniqETA,
          }
        : {}),
    }));
  }

  if (!activeLocode) {
    return sortedPorts;
  }

  const [activePorts, others] = partition(
    sortedPorts,
    ({ locode }) => locode === activeLocode
  );
  // place currently active port at first for prominence of map popup
  return activePorts.length ? [...activePorts, ...others] : sortedPorts;
};

// check if all port flights are fetched
export const allFlightsFetched = (
  progress: {
    [locodeKey: string]: number;
  } | null
) => progress && every(progress, (value) => value === 1);

// Search tag for the first tag that contains a preferred TMC value, otherwise default to ALL
export const getDefaultTMC = (vessel: Vessel | null | undefined) => {
  const matchedProperty = 'tagRules.preferredTmc';
  const { vesselTags = [] } = vessel || {};
  return get(find(vesselTags, matchedProperty), matchedProperty, 'ALL');
};

// insert port details into flight for to cover multiple port ETA situations
// since the fetched flights have original port details inserted
// also, `ports` in planningData do not contain duplicates of same port
export const updateFlightPortDetails =
  (filters: { [locodeKey: string]: FlightFilters }) =>
  (flight: FlightRow | ReadOnlyFlight) => {
    const { portDates } = find(values(filters), ['active', true]) || {};
    return flight.port
      ? {
          ...flight,
          port: { ...flight.port, ...(portDates || {}) } as Port,
        }
      : flight;
  };
