import uniqBy from 'lodash/uniqBy';
import type {
  CrewChangePlanWithDetailsResp,
  SavedCrewChangePlanResponse,
} from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';

import { UserInfo } from 'utils/types';

// format existing vessel plans with the newly created one to update store
export const formatVesselPlansOnSave = (
  newResponse: CrewChangePlanWithDetailsResp,
  savedPlansResponse: SavedCrewChangePlanResponse | undefined,
  userInfo?: UserInfo
) => {
  const { data: storedPlans = [], meta } = savedPlansResponse || {};
  const { crewChangePlan, ...summary } = newResponse;
  const newSummary = {
    ...summary,
    ...(userInfo
      ? {
          creator: {
            id: summary.creatorId,
            email: userInfo.loginEmail,
            name: userInfo.firstname || '',
          },
        }
      : {}),
  };
  return {
    meta: meta || {
      itemsPerPage: 15, // default value
      totalItems: 1,
      currentPage: 1,
      totalPages: 1,
    },
    data: uniqBy([newSummary, ...storedPlans], 'uuid'),
    newlySaved: summary.uuid,
  };
};
