import filter from 'lodash/filter';
import keys from 'lodash/keys';
import includes from 'lodash/includes';
import isNumber from 'lodash/isNumber';
import map from 'lodash/map';
import partition from 'lodash/partition';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import {
  PaxCountType,
  CostObjectType,
  IndicativeCost,
  AgencyCostType,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';

import { getAlpha2CodeFromCountry, getCountryFromAlpha2 } from 'lib/countries';
import { Crew } from 'utils/types/crew-change-types';
import {
  BreakdownCostDetail,
  AgencyCostSummary,
  ReadOnlyFlightCrew,
} from '../types';

const UNPECIFIED_COUNTRY = 'others';

// A module to calculate & format agency costs for the crew change panel. Provides -
// per crew cost for each agency
// total crew change cost for each agency
// formatted breakdown details for each agency
// best (cheapest) or preferred agency details (with above) for each port
export default class AgencyCostModule {
  crewList: (Crew | ReadOnlyFlightCrew)[];
  agencyCosts: CostObjectType;

  constructor(
    crew: (Crew | ReadOnlyFlightCrew)[],
    agencyCosts: CostObjectType
  ) {
    this.crewList = crew;
    this.agencyCosts = agencyCosts;
  }

  get totalCrewCount() {
    return this.crewList.length;
  }

  get crewCountries(): string[] {
    // use placeholder value `others` for country specific cost formatting
    return uniq(
      map(this.crewList, ({ country }) => country || UNPECIFIED_COUNTRY)
    );
  }

  // filter out specific countries inside a breakdown category, if available based on crew countries
  private filterSpecificCostCountryCodes(costDetail: IndicativeCost): string[] {
    const { nationalitySpecificCosts } = costDetail;
    if (!nationalitySpecificCosts) return [];

    return filter(keys(nationalitySpecificCosts), (alpha2Code) =>
      includes(this.crewCountries, getCountryFromAlpha2(alpha2Code)?.name)
    );
  }

  // generate custom category name with number of crew counts, if applicable
  // also, return country specific costs, if available
  private formatCategoryName(costDetail: IndicativeCost) {
    const {
      category,
      perPaxType,
      nationalitySpecificCosts: countryCosts,
    } = costDetail;
    if (perPaxType === PaxCountType.PER_CREW_CHANGE) {
      return { category };
    }

    const filteredCostCountryCodes =
      this.filterSpecificCostCountryCodes(costDetail);
    // indicates there's no country specific costs inside a breakdown category
    const mixedIndicative = countryCosts && filteredCostCountryCodes.length;

    return {
      ...(mixedIndicative ? { countryCosts } : {}),
      category: mixedIndicative
        ? category
        : `${category} x ${this.totalCrewCount}`,
    };
  }

  // formats individual categories that are of PER_PAX type in `breakdown.included`
  private formatPerPaxCost(
    costDetail: IndicativeCost
  ): BreakdownCostDetail | null {
    const totalCrewCount = this.crewList.length;
    const { indicative, relevantNumCrew } = costDetail;
    const { min: minCount, max: maxCount } = relevantNumCrew || {};
    const includeCost =
      // if both min & max counts available
      minCount && maxCount
        ? totalCrewCount >= minCount && totalCrewCount <= maxCount
        : // otherwise based on whether min or max count
          (minCount && totalCrewCount >= minCount) ||
          (maxCount && totalCrewCount <= maxCount);

    if (relevantNumCrew && !includeCost) return null;

    const { category, countryCosts } = this.formatCategoryName(costDetail);
    // indicates homogenous breakdown costs
    if (!countryCosts) {
      return {
        [category]: indicative * totalCrewCount,
      };
    }

    // if there are country specific costs, format them
    const customCostDetails = this.crewCountries.reduce<BreakdownCostDetail>(
      (acc, country) => {
        const alpha2Code = getAlpha2CodeFromCountry(country);
        const countryName = alpha2Code ? country : UNPECIFIED_COUNTRY;
        const countryCount = filter(this.crewList, [
          'country',
          countryName,
        ]).length;
        const countryKey = `${countryName} x ${countryCount}`;
        const countryCostIndicative =
          // if this country is NOT included in the custom costs, use generic indicative cost
          !alpha2Code || !countryCosts[alpha2Code]
            ? indicative
            : // otherwise use custom cost for this country
              countryCosts[alpha2Code].indicative;

        return {
          [category]: {
            ...((acc[category] as {}) || {}),
            [countryKey]: countryCostIndicative * countryCount,
          },
        };
      },
      {}
    );

    const costArray = uniq(values(customCostDetails[category]));
    // return if nested costs have different countries
    // otherwise simplify nesting & set homogenous cost against category
    return costArray.length > 1
      ? customCostDetails
      : { [category]: costArray[0] };
  }

  // calculates total cost of all selected crew for a particular agency
  private getAgencyTotalCost(allCosts: IndicativeCost[]) {
    const [perCrewChangeCosts, perPaxCosts] = partition(allCosts, [
      'perPaxType',
      PaxCountType.PER_CREW_CHANGE,
    ]);

    const perCrewChangeBreakdown =
      perCrewChangeCosts.reduce<BreakdownCostDetail>(
        (acc, { category, indicative }) => ({
          ...acc,
          [category]: indicative,
        }),
        {}
      );
    const perPaxBreakdown = perPaxCosts.reduce<BreakdownCostDetail>(
      (acc, costDetail) => ({
        ...acc,
        ...this.formatPerPaxCost(costDetail),
      }),
      {}
    );
    const breakdown = {
      ...perPaxBreakdown,
      ...perCrewChangeBreakdown,
    };
    const totalAgencyCost = values(breakdown).reduce<number>((acc, item) => {
      if (isNumber(item)) return acc + item;
      return acc + values(item).reduce((acc, item) => acc + item, 0);
    }, 0);

    return { breakdown, agencyCost: Math.round(totalAgencyCost) };
  }

  // gets formatted details of all agency for a port
  public getAllAgencyCostDetails() {
    return mapValues(this.agencyCosts, (agencyCost) => {
      // if cost breakdown not available
      if (!agencyCost?.breakdown) {
        return null;
      }

      const { breakdown, currency, agencyCostType } = agencyCost;
      return {
        currency,
        // historical cost means used only for presentational puposes (i.e - in ports table)
        historical: agencyCostType === AgencyCostType.HISTORICAL_COST,
        ...this.getAgencyTotalCost(breakdown.included),
      };
    });
  }

  // calculates a summry with all relevant info of a user preferred port
  // or the cheapest port if no specific port is provided
  public getPreferredCostSummary(selectedName?: string) {
    // avoid agency costs with top-level `agencyCostType` as `HISTORICAL_COST`
    // these are considered historical & NOT included in cost calculation
    const agencyCostDetails = pickBy(
      this.getAllAgencyCostDetails(),
      (item) => !item?.historical
    );
    // filter for specific agency, if provided, otherwise get the cheapest
    return filter(keys(agencyCostDetails), (name) =>
      selectedName && selectedName !== 'ALL' ? name === selectedName : true
    ).reduce<AgencyCostSummary>((acc, agencyName) => {
      const costDetails = agencyCostDetails[agencyName];
      if (!costDetails) return acc;

      const { currency, agencyCost: currentCost } = costDetails;
      return acc.agencyCost && parseInt(acc.agencyCost) < currentCost
        ? acc
        : {
            agent: agencyName,
            agencyCost: `${currentCost} ${currency}`,
            costDetails,
          };
    }, {} as AgencyCostSummary);
  }
}
