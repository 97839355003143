import uniqBy from 'lodash/uniqBy';
import { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import useAppDispatch from 'hooks/useAppDispatch';
import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import { createNewCMPEvent, fetchMatrixEventDetails } from 'api/crew-matrix';
import { TRACK_CREATE_CMP_EVENT } from 'utils/analytics/constants';
import { fetchVesselScheduleEventsAsync } from 'redux/thunks';
import { RootState } from 'redux/types';
import { CCMatrixContext } from 'contexts/CCMatrixContext';

import {
  CrewChangeEventDetailed,
  ManualEmptyEvent,
  MergeCompliance,
  OffsignerWithOptions,
  OnBoardCrew,
} from 'components/CrewMatrixPlanning/types';
import {
  convertOffsignerForReq,
  generateOffsignerWithOptions,
  getMergeEventsCompliance,
} from 'components/CrewMatrixPlanning/helpers';

type EventResponse = {
  success: boolean;
  message: string;
  result?: CrewChangeEventDetailed | ManualEmptyEvent;
};

type ActionParams = {
  eventId?: number; // available when fetching an existing event
  eventDate?: string; // available when creating a new event
  merge?: boolean; // available when forcing merge
};

type AssignOffsigner = ({
  eventId,
  eventDate,
}: ActionParams) => Promise<CrewChangeEventDetailed | MergeCompliance | null>;

const showErrorToaster = () =>
  showToaster({
    message: 'Failed to assign offsigner to the event.',
    placement: 'left',
    type: 'error',
  });

function useAssignOffsignerToEvent(
  offsigner: OffsignerWithOptions | undefined
) {
  const dispatch = useAppDispatch();
  const storedVessels = useSelector(
    ({ crewChangeMatrix }: RootState) => crewChangeMatrix.vessels
  );
  const { currentEvent, confirmEventUpdate } = useContext(CCMatrixContext);
  const { vessel } = currentEvent || {};

  // state indicating the assigning of offsigner in progress
  const [loading, setLoading] = useState(false);

  const handleAssignOffsigner: AssignOffsigner = useCallback(
    async ({ eventId, eventDate, merge: forceMerge }) => {
      if (!offsigner || !vessel) return null;

      // before starting assigning offsigner to event, set the action event details
      setLoading(true);
      // if there's eventId fetch the event details & set the state
      const promise: Promise<EventResponse> = eventId
        ? fetchMatrixEventDetails(eventId) // existing event where the offsigner is assigned to
        : createNewCMPEvent(vessel.imo, eventDate!);

      const response = await promise;
      const { success, result } = response;
      if (!success || !result) {
        setLoading(false);
        showErrorToaster();
        return null;
      }

      // trigger amplitude event for creating new event
      if (eventDate) {
        trackUserAction(TRACK_CREATE_CMP_EVENT, 'click', {
          newEvent: result,
          vessel,
        });
      }

      const actionEvent: CrewChangeEventDetailed = eventDate
        ? // when creating a new event, prepare detailed event from empty event
          {
            ...result,
            vessel,
            offsigners: [],
            onboard: { type: 'CURRENT_AND_PLANNED', crew: [] },
          }
        : // when assigning to existing event, the response should have the details
          (result as CrewChangeEventDetailed);

      /* --- prepare required params for generating offsigner with onsigner options --- */

      // return early if there are merge conflicts
      const compliance = getMergeEventsCompliance([offsigner], actionEvent);
      if (compliance.conflicts.length && !forceMerge) {
        return compliance;
      }

      // convert offsigner to `OnBoardCrew` type
      const updatedOffsigner = convertOffsignerForReq({
        ...offsigner,
        type: 'CURRENT',
      });
      // get the onboard crew list with updated offsigner
      const updatedOnboardCrewList = uniqBy(
        [updatedOffsigner as OnBoardCrew, ...(actionEvent.onboard.crew || [])],
        'externalCrewId'
      );
      // update the detailed event with updated onboard crew list
      const updatedEvent = {
        ...actionEvent,
        onboard: { ...actionEvent.onboard, crew: updatedOnboardCrewList },
      } as CrewChangeEventDetailed;
      // get the vessel details with updated onboard crew list
      const vesselDetails = {
        ...storedVessels.find(({ id }) => id === vessel.id)!,
        onBoardCrew: updatedOnboardCrewList,
      };
      // generate offsigner with onsigner options
      const offsignerResult = await generateOffsignerWithOptions(
        updatedOffsigner as OnBoardCrew,
        updatedEvent,
        vesselDetails
      )(''); // empty reason

      setLoading(false);
      // insert new offsigner to the event for successful assignment
      return offsignerResult
        ? {
            ...updatedEvent,
            offsigners: uniqBy(
              [offsignerResult.droppedOffsigner, ...actionEvent.offsigners],
              'externalCrewId'
            ),
          }
        : null;
    },
    [vessel, offsigner, storedVessels]
  );

  const handleUpdateCurrentEvent = useCallback(async () => {
    if (!currentEvent?.id) return;

    // fetch the updated current event details
    const { success, result } = await fetchMatrixEventDetails(currentEvent.id);
    if (!success || !result) return;

    // update current event with fetched details after assigning offsigner
    confirmEventUpdate(result, 'offsigner-reassigned');
  }, [currentEvent?.id, confirmEventUpdate]);

  return {
    loading,
    onAssignOffsigner: async (props: ActionParams) => {
      const result = await handleAssignOffsigner(props);
      if (!result || !vessel) return;

      if ((result as CrewChangeEventDetailed)?.id) {
        const { id: vesselId, imo: vesselImo } = vessel;
        // update the current event at context level state
        handleUpdateCurrentEvent();
        // update vessel schedule events in store to reflect changes to any related event(s)
        dispatch(fetchVesselScheduleEventsAsync({ vesselId, vesselImo }));
      }
      return result as MergeCompliance;
    },
  };
}

export default useAssignOffsignerToEvent;
