import head from 'lodash/head';
import last from 'lodash/last';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CabinClass } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';

import { getFlightReqTime } from 'lib/common';
import { fetchFlights } from 'api/flotilla';
import { FlightRequest } from 'utils/types/crew-change-types';

type InputType = {
  searchQuery: string;
  time: string;
  cabinClass?: CabinClass;
  refresh?: boolean;
};

export const searchFlightsAsync = createAsyncThunk(
  'flotillaSearch/searchFlightsAsync',
  async ({ searchQuery, cabinClass, time, refresh }: InputType) => {
    const queryArr = searchQuery.split(' ');
    const startCode = head(queryArr) as string;
    const endCode = last(queryArr) as string;

    const flightRequest: FlightRequest = {
      startCode,
      endCode,
      startDate: getFlightReqTime(time),
    };
    const options = { cabinClass, refresh };

    return fetchFlights(flightRequest, options);
  }
);
