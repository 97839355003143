import { v4 as uuidv4 } from 'uuid';

import { PackagePassportInput } from '../types';

export async function packagePassports({
  crewPassports,
  passportsPassword,
}: PackagePassportInput) {
  // Dynamic import of external package
  const Zip = await import('@zip.js/zip.js');
  const hasFile = Object.keys(crewPassports).reduce((containsFile, crewId) => {
    if (crewPassports[Number(crewId)].file) {
      return true;
    }
    return containsFile;
  }, false);
  if (!hasFile) return [];
  /**
   * if using AES encryption, change zipCrypto to false
   * However, not all devices will be able to open the file without installing
   * additional software
   */
  const zipWriter = new Zip.ZipWriter(new Zip.BlobWriter('application/zip'), {
    bufferedWrite: true,
    password: passportsPassword,
    zipCrypto: true,
  });
  Object.keys(crewPassports).forEach((crewId) => {
    const file = crewPassports[Number(crewId)].file;
    if (file) {
      zipWriter.add(file.name, new Zip.BlobReader(file));
    }
  });

  const zipBlob = await zipWriter.close();
  const reader = new FileReader();
  reader.readAsDataURL(zipBlob);

  const base64string: string | null = await new Promise((resolve) => {
    reader.onload = () => {
      if (reader.result) {
        const base64Zip = String(reader.result).split(',')[1];
        resolve(base64Zip);
      }
    };
    reader.onerror = () => {
      resolve(null);
    };
  });
  if (!base64string) return [];
  return [
    {
      name: `${uuidv4().substring(0, 8)}.zip`,
      contentType: 'application/zip',
      content: base64string,
    },
  ];
}

export async function packagePassportsBlobUrl({
  crewPassports,
  passportsPassword,
}: PackagePassportInput) {
  // Dynamic import of external package
  const Zip = await import('@zip.js/zip.js');
  /**
   * if using AES encryption, change zipCrypto to false
   * However, not all devices will be able to open the file without installing
   * additional software
   */
  const zipWriter = new Zip.ZipWriter(new Zip.BlobWriter('application/zip'), {
    bufferedWrite: true,
    password: passportsPassword,
    zipCrypto: true,
  });
  Object.keys(crewPassports).forEach((crewId) => {
    const file = crewPassports[Number(crewId)].file;
    if (file) {
      zipWriter.add(file.name, new Zip.BlobReader(file));
    }
  });

  const zipBlob = await zipWriter.close();
  return URL.createObjectURL(zipBlob);
}

export const passportPasswordIsValid = (field: string) =>
  /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+){6,}$/.test(field);
