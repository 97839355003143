import { brightBlue, green, red50, yellowOrange } from 'lib/colors';
import { CrewSortOption } from './types';

export const GREYWING_CMP_ACCOUNT = 'cmp@grey-wing.com';

export const SORT_OPTIONS: CrewSortOption[] = ['RANK', 'NAME', 'SIGN-ON DATE'];

export const BASE_MATRIX_ROUTE = 'crew-matrix-planning';
export const OFFSIGNER_DRAG_TYPE = 'AVAILABLE_OFFSIGNER';

export const COMPLIANCE_RESPONSE_SUCCESS_MESSAGE =
  'Oil major compliance calculated successfully';
export const COMPLIANCE_RESPONSE_FAILURE_MESSAGE =
  'Failed to compute oil major compliance';

export const UPDATE_UNAVAILABLE_TITLE = 'Update unavailable';
export const UPDATE_UNAVAILABLE_MESSAGE =
  'This event is already accepted/confirmed. Unaccept current event to make updates.';
export const BLOCKED_OFF_CREW_MESSAGE =
  "This crew has a block-off period which overlaps with the event's date. Remove the blocked off period to add this crew to the event.";

export const MONTHS_OF_YEAR = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// sort crew list for rank based on this list
export const CREW_RANK_SORT_ORDER = [
  'Master',
  'C/O',
  'App C/O',
  '1/O',
  '2/O',
  '3/O',
  'App 3/O',
  'C/E',
  'App C/E',
  '1/E',
  'App 1/E',
  'LPG/E(1/E)',
  'LPG/E(2/E)',
  'LNG/E(1/E)',
  'LNG/E(2/E)',
  '2/E',
  '3/E',
  'App 3/E',
  'BSN',
  'PM',
  'AB',
  'OS',
  '1OLR',
  'OLR',
  'WPR',
  'CCK',
  '2/CK',
  'MM',
  'M/BSN',
  'M/AB',
  'M/OS',
  'M/OLR1',
  'M/OLR',
  'M/WPR',
  'D/C',
  'E/C',
  'D/Boy',
  'E/Boy',
  'R/OFF',
].map((str) => str.toLowerCase());

export const VISIBLE_PERSONAL_INFO = [
  'dob',
  'gender',
  'bloodType',
  'marriage',
  'religion',
  'hobby',
] as const;

export const OPERATOR_IMS_RANKS = ['operator (i.m.s)', 'operator (ims)'];

export const COLORS = [brightBlue, red50, yellowOrange, green];

export const BLOCK_OFF_DATES = [
  { label: 'Sign-on Date', type: 'onsignBlockoffStartDate' },
  { label: 'Start Date', type: 'onboardBlockoffStartDate' },
  { label: 'End Date', type: 'onboardBlockoffEndDate' },
];

// compliance rules for merging 2 events or adding/assigning an offsigner to an event
export const EVENTS_MERGE_COMPLIANCE = [
  {
    ranks: ['Master & C/O', 'C/E & 1/E', '2/O & 3/O', '2/E & 3/E'],
    name: 'BP Requirement',
    reason: 'should have min 2 weeks between joining dates.',
  },
  {
    ranks: ['Master & C/O', 'C/E & 1/E'],
    name: 'TOTAL Requirement',
    reason: 'should not change together.',
  },
  {
    ranks: ['Master & C/O', 'Master & C/E', 'C/E & 1/E'],
    name: 'ADNOC Requirement',
    reason: 'should have min 14 days between joining dates.',
  },
];

// list of ranks chosen initially for filtering vessel ranks comparison modal
export const INITIAL_FILTER_RANKS = ['MASTER', 'C/O', 'C/E', '1/E'];
